<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Line Item
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>

    <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Headers
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn v-on:click="createHeader" small class="mx-4" >
                  <span class="font-size-16 font-weight-600"
                    >New Header</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateLineItemSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100">
                <tbody>
                  <tr v-for="(row, index) in headers" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="row.text"
                        dense
                        filled
                        label="Header"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click="deleteHeader(index)"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    <v-card v-if="false" flat class="custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Line Item
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateLineItemSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 70vh; position: relative"
      >
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-checkbox
            :disabled="pageLoading"
            v-model="setting.allow_item_group"
            label="Allow Item Group"
            hide-details
            color="cyan"
          ></v-checkbox>
          <v-row v-if="setting.allow_item_group" class="ml-10">
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.quotation_item_group"
                label="Allow Item Group in Quotation"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.invoice_item_group"
                label="Allow Item Group in Invoice"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.ticket_item_group"
                label="Allow Item Group in Job"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.purchase_order_item_group"
                label="Allow Item Group in Purchase Order"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.proposal_item_group"
                label="Allow Item Group in Proposal"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.contract_item_group"
                label="Allow Item Group in Contract"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
          </v-row>
          <hr />
          <v-checkbox
            :disabled="pageLoading"
            v-model="setting.allow_qty"
            label="Allow QTY"
            hide-details
            color="cyan"
          ></v-checkbox>
          <v-row v-if="setting.allow_qty" class="ml-10">
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.quotation_qty"
                label="Allow QTY in Quotation"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.invoice_qty"
                label="Allow QTY in Invoice"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.ticket_qty"
                label="Allow QTY in Job"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.purchase_order_qty"
                label="Allow QTY in Purchase Order"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.proposal_qty"
                label="Allow QTY in Proposal"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.contract_qty"
                label="Allow QTY in Contract"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
          </v-row>
          <hr />
          <v-checkbox
            :disabled="pageLoading"
            v-model="setting.allow_rate"
            label="Allow Rate"
            hide-details
            color="cyan"
          ></v-checkbox>
          <v-row v-if="setting.allow_rate" class="ml-10">
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.quotation_rate"
                label="Allow Rate in Quotation"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.invoice_rate"
                label="Allow Rate in Invoice"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.ticket_rate"
                label="Allow Rate in Job"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.purchase_order_rate"
                label="Allow Rate in Purchase Order"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.proposal_rate"
                label="Allow Rate in Proposal"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.contract_rate"
                label="Allow Rate in Contract"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
          </v-row>
          <hr />
          <v-checkbox
            :disabled="pageLoading"
            v-model="setting.allow_discount"
            label="Allow Discount"
            hide-details
            color="cyan"
          ></v-checkbox>
          <v-row v-if="setting.allow_discount" class="ml-10">
            <v-col md="12" class="py-0">
              <v-layout align-center class="pt-4">
                <v-flex
                  ><label
                    for="discount_type"
                    class="font-weight-500 font-size-16 m-0"
                    >Discount Type</label
                  ></v-flex
                >
                <v-flex>
                  <v-select
                    id="discount_type"
                    :items="discountType"
                    dense
                    filled
                    flat
                    hide-details
                    solo
                    color="cyan"
                    item-color="cyan"
                    :loading="pageLoading"
                    :disabled="pageLoading"
                    placeholder="Discount Type"
                    v-model.trim="setting.discount_type"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.quotation_discount"
                label="Allow Discount in Quotation"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.invoice_discount"
                label="Allow Discount in Invoice"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.ticket_discount"
                label="Allow Discount in Job"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.purchase_order_discount"
                label="Allow Discount in Purchase Order"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.proposal_discount"
                label="Allow Discount in Proposal"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.contract_discount"
                label="Allow Discount in Contract"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
          </v-row>
          <hr />
          <v-checkbox
            :disabled="pageLoading"
            v-model="setting.allow_tax"
            label="Allow Tax"
            hide-details
            color="cyan"
          ></v-checkbox>
          <v-row v-if="setting.allow_tax" class="ml-10">
            <v-col md="12" class="py-0">
              <v-layout align-center class="pt-4">
                <v-flex
                  ><label
                    for="tax_type"
                    class="font-weight-500 font-size-16 m-0"
                    >Tax Type</label
                  ></v-flex
                >
                <v-flex>
                  <v-select
                    id="tax_type"
                    :items="discountType"
                    dense
                    filled
                    flat
                    hide-details
                    solo
                    color="cyan"
                    item-color="cyan"
                    :loading="pageLoading"
                    :disabled="pageLoading"
                    placeholder="Tax Type"
                    v-model.trim="setting.tax_type"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.quotation_tax"
                label="Allow Tax in Quotation"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.invoice_tax"
                label="Allow Tax in Invoice"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.ticket_tax"
                label="Allow Tax in Job"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.purchase_order_tax"
                label="Allow Tax in Purchase Order"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.proposal_tax"
                label="Allow Tax in Proposal"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
            <v-col md="12" class="py-0 col-lg-6">
              <v-checkbox
                :disabled="pageLoading"
                v-model="setting.contract_tax"
                label="Allow Tax in Contract"
                hide-details
                color="cyan"
              ></v-checkbox>
            </v-col>
          </v-row>
          <hr />
          <v-checkbox
            :disabled="pageLoading"
            v-model="setting.allow_engineer_rate"
            label="Show Rate to Engineer"
            hide-details
            color="cyan"
          ></v-checkbox>
          <hr />
          <v-checkbox
            :disabled="pageLoading"
            v-model="setting.allow_adjustment"
            label="Allow Adjustment"
            hide-details
            color="cyan"
          ></v-checkbox>
        </v-card-text>
      </perfect-scrollbar>
    </v-card>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import { cloneDeep } from "lodash";

export default {
  name: "profile-lineitem-setting",
  data() {
    return {
      pageLoading: true,
      discountType: [
        {
          text: "At transaction level",
          value: 1,
        },
        {
          text: "At line item level",
          value: 2,
        },
      ],
      headers: [
        {
          text: null,
        },
      ],
      setting: {
        allow_engineer_rate: false,
        allow_adjustment: false,
        allow_item_group: false,
        quotation_item_group: false,
        invoice_item_group: false,
        ticket_item_group: false,
        purchase_order_item_group: false,
        proposal_item_group: false,
        contract_item_group: false,
        allow_qty: false,
        quotation_qty: false,
        invoice_qty: false,
        ticket_qty: false,
        purchase_order_qty: false,
        proposal_qty: false,
        contract_qty: false,
        allow_rate: false,
        quotation_rate: false,
        invoice_rate: false,
        ticket_rate: false,
        purchase_order_rate: false,
        proposal_rate: false,
        contract_rate: false,
        allow_discount: false,
        discount_type: 1,
        tax_type: 1,
        quotation_discount: false,
        invoice_discount: false,
        ticket_discount: false,
        purchase_order_discount: false,
        proposal_discount: false,
        contract_discount: false,
        allow_tax: false,
        quotation_tax: false,
        invoice_tax: false,
        ticket_tax: false,
        purchase_order_tax: false,
        proposal_tax: false,
        contract_tax: false,
      },
    };
  },
  methods: {
    createHeader() {
      this.headers.push({
        text: null,
      });
    },
    deleteHeader(index) {
      if (this.headers.length > 1) {
        this.headers.splice(index, 1);
      }
    },
    getLineItemSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/line-item" })
        .then(({ data }) => {
          _this.headers = cloneDeep(data?.headers ?? _this.headers);
          data.headers = undefined
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateLineItemSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/line-item", data: { ..._this.setting, headers: _this.headers } })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getLineItemSetting();
  },
};
</script>
