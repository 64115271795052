<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    header-left-cols="9"
    header-right-cols="3"
    :customClass="'enquiry-create create-form'"
    v-if="getPermission('enquiry:create') || getPermission('enquiry:update')"
  >
    <template v-slot:header-title>
      <div class="form-action">
        <div class="form-title">
          Enquiry for
          <span
            class="form-title-link cursor-pointer"
            v-on:click="openDialog('customer')"
          >
            <template v-if="vCustomerId">{{ vCustomer.display_name }}</template>
            <template v-else>
              Select a Customer
              <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
            </template>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:header-action>
      <div>
        <v-btn
          :disabled="formLoading"
          v-on:click="goBack()"
          class="custom-bold-button custom-grey-border"
          depressed
          >Cancel</v-btn
        >
        <v-btn
          class="custom-bold-button ml-4"
          depressed
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          v-on:click="validateEnquiry()"
          color="cyan white--text"
        >
          <template v-if="dbEnquiryId > 0"> Update Enquiry</template>
          <template v-else>Save Enquiry</template>
        </v-btn>
      </div>
    </template>

    <template v-slot:body>
      <div class="bg-white px-8">
        <v-form
          ref="createEnquiryForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="validateEnquiry()"
        >
          <v-row>
            <v-col md="12" v-if="false">
              <div class="form-action">
                <div class="form-title d-flex">
                  Enquiry for
                  <div
                    v-if="vCustomerId"
                    class="ml-3 form-title-link cursor-pointer"
                    v-on:click="openDialog('customer')"
                  >
                    {{ vCustomer.display_name }}
                  </div>
                  <template v-else>
                    <div
                      class="ml-3 form-title-link cursor-pointer"
                      v-on:click="openDialog('customer')"
                    >
                      Select a Customer
                      <v-icon large color="cyan"
                        >mdi-account-check-outline</v-icon
                      >
                    </div>
                    <div class="mx-3">OR</div>
                    <div
                      class="form-title-link cursor-pointer cyan--text text--darken-4"
                      v-on:click="createCustomerDialog()"
                    >
                      Create a new Customer
                      <v-icon large color="cyan darken-4"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </div>
                  </template>
                </div>
                <div>
                  <v-btn
                    :disabled="formLoading"
                    v-on:click="goBack()"
                    class="custom-bold-button custom-grey-border"
                    depressed
                    >Cancel</v-btn
                  >
                  <v-btn
                    class="custom-bold-button ml-4"
                    depressed
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    v-on:click="validateEnquiry()"
                    color="cyan white--text"
                  >
                    <template v-if="dbEnquiryId > 0"> Update</template>
                    <template v-else>Save</template>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col md="7">
              <label for="enquiry-title" class="">Enquiry Title</label>
              <TextField
                id="enquiry-title"
                dense
                filled
                placeholder="Title"
                solo
                flat
                counter="50"
                v-model="dbEnquiry.title"
                color="cyan"
                :maxlength="250"
              />

              <v-row>
                <v-col md="6">
                  <v-layout class="align-center">
                    <v-flex md3
                      ><label class="my-0">Billing Location</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        v-on:click="openDialog('billing')"
                        small
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBilling?.id">
                    <p class="mb-0">
                      <show-value
                        :object="vBilling"
                        object-key="property_address"
                        label="Billing Location"
                      />
                    </p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select billing location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>
                  <v-layout class="mt-3 align-center">
                    <v-flex md3
                      ><label class="my-0">Contact Details</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('billing-contact')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBillingContact?.id">
                    <p class="mb-0">
                      <show-value
                        :object="vBillingContact"
                        object-key="display_name"
                        label="Name"
                      />
                    </p>
                    <p class="mb-0">
                      <show-value
                        :object="vBillingContact"
                        object-key="primary_phone"
                        label="Contact No."
                      />
                    </p>
                    <p class="mb-0">
                      <show-value
                        :object="vBillingContact"
                        object-key="primary_email"
                        label="Email"
                      />
                    </p>
                    <div class="d-flex mt-3">
                      <label class="my-0">Office No.</label>
                      <p class="mb-0">
                        <show-value
                          :object="vCustomer"
                          object-key="company_number"
                          label="Office No."
                        />
                      </p>
                    </div>
                    <div class="d-flex mt-3">
                      <label class="my-0">Fax No.</label>
                      <p class="mb-0">
                        <show-value
                          :object="vCustomer"
                          object-key="company_fax"
                          label="Fax No."
                        />
                      </p>
                    </div>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>
                  <v-layout v-if="false" class="mt-3">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="dbEnquiry.notify_billing_contact_person"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="6">
                  <v-layout class="align-center">
                    <v-flex md3><label class="my-0">Site Info</label></v-flex>
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vProperty?.id">
                    <p class="mb-0">
                      <show-value
                        :object="vProperty"
                        object-key="property_address"
                        label="Service Location"
                      />
                    </p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select service location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>

                  <v-layout class="mt-3 align-center">
                    <v-flex md3
                      ><label class="my-0">Contact Details</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property-contact')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vPropertyContact?.id">
                    <p class="mb-0">
                      <show-value
                        :object="vPropertyContact"
                        object-key="display_name"
                        label="Name"
                      />
                    </p>
                    <p class="mb-0">
                      <show-value
                        :object="vPropertyContact"
                        object-key="primary_phone"
                        label="Contact No."
                      />
                    </p>
                    <p class="mb-0">
                      <show-value
                        :object="vPropertyContact"
                        object-key="primary_email"
                        label="Email"
                      />
                    </p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>

                  <v-layout v-if="false" class="mt-3">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="dbEnquiry.notify_property_contact_person"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5">
              <table width="100%" style="table-layout: fixed">
                <tr>
                  <td width="25%">
                    <label for="enquiry-id">Enquiry #</label>
                  </td>
                  <td>
                    <v-text-field
                      id="enquiry-id"
                      dense
                      filled
                      readonly
                      hide-details
                      placeholder="Enquiry #"
                      solo
                      flat
                      class="barcode-field"
                      :value="dbEnquiry.barcode"
                      color="cyan"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <label for="enquiry-reference">Reference #</label>
                  </td>
                  <td>
                    <TextField
                      id="enquiry-reference"
                      dense
                      filled
                      placeholder="Reference #"
                      solo
                      flat
                      counter="50"
                      v-model="dbEnquiry.reference"
                      color="cyan"
                      :maxlength="250"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <label for="enquiry-door-location"
                      >Door Location/Serial No.</label
                    >
                  </td>
                  <td>
                    <TextField
                      id="enquiry-door-location"
                      dense
                      filled
                      placeholder="Door Location/Serial No."
                      solo
                      flat
                      counter="50"
                      v-model="dbEnquiry.door_location"
                      color="cyan"
                      :maxlength="250"
                    />
                  </td>
                </tr>
                <tr v-if="false">
                  <td width="25%">
                    <label for="enquiry-call-no">Call No.</label>
                  </td>
                  <td>
                    <TextField
                      id="enquiry-call-no"
                      dense
                      filled
                      placeholder="Call No."
                      solo
                      flat
                      counter="50"
                      v-model="dbEnquiry.call_no"
                      color="cyan"
                      :maxlength="250"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="enquiry-date">Call Date</label>
                  </td>
                  <td>
                    <date-picker
                      key="enquiry-date"
                      id="enquiry-date"
                      :min-date="todayDate"
                      placeholder="Call Date"
                      v-model="dbEnquiry.date"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="enquiry-due-date">Due Date</label>
                  </td>
                  <td>
                    <date-picker
                      key="enquiry-due-date"
                      id="enquiry-due-date"
                      :min-date="todayDate"
                      placeholder="Due Date"
                      v-model="dbEnquiry.due_date"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="assigned">Assign</label>
                  </td>
                  <td>
                    <v-autocomplete
                      id="assigned"
                      dense
                      v-model="dbEnquiry.assign"
                      item-text="display_name"
                      item-color="cyan"
                      item-value="id"
                      filled
                      :items="userList"
                      hide-details
                      placeholder="Select Assign"
                      solo
                      flat
                      color="cyan"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="tags">Tags</label>
                  </td>
                  <td>
                    <tag-auto-complete v-model="dbEnquiry.tags" />
                  </td>
                </tr>
                <tr v-if="false">
                  <td>
                    <label for="priority">Priority</label>
                  </td>
                  <td>
                    <div class="d-flex ml-1">
                      <v-btn
                        depressed
                        tile
                        :outlined="!(dbEnquiry.priority == 1)"
                        v-on:click="dbEnquiry.priority = 1"
                        value="1"
                        color="red darken-3 white--text"
                        >High</v-btn
                      >
                      <v-btn
                        class="mx-2"
                        depressed
                        tile
                        :outlined="!(dbEnquiry.priority == 2)"
                        v-on:click="dbEnquiry.priority = 2"
                        value="2"
                        color="orange darken-4 white--text"
                        >Medium</v-btn
                      >
                      <v-btn
                        depressed
                        tile
                        :outlined="!(dbEnquiry.priority == 3)"
                        v-on:click="dbEnquiry.priority = 3"
                        value="3"
                        color="light-green darken-3 white--text"
                        >Low</v-btn
                      >
                    </div>
                  </td>
                </tr>
                <tr v-if="false">
                  <td>
                    <label for="impact">Impact</label>
                  </td>
                  <td>
                    <div class="d-flex ml-1">
                      <v-btn
                        depressed
                        tile
                        :outlined="!(dbEnquiry.impact == 1)"
                        v-on:click="dbEnquiry.impact = 1"
                        value="1"
                        color="red darken-3 white--text"
                        >Highest</v-btn
                      >
                      <v-btn
                        class="mx-2"
                        depressed
                        tile
                        :outlined="!(dbEnquiry.impact == 2)"
                        v-on:click="dbEnquiry.impact = 2"
                        value="2"
                        color="orange darken-4 white--text"
                        >Medium</v-btn
                      >
                      <v-btn
                        depressed
                        tile
                        :outlined="!(dbEnquiry.impact == 3)"
                        v-on:click="dbEnquiry.impact = 3"
                        value="3"
                        color="light-green darken-3 white--text"
                        >Lowest</v-btn
                      >
                    </div>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="12" ref="eqNote">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Problem &amp; Attachments
                  </h3>
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout>
                    <v-flex class="mr-4" md6>
                      <label class="font-size-16 font-weight-600"
                        >Problem
                      </label>
                      <v-textarea
                        id="enquiry-problem"
                        v-model="dbEnquiry.problem"
                        auto-grow
                        dense
                        filled
                        :disabled="formLoading"
                        color="cyan"
                        placeholder="Problem..."
                        solo
                        flat
                        row-height="30"
                        v-on:paste="(e) => onPaste(e, 'problem')"
                        v-on:keypress="(e) => manageLimit(e, 'problem')"
                      />
                      <div class="text-right">
                        {{
                          dbEnquiry.problem ? dbEnquiry.problem.length : 0
                        }}/1000
                      </div>
                    </v-flex>
                    <v-flex md6 class="mr-4">
                      <label class="font-size-16 font-weight-600 ml-2"
                        >Attachments ({{ filesUploaded }}/{{
                          $fileUploadLimit
                        }})</label
                      >
                      <table width="100%">
                        <tbody>
                          <tr
                            v-for="(row, index) in dbEnquiry.attachments"
                            :key="`prev-attach-${index}`"
                          >
                            <td width="50%" class="pr-2 py-2">
                              <v-text-field
                                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                readonly
                                color="cyan"
                                v-model="row.name"
                                hide-details
                              ></v-text-field>
                            </td>
                            <td width="50%" class="pl-2 py-2">
                              <v-text-field
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                color="cyan"
                                v-model="row.name"
                                hide-details
                                class="has-delete-outer-icon"
                                append-outer-icon="mdi-delete"
                                v-on:click:append-outer="
                                  remove_row('dbEnquiry.attachments', index)
                                "
                                :suffix="`.${row.ext}`"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr v-for="(row, index) in attachments" :key="index">
                            <td width="50%" class="pr-2 py-2">
                              <v-file-input
                                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                placeholder="Click here to select file"
                                label="Click here to select file"
                                prepend-icon=""
                                color="cyan"
                                solo
                                flat
                                v-model="row.accepted_file"
                                dense
                                v-on:change="update_file_name(index)"
                                hide-details
                              ></v-file-input>
                            </td>
                            <td width="50%" class="pl-2 py-2">
                              <v-text-field
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                color="cyan"
                                v-model="row.file_name"
                                hide-details
                                class="has-delete-outer-icon"
                                append-outer-icon="mdi-delete"
                                v-on:click:append-outer="
                                  remove_row('attachments', index)
                                "
                                :suffix="get_file_extension(row.file_type)"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              dbEnquiry.attachments.length +
                                attachments.length <
                              $fileUploadLimit
                            "
                          >
                            <td colspan="2">
                              <v-btn
                                color="cyan white--text"
                                small
                                depressed
                                v-on:click="add_attachment_row()"
                                class="custom-bold-button"
                                >Add More...</v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-flex>
                    <v-flex md6 v-if="false">
                      <label class="font-size-16 font-weight-600"
                        >Technician Sketch
                      </label>
                      <v-textarea
                        id="enquiry-technician"
                        v-model="dbEnquiry.technician"
                        auto-grow
                        dense
                        filled
                        :disabled="formLoading"
                        color="cyan"
                        placeholder="Technician Sketch..."
                        solo
                        flat
                        row-height="30"
                        v-on:paste="(e) => onPaste(e, 'technician')"
                        v-on:keypress="(e) => manageLimit(e, 'technician')"
                      />
                      <div class="text-right">
                        {{
                          dbEnquiry.technician
                            ? dbEnquiry.technician.length
                            : 0
                        }}/1000
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-4" v-if="false">
                    <v-flex md6 class="mr-4">
                      <label class="font-size-16 font-weight-600"
                        >Attachments ({{ filesUploaded }}/{{
                          $fileUploadLimit
                        }})</label
                      >
                      <table width="100%">
                        <tbody>
                          <tr
                            v-for="(row, index) in dbEnquiry.attachments"
                            :key="`prev-attach-${index}`"
                          >
                            <td width="50%" class="pr-2 py-2">
                              <v-text-field
                                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                readonly
                                color="cyan"
                                v-model="row.name"
                                hide-details
                              ></v-text-field>
                            </td>
                            <td width="50%" class="pl-2 py-2">
                              <v-text-field
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                color="cyan"
                                v-model="row.name"
                                hide-details
                                class="has-delete-outer-icon"
                                append-outer-icon="mdi-delete"
                                v-on:click:append-outer="
                                  remove_row('dbEnquiry.attachments', index)
                                "
                                :suffix="`.${row.ext}`"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr v-for="(row, index) in attachments" :key="index">
                            <td width="50%" class="pr-2 py-2">
                              <v-file-input
                                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                placeholder="Click here to select file"
                                label="Click here to select file"
                                prepend-icon=""
                                color="cyan"
                                solo
                                flat
                                v-model="row.accepted_file"
                                dense
                                v-on:change="update_file_name(index)"
                                hide-details
                              ></v-file-input>
                            </td>
                            <td width="50%" class="pl-2 py-2">
                              <v-text-field
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                color="cyan"
                                v-model="row.file_name"
                                hide-details
                                class="has-delete-outer-icon"
                                append-outer-icon="mdi-delete"
                                v-on:click:append-outer="
                                  remove_row('attachments', index)
                                "
                                :suffix="get_file_extension(row.file_type)"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              dbEnquiry.attachments.length +
                                attachments.length <
                              $fileUploadLimit
                            "
                          >
                            <td colspan="2">
                              <v-btn
                                color="cyan white--text"
                                small
                                depressed
                                v-on:click="add_attachment_row()"
                                class="custom-bold-button"
                                >Add More...</v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-flex>
                    <v-flex md6 class="ml-4"> </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <customer-select :key="`enquiry-customer-select-${dialog_key}`" />
        <customer-create :key="`enquiry-customer-create-${dialog_key}`" />
        <person-select
          :dialog="contactDialog"
          v-on:close="contactDialog = false"
          :type="contactType"
        />
        <address-select
          :dialog="propertyDialog"
          v-on:close="propertyDialog = false"
          :type="propertyType"
          :label="propertyLabel"
        />
      </div>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/components/QDatePicker.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import {
  SET_CREATE_DIALOG_STATUS,
  SET_DIALOG_STATUS,
  CLEAR_CUSTOMER,
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
} from "@/core/services/store/customer.module";
import { UPLOAD, QUERY, POST, PUT } from "@/core/services/store/request.module";
import ShowValue from "@/view/components/ShowValue.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import TagAutoComplete from "@/view/components/TagAutoCompleteInput.vue";
import KTCookie from "@/assets/js/components/cookie";
import { toSafeInteger } from "lodash";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-enquiry",
  mixins: [ValidationMixin],
  data() {
    return {
      formLoading: false,
      formValid: true,
      enquiryCreated: false,
      dialog_key: Number(new Date()),
      dbEnquiry: {
        title: null,
        notify_billing_contact_person: false,
        notify_property_contact_person: false,
        barcode: null,
        reference: null,
        door_location: null,
        call_no: null,
        date: null,
        due_date: null,
        assign: null,
        tags: null,
        priority: 3,
        impact: 3,
        problem: "",
        technician: "",
        attachments: [],
      },
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      dbEnquiryId: 0,
      dbDuplicateId: 0,
      userList: [],
      todayDate: null,
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
      routePreventDialog: false,
    };
  },
  components: {
    "show-value": ShowValue,
    "customer-select": CustomerSelect,
    "customer-create": CustomerCreate,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    "tag-auto-complete": TagAutoComplete,
    "date-picker": DatePicker,
    CreateUpdateTemplate,
  },
  methods: {
    createCustomerDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    manageLimit(e, key) {
      if (this.dbEnquiry[key] && this.dbEnquiry[key].length > 999) {
        e.preventDefault();
      }
    },
    onPaste(e, key) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.dbEnquiry[key];
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 999) {
        let trimValue = finalval.substring(0, 1000);
        this.dbEnquiry[key] = trimValue;
        e.preventDefault();
      }
    },
    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      if (
        this.dbEnquiry.attachments.length + this.attachments.length <
        this.$fileUploadLimit
      ) {
        this.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file instanceof File) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file instanceof File) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    openDialog(type) {
      this.dialog_key = Number(new Date());
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Site Info";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateEnquiry() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Service Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }

      const validateStatus = this.$refs.createEnquiryForm.validate();

      const formErrors = this.validateForm(this.$refs.createEnquiryForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      this.formLoading = true;

      const new_attachments = await this.uploadFiles();

      const request = {
        ...this.dbEnquiry,
        attachments: [...this.dbEnquiry.attachments, ...new_attachments],
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
      };

      let requestType = POST;
      let requestURL = "enquiry";

      let enquiryId = this.$route?.query?.edit ?? 0;

      if (enquiryId) {
        requestType = PUT;
        requestURL = `enquiry/${enquiryId}`;
      }

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(({ data }) => {
          this.enquiryCreated = true;
          this.$store.dispatch(CLEAR_CUSTOMER);
          this.$nextTick(() => {
            this.$router.push(
              this.getDefaultRoute("enquiry.detail", {
                params: { id: data?.id },
              })
            );
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },
    get_attributes() {
      this.$store
        .dispatch(QUERY, {
          url: "enquiry/options",
          data: {
            enquiry: this.dbEnquiryId,
            duplicate: this.dbDuplicateId,
          },
        })
        .then((response) => {
          this.dbEnquiry.barcode = ObjectPath.get(response, "data.barcode");
          // console.log(this.dbEnquiry.barcode,'this.dbEnquiry.barcode')

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Enquiry", route: "enquiry" },
            { title: "Create" },
            { title: this.dbEnquiry.barcode },
          ]);

          this.userList = ObjectPath.get(response, "data.assign_users", []);

          let dbEnquiry = ObjectPath.get(response, "data.enquiry");

          if (dbEnquiry?.id) {
            this.dbEnquiry.barcode = dbEnquiry?.barcode ?? null;
            this.dbEnquiry.title = dbEnquiry?.title ?? null;
            this.dbEnquiry.reference = dbEnquiry?.reference ?? null;
            this.dbEnquiry.call_no = dbEnquiry?.call_no ?? null;
            this.dbEnquiry.date = dbEnquiry?.call_date ?? null;
            this.dbEnquiry.due_date = dbEnquiry?.due_date ?? null;
            this.dbEnquiry.door_location = dbEnquiry?.door_location ?? null;
            this.dbEnquiry.assign = dbEnquiry?.call_person_id ?? null;
            this.dbEnquiry.tags = dbEnquiry?.tags ?? null;
            this.dbEnquiry.priority = dbEnquiry?.priority ?? 3;
            this.dbEnquiry.impact = dbEnquiry?.impact ?? 3;
            this.dbEnquiry.problem = dbEnquiry?.problem ?? "";
            this.dbEnquiry.technician = dbEnquiry?.technician_sketch ?? "";
            this.dbEnquiry.attachments = [];

            this.$store.commit(SET_CUSTOMER, dbEnquiry?.customer_relation);
            this.$store.commit(SET_PROPERTY, dbEnquiry?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              dbEnquiry?.property_person_relation
            );
            this.$store.commit(SET_BILLING, dbEnquiry?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              dbEnquiry?.billing_person_relation
            );

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Enquiry", route: "enquiry" },
              { title: "Update" },
              { title: this.dbEnquiry.barcode },
            ]);
          }

          let dbDuplicate = ObjectPath.get(response, "data.duplicate");

          if (dbDuplicate?.id) {
            this.dbEnquiry.title = dbDuplicate?.title ?? null;
            this.dbEnquiry.reference = dbDuplicate?.reference ?? null;
            this.dbEnquiry.call_no = dbDuplicate?.call_no ?? null;
            this.dbEnquiry.date = dbDuplicate?.call_date ?? null;
            this.dbEnquiry.due_date = dbDuplicate?.due_date ?? null;
            this.dbEnquiry.door_location = dbDuplicate?.door_location ?? null;
            this.dbEnquiry.assign = dbDuplicate?.call_person_id ?? null;
            this.dbEnquiry.tags = dbDuplicate?.tags ?? null;
            this.dbEnquiry.priority = dbDuplicate?.priority ?? 3;
            this.dbEnquiry.impact = dbDuplicate?.impact ?? 3;
            this.dbEnquiry.problem = dbDuplicate?.problem ?? "";
            this.dbEnquiry.technician = dbDuplicate?.technician_sketch ?? "";

            this.$store.commit(SET_CUSTOMER, dbDuplicate?.customer_relation);
            this.$store.commit(SET_PROPERTY, dbDuplicate?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              dbDuplicate?.property_person_relation
            );
            this.$store.commit(SET_BILLING, dbDuplicate?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              dbDuplicate?.billing_person_relation
            );
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          // this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.get_attributes();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Enquiry", route: "enquiry" },
      { title: "Create" },
    ]);
  },
  destroyed() {
    this.$store.dispatch(SET_BREADCRUMB, []);
    this.$store.dispatch(CLEAR_CUSTOMER);
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbEnquiry.date = this.todayDate;
    this.dbEnquiryId = toSafeInteger(this.$route.query?.edit ?? 0);
    this.dbDuplicateId = toSafeInteger(this.$route.query?.duplicate ?? 0);
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(CLEAR_CUSTOMER);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.enquiryCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  computed: {
    filesUploaded() {
      let newFiles = this.attachments.filter((row) => {
        return row.accepted_file instanceof File;
      });
      return newFiles.length + this.dbEnquiry.attachments.length;
    },
    ...mapGetters([
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.enquiry-create-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;
    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
