<template>
  <div class="resource-calendar-container main-listing-page">
    <v-toolbar height="44" flat class="px-1">
      <v-flex class="my-auto">
        <v-btn
          color="blue darken-4 white--text"
          tile
          depressed
          class="mr-1"
          v-on:click="getToday()"
        >
          Today
        </v-btn>
        <v-btn
          color="blue darken-4 white--text"
          tile
          depressed
          small
          width="30"
          min-width="30"
          height="26"
          class="mr-1"
          v-on:click="preview()"
        >
          <v-icon>mdi-menu-left</v-icon>
        </v-btn>
        <v-btn
          color="blue darken-4 white--text"
          tile
          depressed
          small
          width="30"
          min-width="30"
          height="26"
          class="mr-1"
          v-on:click="next()"
        >
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
        <div
          class="mx-5 font-level-3-bold d-inline-block primary--text text--darken-4 fw-600"
          style="min-width: 150px; font-size: 18px"
        >
          {{ toolbarTitle }}
        </div>
      </v-flex>
      <v-flex class="d-flex justify-content-start align-items-center">
        <div
          class="mx-5 font-level-3-bold d-inline-block primary--text text--darken-4 fw-600"
          style=""
        >
          Filters
        </div>
        <v-flex class="mr-2" style="max-width: 12rem">
          <DatePicker
            outlined
            depressed
            hide-details
            placeholder="Select Date"
            class-name="mt-0"
          >
          </DatePicker>
        </v-flex>
        <v-flex class="mr-2" style="max-width: 12rem">
          <v-autocomplete
            :items="statusList"
            v-model="status"
            hide-details
            clearable
            flat
            solo
            dense
            placeholder="Select Status"
            color="blue darken-4"
            item-color="blue darken-4"
            item-text="text"
            item-value="status"
            max-width="230px"
            min-height="26px"
            height="26px"
          >
            <!-- <template v-slot:selection="{ item, index }">
              <span class="text-truncate" v-if="index === 0">{{
                item.text
              }}</span>
              <span v-if="index === 1" class="ml-2 grey--text text-caption">
                (+{{ status.length - 1 }} more)
              </span>
            </template> -->
            <template v-slot:item="{ item }">
              <v-list-item-action class="mr-0">
                <v-chip
                  style="height: 16px !important; width: 16px; padding: 0"
                  :color="item.color"
                >
                </v-chip>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action
                class="align-self-center"
                v-if="status == item.status"
              >
                <v-icon color="cyan">mdi-check</v-icon>
              </v-list-item-action>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex class="mr-2" style="max-width: 12rem">
          <v-autocomplete
            :items="customerList"
            v-model="customer"
            hide-details
            clearable
            flat
            solo
            dense
            placeholder="Select Customer"
            color="blue darken-4"
            item-color="blue darken-4"
            item-text="display_name"
            item-value="id"
            style="max-width: 230px"
          >
            <!-- <template v-slot:selection="{ item }">
              <div class="text-truncate">{{ item.display_name }}</div>
            </template> -->
          </v-autocomplete>
        </v-flex>
      </v-flex>
      <v-flex class="d-flex justify-content-end">
        <v-btn
          color="blue darken-4 white--text"
          tile
          depressed
          class="mr-1"
          v-on:click="dayView()"
        >
          Day
        </v-btn>
        <v-btn
          color="blue darken-4 white--text"
          tile
          depressed
          class="mr-1"
          v-on:click="weekView()"
        >
          Week
        </v-btn>
        <v-btn
          color="blue darken-4 white--text"
          tile
          depressed
          class="mr-1"
          v-on:click="monthView()"
        >
          Month
        </v-btn>
        <v-btn
          depressed
          tile
          outlined
          color="blue darken-4"
          class="white--text ml-1"
          :to="{ name: 'admin.calendar' }"
        >
          Calendar
        </v-btn>
      </v-flex>
    </v-toolbar>
    <div class="resource-calender d-flex">
      <div class="calender-wrapper">
        <FullCalendar ref="fullCalendar" :options="calendarOptions" />
      </div>
      <div class="sidebar-event px-3 pb-3">
        <div class="listing-contents">
          <div class="position-sticky">
            <div class="d-flex py-1">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <h5
                    class="mt-0 py-0 pl-2 cursor-pointer"
                    style="border-color: #a3a3a3 !important"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ filterType }}
                    <v-icon size="28">mdi-menu-down</v-icon>
                  </h5>
                </template>
                <v-list>
                  <v-list-item-group color="primary">
                    <v-list-item link @click="filterType = 'Assigned Visit'">
                      <v-list-item-title class="fw-600 d-flex align-center">
                        <span
                          class="white--text mr-2 rounded-circle green d-flex justify-center align-center"
                          style="height: 22px; width: 22px"
                          >23</span
                        >
                        Assigned Visit</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link @click="filterType = 'UnAssigned Visit'">
                      <v-list-item-title class="fw-600 d-flex align-center">
                        <span
                          class="white--text mr-2 rounded-circle blue d-flex justify-center align-center"
                          style="height: 22px; width: 22px"
                          >23</span
                        >
                        UnAssigned Visit</v-list-item-title
                      >
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn
                fab
                icon
                small
                @click="showCalendarFilter = !showCalendarFilter"
              >
                <v-icon>mdi-calendar-check-outline</v-icon>
              </v-btn>
              <v-btn fab icon small min-width="30" width="30">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </div>
            <div v-if="showCalendarFilter" class="mb-2">
              <v-date-picker
                full-width
                no-title
                color="green lighten-1"
                v-model="datePicker"
                :event-color="(date) => (date[9] % 2 ? 'red' : 'yellow')"
                :events="functionEvents"
              ></v-date-picker>
            </div>
          </div>
          <v-flex class="mb-3 white">
            <v-autocomplete
              :items="engineerList"
              v-model="engineers"
              hide-details
              clearable
              flat
              solo
              dense
              prepend-inner-icon="mdi-magnify"
              placeholder="Select Engineer"
              color="blue darken-4"
              item-color="blue darken-4"
              item-text="display_name"
              item-value="id"
              class="pa-0"
            >
              <!-- <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate d-inline-block">{{
                  item.display_name
                }}</span>
                <span v-if="index === 1" class="ml-2 grey--text text-caption">
                  (+{{ engineer.length - 1 }} more)
                </span>
              </template> -->
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  class="mr-1"
                  height="30"
                  width="30"
                  min-width="30"
                  color="grey lighten-2 grey--text"
                >
                  <span class="fw-500">
                    {{ item.display_name.slice(0, 2).toUpperCase() }}
                  </span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >{{ item.display_name }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action
                  class="align-self-center"
                  v-if="engineer == item.id"
                >
                  <v-icon color="cyan">mdi-check</v-icon>
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-flex>
          <div
            class="list-items fc-event border"
            v-for="item in visit_list"
            :key="item.id"
            :data-set="JSON.stringify(item)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="fw-700 blue--text text-darken-4"
                style="font-size: 16px"
                >#{{ item.id }}</span
              >
              <div>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="visit-date d-flex align-items-center mr-2"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon size="13" color="green darken-4" class="mr-1"
                        >mdi-alarm</v-icon
                      >
                      <span class="green--text fw-500 font-size-11">{{
                        item.start
                      }}</span>
                    </div>
                  </template>
                  <span>Start date</span>
                </v-tooltip>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="visit-date d-flex align-items-center mr-2"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon size="13" color="red darken-4" class="mr-1"
                        >mdi-alarm</v-icon
                      >
                      <span class="red--text fw-500 font-size-11">{{
                        item.end
                      }}</span>
                    </div>
                  </template>
                  <span>End date</span>
                </v-tooltip>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1">
                <div class="visit-title">
                  <span
                    class="text-truncate d-block fw-600"
                    style="font-size: 14px"
                    >Globex Corporation</span
                  >
                  <span
                    class="text-truncate d-block fw-500"
                    style="font-size: 13px"
                    >{{ item.title }}</span
                  >
                </div>
                <div class="">
                  <span style="color: #63636c"
                    >abc -1235 758468568 jhj4144 565658 Singapore</span
                  >
                </div>
              </div>
              <div class="action-side">
                <div>
                  <v-icon size="18" class="" :color="`${item.status_color}`">
                    mdi-circle
                  </v-icon>
                </div>
                <div>
                  <v-icon size="18" class="my-1"> mdi-flag</v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DetailDrawer
      :drawer="open_detail"
      v-on:close="open_detail = false"
    ></DetailDrawer>
  </div>
</template>
<script>
import DetailDrawer from "@/view/pages/calendar/DetailDrawer";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import DatePicker from "@/view/components/DatePicker";
//import ObjectPath from "object-path";

export default {
  name: "Resource-calender",
  components: {
    FullCalendar,
    DetailDrawer,
    DatePicker,
  },
  data() {
    return {
      showCalendarFilter: false,
      filterType: "UnAssigned Visit",
      calendarFilterType: "Assigned Visit",
      count: 0,
      open_detail: false,
      payload_data: null,
      status: "0",
      customer: "0",
      engineers: "0",
      toolbarTitle: null,
      calendarOptions: {
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        plugins: [resourceTimelinePlugin, interactionPlugin, dayGridPlugin],
        timeZone: "UTC",
        initialView: "resourceTimelineDay",
        aspectRatio: 1.8,
        eventClick: this.handleEventClick,
        resourceLabelDidMount: this.mountResourceLabel,
        eventDidMount: this.mountEventdata,
        eventReceive: this.customEventReceive,
        resourceAreaWidth: "300px",
        droppable: true,
        drop: this.eventDrop,
        eventDragStop: this.customEventDragStop,
        eventDragStart: this.customEventDragStart,
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right:
            "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        },
        editable: true,
        /* resourceLabelDidMount: this.resourceLabel(info), */
        resourceAreaHeaderContent: {
          html: '<div class="text-left mb-1">Engineers</div><div class="position-relative"><input type="text" placeholder="Search Engineers..." class="pr-10 gt-input search-input"  /><div class="magnify"><i class="mdi mdi-magnify v-icon"></i></div></div>',
        },
        resourceAreaHeaderClassNames: "engineers-search",
        resources: null,
        events: null,
        eventRender: this.customEventRender,
        fixedMirrorParent: document.body,
      },
      resource_name: [
        {
          id: "a",
          title: "Auditorium A Auditorium A ",
          designation: "Developer",
          avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
          total_task: 5,
          status_color: "red",
        },
        {
          id: "b",
          title: "Auditorium B",
          designation: "B Developer",
          avatar:
            "https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg",
          total_task: 13,
          status_color: "green",
        },
        {
          id: "c",
          title: "Auditorium C",
          designation: "C Developer",
          avatar:
            "https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg",
          total_task: 3,
          status_color: "green",
        },
        {
          id: "d",
          title: "Auditorium D",
          designation: "D Developer",
          avatar:
            "https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg",
          total_task: 15,
          status_color: "red",
        },
        {
          id: "e",
          title: "Auditorium E",
          designation: "E Developer",
          avatar:
            "https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg",
          status_color: "red",
          total_task: 12,
        },
        {
          id: "f",
          title: "Auditorium F",
          designation: "F Developer",
          avatar:
            "https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg",
          status_color: "green",
          total_task: 5,
        },
      ],
      event_lists: [
        {
          resourceId: "a",
          eventType: "task",
          title: "event 1",
          visitId: "Visit123",
          start: "2023-06-01T10:00:00+00:00",
          end: "2023-06-01T12:00:00+00:00",
          bg_color: "blue",
          classNames: "custom-event blue lighten-3",
        },
        {
          resourceId: "c",
          eventType: "task",
          title: "event 3",
          visitId: "Visit123",
          start: "2023-06-01T12:00:00+00:00",
          end: "2023-06-01T16:00:00+00:00",
          bg_color: "blue",
          classNames: "custom-event blue lighten-3",
        },
        {
          resourceId: "d",
          eventType: "visit",
          title: "event d",
          visitId: "Visit123",
          start: "2023-06-01T08:00:00+00:00",
          end: "2023-06-01T16:00:00+00:00",
          bg_color: "orange",
          classNames: "custom-event orange lighten-3",
        },
        {
          resourceId: "f",
          eventType: "visit",
          title: "event f",
          visitId: "Visit123",
          start: "2023-06-01T08:00:00+00:00",
          end: "2023-06-01T16:00:00+00:00",
          bg_color: "orange",
          classNames: "custom-event orange lighten-3",
        },
        {
          resourceId: "f",
          eventType: "visit",
          title: "event 4",
          visitId: "Visit123",
          start: "2023-06-01T16:30:00+00:00",
          end: "2023-06-01T22:30:00+00:00",
          bg_color: "orange",
          classNames: "custom-event orange lighten-3",
        },
        {
          resourceId: "b",
          eventType: "visit",
          title: "event 5",
          visitId: "Visit123",
          start: "2023-06-01T10:00:00+00:00",
          end: "2023-06-01T15:00:00+00:00",
          bg_color: "orange",
          classNames: "custom-event orange lighten-3",
        },
        {
          resourceId: "e",
          eventType: "leave",
          title: "event 2",
          visitId: "Visit123",
          start: "2023-06-01T05:00:00+00:00",
          end: "2023-06-01T15:00:00+00:00",
          bg_color: "red",
          classNames: "custom-event red lighten-3",
        },
      ],
      visit_list: [
        {
          id: "V000001",
          title: "Painting sssdf",
          headline: "Lorem ipsum dolor sit amet.",
          time: "11:30 AM - 12:30 PM",
          status_color: "orange",
          duration: "02:00",
          classNames: "orange",
          start: "2023-06-01T20:00:00",
          end: "2023-06-01T10:00:00",
        },
        {
          id: "V0000012",
          title: "ADHOC TESTING",
          headline: "Lorem ipsum dolor sit amet.",
          time: "11:30 AM - 12:30 PM",
          status_color: "green",
          classNames: "green",
          start: "01-06-2023 04:30 PM",
          end: "01-06-2023 04:30 PM",
        },
        {
          id: "V000003",
          title: "Inspection",
          headline: "Lorem ipsum dolor sit amet.",
          time: "11:30 AM - 12:30 PM",
          status_color: "pink",
          classNames: "pink",
          start: "01-06-2023 04:30 PM",
          end: "01-06-2023 04:30 PM",
        },
        {
          id: "V000004",
          title: "Delivers",
          headline: "Lorem ipsum dolor sit amet.",
          time: "11:30 AM - 12:30 PM",
          status_color: "blue",
          classNames: "blue",
          start: "01-06-2023 04:30 PM",
          end: "01-06-2023 04:30 PM",
        },
      ],
      statusList: [
        {
          type: 12,
          text: "All Visits",
          value: "all",
          description: "All Visits",
          activity_text: "All Visits",
          color: "cyan",
          textcolor: "white",
          headercolor: "primary",
          order: 1,
          status: 0,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Open",
          value: "open",
          description: "Open Visits",
          activity_text: "Open",
          color: "blue darken-1",
          textcolor: "white",
          headercolor: "primary",
          order: 2,
          status: 1,
          top_visible: 1,
        },
        {
          type: 12,
          text: "In-Progress",
          value: "in-progress",
          description: "In-Progress Visits",
          activity_text: "In-Progress",
          color: "yellow accent-4",
          textcolor: "blue-grey darken-4",
          headercolor: "primary",
          order: 3,
          status: 2,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Hold",
          value: "hold",
          description: "Hold Visits",
          activity_text: "Hold",
          color: "orange darken-4",
          textcolor: "white",
          headercolor: "primary",
          order: 4,
          status: 3,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Completed",
          value: "completed",
          description: "Completed Visits",
          activity_text: "Completed",
          color: "green darken-1",
          textcolor: "white",
          headercolor: "primary",
          order: 5,
          status: 4,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Cancelled",
          value: "cancelled",
          description: "Cancelled Visits",
          activity_text: "Cancelled",
          color: "red lighten-1",
          textcolor: "white",
          headercolor: "primary",
          order: 6,
          status: 5,
          top_visible: 1,
        },
      ],
      customerList: [
        {
          id: 1,
          display_name: "Business Thrust Techsoft Pvt Ltd.",
        },
        {
          id: 2,
          display_name: "jeny",
        },
        {
          id: 3,
          display_name: "Ethan Alex",
        },
      ],
      engineerList: [
        {
          id: 1,
          display_name: "John Doe",
        },
        {
          id: 2,
          display_name: "john",
        },
        {
          id: 3,
          display_name: "Mandarin Mandarin",
        },
        {
          id: 4,
          display_name: "Ang peng Siong",
        },
      ],
    };
  },
  methods: {
    next() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.next();
      this.toolbarTitle = calendarApi.view.title;
    },
    preview() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.prev();
      this.toolbarTitle = calendarApi.view.title;
    },
    getToday() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.today();
      this.toolbarTitle = calendarApi.view.title;
    },
    dayView() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.changeView("resourceTimelineDay");
      this.toolbarTitle = calendarApi.view.title;
    },
    weekView() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.changeView("resourceTimelineWeek");
      this.toolbarTitle = calendarApi.view.title;
    },
    monthView() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.changeView("resourceTimelineMonth");
      this.toolbarTitle = calendarApi.view.title;
    },
    customEventRender(info) {
      console.log(info, "customEventRender");
    },
    mountEventdata(info) {
      console.log("info.event._def.extendedProps", info.event);
      if (info.event._def.extendedProps.visitId != undefined) {
        let infoElement = document.createElement("div");
        // const visit_id = info.event._def.extendedProps.visitId;
        // const visit_info = this.visit_list.find((e) => e.id == visit_id);
        const bt_elem = `<div class="d-flex">
							<div class="pl-6" style="color:#333">
								<b>${info.event.title}</b>
								<span> 10:00AM - 12:00PM</span>
								<div class="time text-truncate" style="font-size:16px;color:#333">Globex Corporation</div>
								<div class="event-type px-2 ${info.event._def.extendedProps.bg_color} darken-3 rounded d-inline-block">${info.event._def.extendedProps.eventType}</div>
							</div>
						</div>`;
        // console.log("visit_info", visit_info);
        infoElement.innerHTML = bt_elem; //info.event._def.extendedProps.visitId;
        infoElement.classList.add("fc-event-id");
        // infoElement.classList.add("darken-2");
        // infoElement.classList.add(info.event._def.extendedProps.bg_color);
        let elementNode = info.el.querySelector(".fc-event-title-container");
        elementNode.prepend(infoElement);
      }
    },
    mountResourceLabel(info) {
      let profile_name = document.createElement("div");
      //let status = document.createElement("span");
      let profile_image = document.createElement("img");
      let total_count = document.createElement("div");

      profile_name.innerHTML =
        info.resource._resource.extendedProps.designation;
      const avatar = info.resource._resource.extendedProps.avatar;
      //status.setAttribute("class", "resource_status green");
      profile_image.setAttribute("src", avatar);
      total_count.innerHTML = info.resource._resource.extendedProps.total_task;

      total_count.setAttribute("class", "resource_user_total");
      if (info.resource._resource.extendedProps.child) {
        profile_image.setAttribute("class", "resource_user_image ml-5");
        profile_name.setAttribute(
          "class",
          "resource_user_designation font-size-13 pl-72"
        );
      } else {
        profile_image.setAttribute("class", "resource_user_image mr-3");
        profile_name.setAttribute(
          "class",
          "resource_user_designation font-size-13 pl-52"
        );
      }
      let elementNode = info.el.querySelector(".fc-datagrid-cell-cushion");
      //let StatusElementNode = info.el.querySelector(".fc-datagrid-cell-main");
      elementNode.style.borderColor = `${info.resource._resource.extendedProps.status_color}`;
      elementNode.classList.add("h-100");
      // const color = info.resource._resource.extendedProps.status_color;
      // elementNode.classList.add('lighten-5');

      elementNode.prepend(profile_image);
      elementNode.append(profile_name);
      elementNode.append(total_count);
      //StatusElementNode.append(status);
    },
    dragableEvent() {
      // console.log('asd');
      let containerEl = document.querySelector(".listing-contents");
      new Draggable(containerEl, {
        itemSelector: ".fc-event",
        eventData: this.getPayload,
      });
    },
    getPayload(eventEl) {
      //console.log({ payload: eventEl });
      this.payload_data = JSON.parse(eventEl.getAttribute("data-set"));
      return this.payload_data;
    },
    eventDrop(data) {
      const payload = this.payload_data;
      const eventData = {
        resourceId: data.resource._resource.id,
        title: payload.title,
        start: data.dateStr,
        end: data.dateStr,
        visitId: payload.id,
        bg_color: payload.status_color,
        classNames: `custom-event h-100 ${payload.status_color}`,
      };
      this.event_lists.push(eventData);
    },
    customEventReceive(info) {
      console.log(info);
    },
    handleEventClick() {
      //this.open_detail = true;
      //console.log(info.event.title);
    },
  },
  mounted() {
    let calendarApi = this.$refs.fullCalendar.getApi();
    this.toolbarTitle = calendarApi.view.title;
    this.calendarOptions.resources = this.resource_name;
    this.calendarOptions.events = this.event_lists;
    this.dragableEvent();
  },
};
</script>
<style scoped></style>
