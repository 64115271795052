<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="12">
            <v-row>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required"
                    >Salutation</label
                  >
                  <v-select
                    v-model.trim="personalInfo.title"
                    :items="salutationList"
                    dense
                    filled
                    :rules="[
                      validateRules.required(personalInfo.title, 'Title'),
                    ]"
                    item-text="text"
                    item-value="value"
                    item-color="cyan"
                    placeholder="Salutation"
                    solo
                    flat
                    color="cyan"
                  ></v-select>
                </template>
              </v-col>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required"
                    >First name</label
                  >
                  <v-text-field
                    v-model.trim="personalInfo.first_name"
                    :rules="[
                      validateRules.required(
                        personalInfo.first_name,
                        'First name'
                      ),
                      validateRules.minLength(
                        personalInfo.first_name,
                        'First name',
                        1
                      ),
                      validateRules.maxLength(
                        personalInfo.first_name,
                        'First name',
                        50
                      ),
                    ]"
                    dense
                    filled
                    placeholder="First name"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-col>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16">Last name</label>
                  <v-text-field
                    v-model.trim="personalInfo.last_name"
                    :rules="[
                      validateRules.minLength(
                        personalInfo.last_name,
                        'Last name',
                        1
                      ),
                      validateRules.maxLength(
                        personalInfo.last_name,
                        'Last name',
                        50
                      ),
                    ]"
                    dense
                    filled
                    placeholder="Last name"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required"
                    >Display name</label
                  >
                  <v-text-field
                    v-model.trim="personalInfo.display_name"
                    :rules="[
                      validateRules.required(
                        personalInfo.display_name,
                        'Display name'
                      ),
                      validateRules.minLength(
                        personalInfo.display_name,
                        'Display name',
                        1
                      ),
                      validateRules.maxLength(
                        personalInfo.display_name,
                        'Display name',
                        50
                      ),
                    ]"
                    dense
                    filled
                    placeholder="Display name"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required">
                    Designation</label
                  >
                  <v-autocomplete
                    :items="engineerDesignations"
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    solo
                    flat
                    v-model.trim="personalInfo.designation"
                    placeholder="Select Designation"
                    item-text="text"
                    item-value="value"
                    class="width-100 new-height"
                    hide-details
                    :rules="[
                      validateRules.required(
                        personalInfo.designation,
                        'Designationdata'
                      ),
                    ]"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Designation(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>

                  <template>
                    <ManageEdesignation
                      :d-dialog="manageBrandDialog"
                      :e_designation="engineerDesignations"
                      v-on:close-dialog="manageBrandDialog = false"
                      :exist-category="personalInfo.designation"
                      v-on:get-e-designation="getOptions"
                    ></ManageEdesignation>
                  </template>

                  <!-- <v-select
                    :items="engineerDesignations"
                    v-model.trim="personalInfo.designation"
                    :rules="[
                      validateRules.required(
                        personalInfo.designation,
                        'Designation'
                      ),
                    ]"
                    dense
                    filled
                    placeholder="Designation"
                    solo
                    flat
                    item-color="cyan"
                    item-text="title"
                    item-value="value"
                  ></v-select> -->
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required"
                    >Skills</label
                  >
                  <v-select
                    :items="engineerSkills"
                    v-model.trim="personalInfo.skills"
                    :rules="[
                      validateRules.required(personalInfo.skills, 'Skills'),
                    ]"
                    dense
                    filled
                    placeholder="Skills"
                    solo
                    flat
                    item-color="cyan"
                    item-text="title"
                    item-value="value"
                    multiple
                  ></v-select>
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required"
                    >Email address</label
                  >
                  <v-text-field
                    v-model.trim="personalInfo.primary_email"
                    :rules="[
                      validateRules.required(
                        personalInfo.primary_email,
                        'Email address'
                      ),
                      validateRules.validEmail(
                        personalInfo.primary_email,
                        'Email address'
                      ),
                      validateRules.minLength(
                        personalInfo.primary_email,
                        'Email address',
                        1
                      ),
                      validateRules.maxLength(
                        personalInfo.primary_email,
                        'Email address',
                        50
                      ),
                    ]"
                    dense
                    filled
                    placeholder="Email address"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required"
                    >Phone number</label
                  >
                  <PhoneTextField
                    id="primary_phone"
                    v-model="personalInfo.primary_phone"
                    :value="personalInfo.primary_phone"
                    required
                  >
                  </PhoneTextField>
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>

                <template v-else>
                  <label class="font-weight-700 font-size-16 required"
                    >Role</label
                  >
                  <template v-if="engineer?.id">
                    <v-select
                      :items="roles"
                      v-model.trim="personalInfo.role"
                      :rules="[
                        validateRules.required(personalInfo.role, 'Role'),
                      ]"
                      dense
                      filled
                      placeholder="Select Role"
                      solo
                      flat
                      item-color="cyan"
                      item-text="name"
                      item-value="id"
                      readonly
                    ></v-select>
                  </template>
                  <template v-else>
                    <v-select
                      :items="roles"
                      v-model.trim="personalInfo.role"
                      :rules="[
                        validateRules.required(personalInfo.role, 'Role'),
                      ]"
                      dense
                      filled
                      placeholder="Select Role"
                      solo
                      flat
                      item-color="cyan"
                      item-text="name"
                      item-value="id"
                    ></v-select>
                  </template>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16"
                    >Probation Period</label
                  >
                  <v-select
                    :items="months"
                    v-model.trim="personalInfo.probation_month_number"
                    dense
                    filled
                    placeholder="Probation Month"
                    solo
                    v-on:change="setProbationTime"
                    flat
                    item-color="cyan"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16">Type </label>
                  <template v-if="personalInfo.probation_type">
                    <v-select
                      :items="types"
                      v-model.trim="personalInfo.probation_type"
                      dense
                      filled
                      placeholder="Select Type"
                      solo
                      flat
                      v-on:change="setProbationTime"
                      item-color="cyan"
                      item-text="text"
                      item-value="value"
                      readonly
                    ></v-select>
                  </template>
                  <template v-else>
                    <v-select
                      :items="types"
                      v-model.trim="personalInfo.probation_type"
                      dense
                      filled
                      placeholder="Select Type"
                      solo
                      flat
                      v-on:change="setProbationTime"
                      item-color="cyan"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </template>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16"
                    >Probation Start</label
                  >
                  <DatePicker
                    placeholder="Probation Start Date"
                    v-model="personalInfo.probation_start"
                    v-on:change="setProbationTime"
                  />
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16"
                    >Probation End</label
                  >

                  <DatePicker
                    :minDate="personalInfo.probation_start"
                    placeholder="Probation End Date"
                    v-model="personalInfo.probation_end"
                  />
                </template>
              </v-col>
            </v-row>

            <v-row class="mt-1">
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-width-100px custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>

                <v-switch
                  v-else
                  v-model.trim="personalInfo.is_tl"
                  inset
                  :value="1"
                  color="red"
                  hide-details
                  label="Is TL"
                  class="mt-0"
                  v-on:change="allowLogin(personalInfo.is_tl)"
                ></v-switch>
              </v-col>
              <v-col md="6" class="py-0">
                <div class="d-flex">
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-width-100px custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <v-switch
                    v-else
                    v-model.trim="personalInfo.can_login"
                    inset
                    :value="1"
                    color="cyan"
                    hide-details
                    label="Allow Login"
                    class="mt-0"
                    readonly
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container white lighten-3 text-center"
        cols="12"
        md="3"
        sm="3"
      >
        <div class="text-center">
          <template v-if="updateEngineer && pageLoading">
            <v-skeleton-loader
              class="custom-skeleton custom-avatar-100px"
              type="avatar"
            >
            </v-skeleton-loader>
          </template>
          <template v-else>
            <v-progress-circular
              indeterminate
              :size="100"
              :width="10"
              color="cyan"
              v-if="primaryLoader"
            ></v-progress-circular>
            <v-img
              v-else
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage ? getProfileImage : $defaultProfileImage"
              max-height="200"
              max-width="200"
              height="140px"
              width="140px"
              class="custom-grey-border custom-border-radius-50 mx-auto"
            >
            </v-img>
          </template>
        </div>
        <div class="py-5 text-center">
          <template v-if="updateEngineer && pageLoading">
            <v-skeleton-loader
              class="mx-4 custom-skeleton small-skeleton-action-button"
              type="button"
            >
            </v-skeleton-loader>
          </template>
          <v-btn
            v-else
            class="mx-4 custom-bold-button"
            outlined
            small
            :disabled="primaryLoader"
            :loading="primaryLoader"
            v-on:click="selectImage"
            color="cyan"
          >
            <template v-if="personalInfo.profile_image">Change Image</template>
            <template v-else>Upload Image</template>
          </v-btn>
          <div class="d-none">
            <v-file-input
              v-model.trim="rawImage"
              ref="uploadImage"
              :rules="imageRules"
              accept="image/png, image/jpeg, image/bmp"
            ></v-file-input>
          </div>
        </div>
        <template v-if="false">
          <template v-for="(fieldInfo, indx) in fieldDescriptions">
            <div
              v-if="fieldInfo.field == currentActiveField"
              :key="indx"
              class="help-sidebar"
            >
              <div class="help-sidebar-header">
                <h3 v-if="fieldInfo.title" class="section-header-title">
                  {{ fieldInfo.title }}
                </h3>
              </div>
              <p v-if="fieldInfo.description" class="help-sidebar-description">
                {{ fieldInfo.description }}
              </p>
              <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
                {{ fieldInfo.footer }}
              </h5>
              <p
                v-if="fieldInfo.footer_content"
                class="help-sidebar-footer-text"
              >
                {{ fieldInfo.footer_content }}
              </p>
            </div>
          </template>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
.v-input input {
  max-height: 30px;
}
</style>
<script>
import ManageEdesignation from "@/view/pages/partials/Manage-Edesignation.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { GET } from "@/core/services/store/request.module";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import DatePicker from "@/view/components/QDatePicker.vue";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "personal-info",
  mixins: [ValidationMixin, CommonMixin, FileManagerMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      engineerSkills: [],
      manageBrandDialog: false,
      engineerDesignations: [],
      e_designation: [],
      leaveTypes: [],
      timeoutLimit: 100,
      roles: [],
      months: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
        { value: 11, text: "11" },
        { value: 12, text: "12" },
      ],
      types: [{ value: "month", text: "Month" }],
      timeout: null,
      personalInfo: {
        profile_image: null,
        probation_start: null,
        probation_end: null,
        probation_month_number: null,
        probation_type: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        designation: 0,
        primary_email: null,
        primary_phone: null,
        engineerLeaves: {},
        skills: [],
        leave: null,
        can_login: 1,
        allow_leave: 0,
        is_driver: 0,
        is_tl: 1,
        role: 2,
      },
      rawImage: null,
      fieldDescriptions: [],
    };
  },
  watch: {
    "personalInfo.first_name"() {
      this.personalInfo.display_name =
        (this.personalInfo.first_name || "") +
        " " +
        (this.personalInfo.last_name || "");
    },
    "personalInfo.last_name"() {
      this.personalInfo.display_name =
        (this.personalInfo.first_name || "") +
        " " +
        (this.personalInfo.last_name || "");
    },
    rawImage(param) {
      const _this = this;
      _this.uploadAttachment(param).then((response) => {
        _this.personalInfo.profile_image = _this.lodash.head(response);
      });
    },
    personalInfo: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:personal-info", param);
        }, _this.timeoutLimit);
      },
    },
    engineer() {
      this.setPersonalInfo();
    },
  },
  methods: {
    allowLogin(param) {
      if (param) {
        this.personalInfo.can_login = 1;
      } else {
        this.personalInfo.can_login = 0;
      }
      console.log(param);
    },
    setProbationTime() {
      if (this.personalInfo && this.personalInfo.probation_start) {
        let date = moment(this.personalInfo.probation_start, "YYYY-MM-DD");
        if (
          this.personalInfo.probation_month_number &&
          this.personalInfo.probation_type
        ) {
          date.add(
            this.personalInfo.probation_month_number,
            this.personalInfo.probation_type
          );
          date.subtract(1, "days");
        }
        this.personalInfo.probation_end = date.format("YYYY-MM-DD");
      }
    },
    Designationdata() {
      this.manageBrandDialog = true;
      this.getOptions();
    },
    getRolesNew() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "user-role-new" })
        .then(({ data }) => {
          _this.roles = data;
          // console.log(_this.roles, "hello");
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "engineer/options",
        })
        .then(({ data }) => {
          this.$emit("barcode", data.barcode);
          _this.engineerDesignations = this.lodash.map(
            data.engineer_designation,
            function (row) {
              return {
                text: row.text,
                value: row.id,
              };
            }
          );
          _this.leaveTypes = this.lodash.map(data.leave_types, function (row) {
            return {
              title: row.value,
              value: row.id,
            };
          });
          _this.engineerSkills = this.lodash.map(data.skills, function (row) {
            return {
              title: row.value,
              value: row.value.toLowerCase(),
            };
          });
          /*  EngineerEventBus.$emit("update:option:data", data); */
          /*  const _this = this;
          clearTimeout(_this.timeout);
          _this.timeout = setTimeout(function () {
            EngineerEventBus.$emit("update:option:data", data);
          }, _this.timeoutLimit); */
          /*   _this.skillCertificate = this.lodash.map(data.skillCertificate, function (row) {
            return {
              title: row.text,
              value: row.value.toLowerCase(),
            };
          });
          _this.licenseCertificate = this.lodash.map(data.licenseCertificate, function (row) {
            return {
              title: row.text,
              value: row.value.toLowerCase(),
            };
          }); */
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    setPersonalInfo() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.personalInfo.profile_image = _this.engineer.profile_logo;
        _this.personalInfo.title = _this.engineer.title;
        _this.personalInfo.first_name = _this.engineer.first_name;
        _this.personalInfo.last_name = _this.engineer.last_name;
        _this.personalInfo.role = _this.engineer.role.id;
        _this.personalInfo.display_name = _this.engineer.display_name;
        if (_this.engineer.designation && _this.engineer.designation.id) {
          _this.personalInfo.designation = _this.engineer.designation.id;
        }
        _this.personalInfo.skills = _this.engineer.skills;
        _this.personalInfo.primary_email = _this.engineer.primary_email;
        _this.personalInfo.probation_start = _this.engineer.probation_start;
        _this.personalInfo.probation_end = _this.engineer.probation_end;
        _this.personalInfo.probation_type = _this.engineer.probation_type;
        _this.personalInfo.probation_month_number =
          _this.engineer.probation_month_number;
        _this.personalInfo.primary_phone = _this.engineer.primary_phone;
        _this.personalInfo.leave = _this.engineer.leave;
        _this.personalInfo.can_login = +_this.engineer.can_login;
        _this.personalInfo.allow_leave = +_this.engineer.allow_leave;
        _this.personalInfo.is_driver = +_this.engineer.is_driver;
        _this.personalInfo.is_tl = +_this.engineer.is_tl;
        if (_this.engineer.leaves && _this.engineer.leaves.length > 0) {
          for (let i = 0; i < _this.engineer.leaves.length; i++) {
            const { leave, leave_allowed } = _this.engineer.leaves[i];
            _this.personalInfo.engineerLeaves[leave.id] = leave_allowed;
          }
        }
      }
    },
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.personalInfo.profile_image) === false
        ? this.personalInfo.profile_image.file.url
        : null;
    },
  },
  components: {
    PhoneTextField,
    ManageEdesignation,
    DatePicker,
  },
  mounted() {
    this.setPersonalInfo();
    this.getOptions();
    this.getRolesNew();
  },
};
</script>
