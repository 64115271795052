<template>
  <v-row>
    <v-col md="12">
      <table class="w-100">
        <thead>
          <tr>
            <th width="5%">#</th>
            <th width="10%">Type</th>
            <th width="75%">Label</th>
            <th width="10%"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in quoteNoteList"
            :key="`quote-note-${index}`"
            class="border-top"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <v-chip
                label
                small
                color="blue"
                text-color="white"
                class="text-uppercase"
                >{{ row.type }}
              </v-chip>
            </td>
            <td>{{ row.label }}</td>
            <td>
              <v-btn
                color="cyan"
                class="text-white"
                depressed
                :disabled="pageLoading"
                :loading="pageLoading"
                @click="editDialog(row)"
              >
                <v-icon size="15">mdi-pencil</v-icon> Update
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <Dialog :common-dialog="updateDialog" :dialog-width="dialogWidth">
        <template v-slot:title>
          <div class="d-flex align-center justify-space-between w-100">
            <div>
              <span class="text-capitalize"> Update {{ editNote.type }}</span>
            </div>
            <div v-if="editNote.type != 'exclusions'">
              <v-btn small class="mx-4" v-on:click="createQuoteType">
                <span class="font-size-16 font-weight-600"
                  >Add new {{ editNote.type }}</span
                >
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:body>
          <v-container class="custom-listing-table">
            <div v-if="editNote.type == 'exclusions'">
              <TinyMCE v-model="exclusions" />
            </div>
            <table v-else class="width-100 simple-table">
              <thead>
                <tr>
                  <th class="simple-table-th hide-border-top" width="5%">
                    Primary
                  </th>
                  <th class="simple-table-th hide-border-top">Text</th>
                  <th class="simple-table-th hide-border-top" width="5%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(type, index) in quoteTypeList" :key="index">
                  <td class="simple-table-td" align="center">
                    <div class="d-flex align-center justify-center">
                      <v-checkbox
                        class="hide-margin"
                        dense
                        color="cyan"
                        :disabled="!type.text"
                        :input-value="type.primary"
                        v-on:change="updatePrimary($event, index)"
                      />
                    </div>
                  </td>
                  <td class="simple-table-td">
                    <v-text-field
                      v-model.trim="type.text"
                      dense
                      filled
                      :label="`Enter ${editNote.type}`"
                      solo
                      flat
                      class="py-2"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    />
                  </td>
                  <td class="simple-table-td" width="50">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on:click="deleteQuoteType(index)"
                          color="deep-orange"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Click here to delete</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="custom-bold-button"
            :disabled="formLoading"
            v-on:click="updateDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            class="custom-bold-button"
            :disabled="formLoading"
            :loading="formLoading"
            v-on:click="updateOrCreateNoteType()"
            color="cyan white--text"
            >Save</v-btn
          >
        </template>
      </Dialog>
    </v-col>
  </v-row>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import Dialog from "@/view/pages/partials/Dialog";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  name: "quotation-note-template",
  data() {
    return {
      pageLoading: false,
      updateDialog: false,
      formLoading: false,
      quoteNoteList: [],
      quoteTypeList: [
        {
          text: null,
          primary: false,
        },
      ],
      exclusions: null,
      editNote: {},
    };
  },
  components: {
    Dialog,
    TinyMCE,
  },
  props: {
    quoteType: {
      type: String,
      default: "new",
    },
  },
  methods: {
    updatePrimary(status, index) {
      if (this.editNote.type !== "remark") {
        for (let i = 0; i < this.quoteTypeList.length; i++) {
          this.quoteTypeList[i].primary = false;
        }
      }

      this.$nextTick(() => {
        this.quoteTypeList[index].primary = status;
      });
    },
    updateOrCreateNoteType() {
      const _this = this;
      const data = {
        id: _this.editNote.id,
        quote_note_type_id: null,
        type: _this.editNote.type,
        label: null,
        all_rows: this.quoteTypeList,
        quote_type: _this.quoteType,
      };

      if (_this.editNote.type == "remark") {
        const remarks = this.quoteTypeList
          ?.filter((row) => row.primary)
          ?.map((row) => row.text);
        data.label = JSON.stringify(remarks);
      } else if (_this.editNote.type == "exclusions") {
        data.label = this.exclusions;
      } else {
        const primaryNote = this.quoteTypeList.find((row) => row.primary);

        if (!primaryNote) {
          ErrorEventBus.$emit("update:error", "Select atleast one.");
          return false;
        }

        data.quote_note_type_id = primaryNote?.id;
        data.label = primaryNote?.text;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, { url: "setting/quotation-notes", data: {
          rows: this.quoteTypeList,
          quote_type: this.quoteType,
          type: this.editNote.type,
        } })
        .then(() => {
          _this.updateDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
          _this.getNotes();
        });
    },
    createQuoteType() {
      this.quoteTypeList.push({
        text: null,
        primary: false,
        new: true
      });
    },
    deleteQuoteType(index) {
      if (this.quoteTypeList.length > 1) {
        this.quoteTypeList.splice(index, 1);
      }
    },
    editDialog(row) {
      this.editNote = row;
      this.editNote.type = String(row.type).replace(/\s+/g, '_');
      if (row.type == "exclusions") {
        this.exclusions = row.editLabel;
        this.updateDialog = true;
        this.quoteTypeList = [];
        return false;
      }
      this.getNoteTypes();
    },
    getNoteTypes() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: `setting/quote-notes/${this.editNote.type}?quote_type=${this.quoteType}`,
        })
        .then(({ data }) => {
          _this.quoteTypeList = data.allNotes?.map((row) => ({
            ...row,
            new: false,
            primary: false,
          }));
          _this.updateDialog = true;
          _this.$nextTick(() => {
            for (let i = 0; i < _this.quoteTypeList.length; i++) {
              _this.quoteTypeList[i].primary =
                _this.editNote.type === "remark"
                  ? _this.editNote.editLabel.includes(
                      _this.quoteTypeList[i].text
                    )
                  : _this.editNote.quote_note_type_id ===
                    _this.quoteTypeList[i].id;
            }

            _this.quoteTypeList = _this.quoteTypeList.sort(
              (a, b) => b.primary - a.primary
            );
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getNotes() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: `setting/quotation-notes?quote_type=${this.quoteType}`,
        })
        .then(({ data }) => {
          this.quoteNoteList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getNotes();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
