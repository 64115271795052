<template
  :routePreventDialog="routePreventDialog"
  :customClass="'leave-update'"
>
  <div v-if="drawer">
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 99"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-1 px-5 d-flex align-items-center white--text orange darken-2"
        >
          <div class="form-title my-auto" v-if="leaveCreate.id > 0">
            Update Leave
          </div>
          <div class="form-title my-auto" v-else>Create Leave</div>
          <v-spacer></v-spacer>
          <v-btn
            tile
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="custom-bold-button"
            color="cyan white--text"
            v-on:click="CreateOrupdateLeave"
            >Save</v-btn
          >
          <!-- <v-btn tile color="" class="ml-2" v-on:click="$emit('close')" -->
          <v-btn tile color="" class="ml-2" v-on:click="$emit('close')"
            >Cancel</v-btn
          >
        </v-flex>
        <v-form
          ref="leaveForm"
          id="leaveForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="CreateOrupdateLeave"
        >
          <div class="drawer-content pt-5 px-5">
            <div class="d-form-grp mb-2" v-if="!leaveCreate.id">
              <label class="my-auto fw-500 required" required
                >Select Staff</label
              >
              <v-autocomplete
                hide-details
                dense
                solo
                flat
                placeholder="Select Staff"
                :items="userList"
                :disabled="pageLoading"
                :loading="pageLoading"
                item-value="id"
                item-text="full_name"
                v-on:change="getCategoryOptions"
                v-model.trim="leaveCreate.staff"
                :rules="[
                  validateRules.required(leaveCreate.staff, 'Select Staff'),
                ]"
              ></v-autocomplete>
            </div>

            <template v-if="leaveCreate && leaveCreate.staff != null">
              <div class="mb-3 d-flex blue lighten-5 pa-2">
                <div class="profile-img">
                  <v-avatar size="100" class="mr-4">
                    <img
                      v-if="userData.profile_logo"
                      :src="userData.profile_logo"
                    />
                    <img
                      v-else
                      src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                    />
                  </v-avatar>
                </div>
                <div class="profile-desc">
                  <!-- <div class="mb-2">
										<span class="fw-600">User # : </span>
										<span class="fw-500" v-if="userData && userData.barcode"
											><v-chip color="cyan" label outlined x-small class="text-capitalize">
												{{ userData.barcode }}
											</v-chip>
										</span>
									</div> -->
                  <div class="mb-2">
                    <span class="fw-600">Name : </span>
                    <span
                      class="fw-500"
                      v-if="userData && userData.full_name"
                      >{{ userData.full_name }}</span
                    >
                  </div>
                  <div class="mb-2">
                    <span class="fw-600">Email : </span>
                    <span
                      class="fw-500"
                      v-if="userData && userData.user_email"
                      >{{ userData.user_email }}</span
                    >
                  </div>
                  <div class="mb-2">
                    <span class="fw-600">Phone Number : </span>
                    <span
                      class="fw-500"
                      v-if="userData && userData.phone_number"
                      >{{ userData.phone_number }}</span
                    >
                  </div>
                  <!-- <div class="mb-2">
										<span class="fw-600">Joining Date : </span>
										<span class="fw-500" v-if="userData && userData.joining_date">{{
											formatDate(userData.joining_date)
										}}</span>
									</div> -->
                  <!-- <div class="mb-2">
										<span class="fw-600">Probation Period : </span>
										<span class="fw-500" v-if="userData && userData.joining_date && userData.provision_date"
											>{{ formatDate(userData.joining_date) }} <b>To</b>
											{{ formatDate(userData.provision_date) }}</span
										>
										<span class="fw-600 ml-2">
											<v-chip
												v-if="probisionTime >= 0"
												color="red"
												class="white--text"
												outlined
												style="letter-spacing: 0.8px"
												small
											>
												Probation {{ getProvision(userData.provision_date) }}
											</v-chip>
										</span>
									</div> -->
                  <div class="mb-2">
                    <span class="fw-600">Designation : </span>
                    <v-chip
                      color="primary"
                      label
                      outlined
                      x-small
                      class="ml-2 text-capitalize"
                      v-if="userData && userData.designation"
                    >
                      <template v-if="userData.designation">{{
                        userData.designation.name
                      }}</template>
                      <em v-else class="text-muted">no designation</em>
                      <!-- <template v-else>{{ userData.user_designation }}</template> -->
                    </v-chip>
                  </div>
                </div>
              </div>
            </template>
            <div
              class="d-form-grp mt-4 orange lighten-5"
              v-if="leaveCreate.staff"
            >
              <!-- <label class="input-label required" required> Leave Type</label> -->
              <div class="v-data-table bt-table theme--light">
                <div
                  class="v-data-table__wrapper"
                  style="height: auto !important"
                >
                  <table class="w-100">
                    <thead>
                      <tr>
                        <th class="simple-table-th">#</th>
                        <th class="simple-table-th">Type</th>
                        <th class="simple-table-th">Remaining</th>
                        <th class="simple-table-th">Pending</th>
                        <th class="simple-table-th">Approved</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(row, index) in categoryList">
                        <tr :key="index" class="customer-listing-row">
                          <td class="simple-table-td">
                            <v-radio-group
                              v-model="leaveCreate.category"
                              row
                              hide-details
                              class="mt-0 test"
                            >
                              <v-radio
                                label=""
                                :value="row.category"
                                class="mt-0 test-auto"
                              ></v-radio>
                            </v-radio-group>
                          </td>
                          <td
                            class="simple-table-td text-capitalize"
                            style="padding-left: 16px !important"
                          >
                            {{ row.category.replace("-", " ") }}
                          </td>
                          <td
                            class="simple-table-td"
                            style="padding-left: 16px !important"
                          >
                            {{ row.balance }}
                          </td>
                          <td
                            class="simple-table-td"
                            style="padding-left: 16px !important"
                          >
                            {{ row.pending }}
                          </td>
                          <td
                            class="simple-table-td"
                            style="padding-left: 16px !important"
                          >
                            {{ row.taken }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div v-for="(row, index) in categoryList" :key="index">
								<span>
									<v-radio-group v-model="leaveCreate.category" row hide-details class="mt-0 test">
										<div>
											<v-radio
											label=""
											class="mt-0 test-auto"
											:value="row.category"
											v-on:change="categoryDays"
											v-on:click="categoryDays"
										></v-radio>
										</div>
										<v-chip color="primary" label outlined x-small class="ml-2" v-if="row && row.category">
											{{ row.category }}
											<template v-if="row.allocated_days">( {{ row.allocated_days }} )</template></v-chip
										>

										<v-chip color="red" label outlined x-small class="ml-2" v-if="row && row.balance">
											{{ "Remaining" }} ({{ row.balance }})</v-chip
										>
										<v-chip color="orange" label outlined x-small class="ml-2" v-if="row && row.balance">
											{{ "Pending" }} ({{ row.pending }})</v-chip
										>
										<v-chip color="green" label outlined x-small class="ml-2" v-if="row">
											{{ "Approved" }} ( {{ row.taken }} )</v-chip
										>
									</v-radio-group>
								</span>
							</div> -->
            </div>
            <div class="d-form-grp mt-4">
              <label class="input-label required">Start Date</label>
              <DatePicker
                solo
                flat
                :disabled="disabledDate"
                :mandatory="true"
                :nullable="leaveCreate && leaveCreate.id > 0 ? false : true"
                placeholder="Start Date"
                v-model="start_date"
                :min-date="minDate"
                :default-date="start_date"
                offWeekend
                v-on:change="(e) => calculateDate(leaveCreate, e, 'start')"
              ></DatePicker>
              <!-- <DatePicker
                depressed
                :mandatory="true"
                :nullable="leaveCreate && leaveCreate.id > 0 ? false : true"
                placeholder="Start Date"
                v-model="start_date"
                :min-date="minDate"
                :default-date="start_date"
                v-on:change="calculateDate(leaveCreate)"
              ></DatePicker> -->
            </div>
            <div class="d-form-grp mt-4">
              <label class="input-label required">End Date</label>

              <template
                v-if="leaveCreate && leaveCreate.id > 0 && end_date == null"
              >
                <DatePicker
                  :mandatory="true"
                  :nullable="leaveCreate"
                  :disabled="disabledDate"
                  placeholder="End Date"
                  v-model="end_date"
                  :min-date="start_date"
                  :max-date="maxDate"
                  :default-date="end_date"
                  offWeekend
                  v-on:change="(e) => calculateDate(leaveCreate, e, 'end')"
                ></DatePicker>
              </template>
              <template v-else>
                <DatePicker
                  solo
                  :disabled="disabledDate"
                  flat
                  :nullable="leaveCreate && leaveCreate.id > 0 ? false : true"
                  placeholder="End Date"
                  v-model="end_date"
                  :min-date="start_date"
                  :max-date="maxDate"
                  :default-date="end_date"
                  v-on:change="(e) => calculateDate(leaveCreate, e, 'end')"
                  :mandatory="true"
                  offWeekend
                ></DatePicker>
              </template>
            </div>
            <div style="margin-bottom: 10px !important">
              <div class="d-form-grp mt-4 w-20">
                <label class="input-label">Half Day</label>
                <v-switch
                  :disabled="diableDay"
                  v-model="leaveCreate.duration"
                  :value="leaveCreate.duration"
                  dense
                  hide-details
                  class="ma-0"
                  v-on:change="changeData(leaveCreate.duration)"
                ></v-switch>
              </div>
              <div class="d-form-grp mt-4" v-if="leaveCreate.duration == 1">
                <label class="input-label"></label>

                <v-radio-group
                  v-model="leaveCreate.duration_type"
                  row
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio label="First half" :value="1" class="ma-0"></v-radio>
                  <v-radio
                    label="Second half"
                    :value="2"
                    class="ma-0"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div
                class="d-form-grp mt-4"
                v-if="leaveCreate.duration == 1 && start_date != end_date"
              >
                <label class="input-label"></label>

                <v-radio-group
                  v-model="leaveCreate.day_type"
                  row
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio
                    :label="`Start Date : ${formatDate(this.start_date)}`"
                    :value="1"
                    class="ma-0 mr-2"
                  ></v-radio>
                  <v-radio
                    :label="`End Date : ${formatDate(this.end_date)}`"
                    :value="2"
                    class="ma-0"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
            <div class="mt-2">
              <span>Total Leave : </span>
              <span class="fw-500">
                <span class="primary--text" v-if="start_date && end_date">
                  {{ getBusinessDays() }}
                  <!-- <span
                    v-if="
                      probisionTime >= 0 || leaveCreate.categories == 'UnPaid'
                    "
                  >
                    (Unpaid)</span
                  > -->
                </span>
                <span v-else>0</span>
              </span>
            </div>
            <div class="">
              <div class="d-flex pt-1">
                <label>Attachment</label>
              </div>
            </div>
            <template v-for="(file, index) in files">
              <div class="d-flex" :key="index">
                <div
                  class="w-40 pr-3"
                  style="margin-top: -12px; margin-left: -3px"
                >
                  <v-file-input
                    :id="`document-file-${index}`"
                    placeholder="Select File"
                    outlined
                    class="custome-files mt-3"
                    prepend-icon=""
                    prepend-inner-icon="mdi-attachment"
                    hide-details
                    v-model="file.file"
                    v-on:change="updateFile(index, $event)"
                    v-on:click:clear="updateFile(index, $event)"
                  ></v-file-input>
                </div>
                <div class="w-40 pr-3" style="margin-top: -12px">
                  <TextInput
                    :id="`document-name-${index}`"
                    v-model="file.name"
                    hide-details
                    placeholder="File Name"
                    :suffix="file.suffix"
                    class="custome-files mt-3"
                  ></TextInput>
                </div>
                <div class="w-10 pr-3" style="margin-top: -12px">
                  <v-btn
                    :disabled="files.length < 2"
                    v-on:click="removeFile(index)"
                    color="red lighten-1 white--text"
                    class="mt-3"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <div class="w-10 pr-3" style="margin-top: -12px">
                  <v-btn
                    color="cyan white--text"
                    class="mt-3 ml-2"
                    tile
                    depressed
                    v-on:click="addMore()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
            <div class="d-form-grp mt-4">
              <label class="input-label">Reason for leave</label>
              <v-textarea
                v-model="leaveCreate.reason"
                auto-grow
                dense
                filled
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
                label="Remark"
                solo
                v-on:paste="(e) => onPaste(e)"
                v-on:keypress="(e) => manageLimit(e)"
                flat
                row-height="20"
              ></v-textarea>
              <div style="text-align: end">
                {{ leaveCreate.reason ? leaveCreate.reason.length : 0 }}/200
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </v-navigation-drawer>
    <Dialog :common-dialog="statusDialog" :dialog-width="640">
      <template v-slot:title>
        <div class="d-flex">
          <div>Confirmed<br /></div>
        </div>
      </template>
      <template v-slot:body>
        <p class="btx-p m-0 text-align-center">
          <template v-for="(row, index) in visitArr">
            <span :key="index">
              <h3 class="font-size-16 font-weight-500 delete-text">
                <span
                  class="font-weight-700"
                  v-if="userData && userData.full_name"
                  >{{ userData.full_name }}</span
                >
                is already assigned to
                <span class="font-weight-700" color="red">{{
                  row.barcode
                }}</span>
                on
                <!-- {{ formatDate(row.started_at)}} -->
                {{ formatDate(row.started_at) }}
                {{ formatDateTimeStart(row.started_at) }} -
                {{ formatetimedata(row.finished_at) }}
              </h3>
            </span>
          </template>
        </p>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          style="min-width: 78px !important"
          v-on:click="continueData"
        >
          Continue
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="statusDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<style>
/* .bt-table .v-data-table__wrapper {
    height: auto;
    overflow: auto;
} */
</style>
<script>
/* import MainMixin from "@/core/mixins/main.mixin.js"; */
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import { POST, GET } from "@/core/services/store/request.module";
import moment from "moment-timezone";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { toSafeInteger, filter, find, toString } from "lodash";
import { getCurrentUser } from "@/core/services/jwt.service";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import TextInput from "@/view/pages/TextInput";
import { LeaveApplyEventBus } from "@/core/lib/leave.from.lib.js";
/* import { LeaveEventBus } from "@/core/lib/leave.form.lib"; */
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "CreateLeave",
  components: {
    DatePicker,
    TextInput,
    Dialog,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    leaveUpdateData: {
      type: Object,
    },
    leaveIds: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formLoading: false,
      probisionTime: 0,
      formValid: true,
      minDate: new Date().toISOString().substr(0, 10),
      leave: null,
      updateDialog: false,
      apprveLeaves: 0,
      pendingLeaves: 0,
      statusDialog: false,
      allocated: 0,
      diableDay: false,
      pageLoading: false,
      userData: [],
      categoryList: new Array(),
      is_visit_validated: 1,
      visitArr: [],
      start_date: null,
      end_date: null,
      maxDate: null,
      weekEndDays: 0,
      userList: new Array(),
      remainingLeaves: null,
      files: [
        {
          file: null,
          name: null,
        },
      ],
      leaveList: [
        {
          category: null,
          maxdays: null,
          remainig_days: null,
        },
      ],
      leaveCreate: new Object({
        id: null,
        staff: null,
        category: "medical-leave",
        remainig_days: null,
        duration: 0,
        duration_type: 1,
        day_type: 1,
        reason: null,
      }),
    };
  },
  watch: {
    start_date() {
      this.categoryDays();
    },
    leaveUpdateData: {
      handler(param) {
        if (param) {
          this.init(param);
        }

        /* this.getCategoryOptions(param.staff.id); */
      },
    },
  },
  methods: {
    manageLimit(e) {
      if (this.leaveCreate.reason && this.leaveCreate.reason.length > 199) {
        e.preventDefault();
      }
    },
    /* onPaste(e){
         let coppied = e.clipboardData.getData("Text")
		 if(e && coppied && coppied.length){
			this.leaveCreate.reason = coppied.substring(0, 200)
			e.preventDefault();
		 } 
		}, */
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.leaveCreate.reason;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.leaveCreate.reason = trimValue;
        e.preventDefault();
      }
    },
    continueData() {
      (this.is_visit_validated = 0), console.log(this.is_visit_validated);
      this.statusDialog = false;
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      return moment(row).format("hh:mm A");
    },
    calculateDate(data, e, type) {
      if (data.id == null && data.category != "UnPaid") {
        let firstDay = moment(this.start_date);
        let lastDay = moment(this.end_date);
        if (type == "start") {
          firstDay = moment(e);
        } else {
          lastDay = moment(e);
        }

        if (firstDay && lastDay) {
          let calcBusinessDays =
            1 +
            (lastDay.diff(firstDay, "days") * 5 -
              (firstDay.day() - lastDay.day()) * 2) /
              7;
          if (lastDay.day() == 6) calcBusinessDays--; //SAT
          if (firstDay.day() == 0) calcBusinessDays--; //SUN
          if (this.leaveCreate.duration == 1) {
            calcBusinessDays = calcBusinessDays - 0.5;
          }

          this.totalDuration = calcBusinessDays;
          if (this.remainingLeaves < this.totalDuration) {
            (this.leaveCreate.duration = 1), (this.diableDay = true);
          } else {
            this.leaveCreate.duration = 0;
            this.diableDay = false;
          }
        }
      }
    },
    getColor(data) {
      if (data == 1) {
        return "blue";
      } else if (data == 2) {
        return "yellow";
      } else if (data == 3) {
        return "red";
      } else if (data == 4) {
        return "green";
      } else if (data == 5) {
        return "red";
      } else if (data == 6) {
        return "cyan";
      }
    },
    getBusinessDays() {
      var lastDay = moment(this.end_date);
      var firstDay = moment(this.start_date);
      let calcBusinessDays =
        1 +
        (lastDay.diff(firstDay, "days") * 5 -
          (firstDay.day() - lastDay.day()) * 2) /
          7;
      if (lastDay.day() == 6) calcBusinessDays--; //SAT
      if (firstDay.day() == 0) calcBusinessDays--; //SUN
      if (this.leaveCreate.duration == 1) {
        calcBusinessDays = calcBusinessDays - 0.5;
      }
      if (Number(calcBusinessDays) < 0) {
        calcBusinessDays = 0;
      }
      return calcBusinessDays;
    },
    getProvision(proDate) {
      let a = moment();
      let b = moment(proDate);
      this.probisionTime = b.diff(a, "days");
    },
    changeData(data) {
      if (data == true) {
        this.leaveCreate.duration = 1;
      } else {
        this.leaveCreate.duration = 0;
      }
      if (this.start_date && this.end_date) {
        this.getBusinessDays();
      }
    },
    init(param) {
      if (param && param.id) {
        this.leaveCreate = {
          id: param.id,
          staff: (param.staff && param.staff.id) || null,
          category: (param.category && param.category) || null,
          duration: param.duration || 0,
          duration_type: toSafeInteger(param.duration_type) || 1,
          reason: param.reason || null,
          day_type: toSafeInteger(param.day_type) || 1,
        };
        this.start_date = param.start_date || null;
        this.end_date = param.end_date || null;
        if (this.leaveCreate.staff > 0) {
          this.getUser(this.leaveCreate.staff);
          this.getCategoryOptions(this.leaveCreate.staff);
          /* this.categoryDays(); */
        }
      }
    },
    getMaxDate(mindate, remainig_days) {
      if (remainig_days <= 0) {
        return moment(mindate, "YYYY-MM-DD")
          .add(Math.floor(remainig_days) - 1, "days")
          .format("YYYY-MM-DD");
      }
      return null;
    },
    currentUserData() {
      const user = getCurrentUser();
      if (user && user.role != "Admin") {
        this.leaveCreate.staff = user.id;
      }
      return user;
    },
    leaveSetting() {
      this.$store
        .dispatch(GET, {
          url: `leave-setting`,
        })
        .then((data) => {
          this.userList = data.staff;
        })
        .catch((error) => {
          console.log({
            error,
          });
        });
    },

    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/options",
        })
        .then(({ data }) => {
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    categoryDays() {
      const _this = this;
      let categoryData = filter(_this.categoryList, function (row) {
        return row.category === _this.leaveCreate.category;
      });

      if (categoryData[0] && categoryData[0].balance) {
        let maxDate = toString(
          moment(this.start_date, "YYYY-MM-DD")
            .add(
              Math.ceil(
                toSafeInteger(categoryData[0].balance) +
                  toSafeInteger(this.weekEndDays)
              ) - 1,
              "days"
            )
            .format("YYYY-MM-DD")
        );

        var lastDay = moment(maxDate);
        var firstDay = moment(this.start_date);
        let calcBusinessDays =
          1 +
          (lastDay.diff(firstDay, "days") * 5 -
            (firstDay.day() - lastDay.day()) * 2) /
            7;
        if (lastDay.day() == 6) calcBusinessDays--; //SAT
        if (firstDay.day() == 0) calcBusinessDays--; //SUN

        let addWeekends = categoryData[0].balance - calcBusinessDays;

        if (addWeekends > 0) {
          this.weekEndDays = this.weekEndDays + addWeekends;
          this.categoryDays();
        } else {
          this.weekEndDays = 0;
          this.maxDate = maxDate;
          this.remainingLeaves = categoryData[0].balance;
          this.apprveLeaves = categoryData[0].taken;
          this.pendingLeaves = categoryData[0].pending;
          this.allocated = categoryData[0].allocated_days;
        }
      }
    },
    getCategoryOptions(userList = 0) {
      if (userList == 0) {
        const user = getCurrentUser();
        if (user && user.role == "Staff") {
          userList = user.id;
        } else {
          return false;
        }
      }

      if (userList > 0) {
        const _this = this;
        _this.pageLoading = true;
        _this.categoryDays();
        _this.$store
          .dispatch(GET, {
            url: "setting/leave/option/" + userList,
          })
          .then((data) => {
            _this.getUser(userList);
            _this.categoryList = data.data.categories;

            _this.categoryList.push({
              category: "UnPaid",
            });

            if (this.leaveCreate.id == null) {
              _this.categoryDays();
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }
      /* 	this.categoryDays(); */
    },
    getUser(id) {
      this.$store
        .dispatch(GET, { url: `single-user/${id}` })
        .then((data) => {
          this.userData = data.data;
          this.getProvision(this.userData.provision_date);
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    CreateOrupdateLeave() {
      const _this = this;
      const formErrors = _this.validateForm(_this.$refs["leaveForm"]);
      _this.$refs["leaveForm"].validate();
      if (formErrors.length) {
        _this.errors = [];
        this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
        return false;
      }
      if (!_this.$refs["leaveForm"].validate()) {
        return false;
      }
      _this.pageLoading = true;
      let leaveId = _this.leaveCreate.id || 0;
      let staff = _this.leaveCreate.staff || null;
      if (!staff) {
        const user = getCurrentUser();
        staff = user.id;
      }
      if (this.leaveCreate.duration == true) {
        this.leaveCreate.duration = 1;
      } else {
        this.leaveCreate.duration = 0;
      }
      let remainigBalanceData = find(this.categoryList, {
        category: this.leaveCreate.category,
      });

      if (
        remainigBalanceData &&
        remainigBalanceData.balance == 0 &&
        this.leaveCreate.category != "UnPaid"
      ) {
        this.$store.commit(SET_ERROR, [
          {
            model: true,
            message:
              "Sorry ! You don't have any leave balance for this leave type",
          },
        ]);
        return false;
      }
      this.pageLoading = true;
      let formData = new FormData();
      formData.append(
        "staff",
        this.leaveCreate.staff ? this.leaveCreate.staff : 0
      );
      formData.append(
        "category",
        this.leaveCreate.category ? this.leaveCreate.category : null
      );
      /* formData.append("remaining", this.remainingLeaves ? this.remainingLeaves:0);
				console.log(this.remainingLeaves) */
      /*	formData.append("pending", this.pendingLeaves ? this.pendingLeaves:0);
				formData.append("allocated", this.allocated ? this.allocated:0); */
      formData.append(
        "duration",
        this.leaveCreate.duration ? this.leaveCreate.duration : 0
      );
      formData.append(
        "is_visit_validated",
        this.is_visit_validated && this.is_visit_validated == 1 ? 1 : 0
      );
      formData.append(
        "duration_type",
        this.leaveCreate.duration_type ? this.leaveCreate.duration_type : 1
      );
      if (this.leaveCreate && this.leaveCreate.reason) {
        formData.append(
          "reason",
          this.leaveCreate.reason ? this.leaveCreate.reason : null
        );
      }
      if (this.start_date) {
        formData.append("start_date", this.start_date ? this.start_date : null);
      }

      if (this.leaveCreate && this.leaveCreate.day_type) {
        formData.append(
          "day_type",
          this.leaveCreate.day_type ? this.leaveCreate.day_type : 1
        );
      }
      if (this.end_date) {
        formData.append("end_date", this.end_date ? this.end_date : null);
      }
      if (this.files && this.files.length) {
        for (let i = 0; i < this.files.length; i++) {
          if (this.files && this.files[i] && this.files[i].file) {
            formData.append(`file[${i}][file]`, this.files[i].file);
            formData.append(`file[${i}][name]`, this.files[i].name);
          }
        }
      }
      let requestTYPE = POST;
      let requestURL = "leave";

      if (leaveId > 0) {
        requestURL = `leave/${leaveId}`;
      }

      this.$store
        .dispatch(requestTYPE, {
          url: requestURL,
          data: formData,
        })
        .then((data) => {
          this.visitArr = data.data;
          console.log(this.visitArr.length);
          console.log(this.visitArr);
          if (
            this.visitArr &&
            this.visitArr.length > 0 &&
            this.is_visit_validated == 1
          ) {
            this.statusDialog = true;
            return false;
          }
          if (leaveId > 0) {
            this.$store.commit(SET_MESSAGE, [
              { model: true, message: "Success ! Leave updated successfully." },
              this.$emit("close"),
              LeaveApplyEventBus.$emit("refresh", true),
              /* this.leaveCreate = {} */
            ]);
          } else {
            this.$store.commit(SET_MESSAGE, [
              { model: true, message: "Success ! Leave added successfully." },
              this.$emit("close"),
              LeaveApplyEventBus.$emit("refresh", true),
            ]);
          }
          /* 	LeaveEventBus.$emit("refresh", true); */
          this.$emit("refreshCount", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateFile(index, file) {
      if (file && file.name) {
        this.files[index].name = file.name.split(".").slice(0, -1).join(".");
        this.files[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.files[index].name = null;
        this.files[index].suffix = null;
      }
    },
    addMore() {
      this.files.push({
        file: null,
        name: null,
      });
    },

    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },
    /* getLeave() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "leave/" + _this.leave,
				})
				.then(({ data }) => {
					_this.leaveCreate = new Object({
						staff: data.staff ? data.staff.staff : null,

						duration: data.duration,
						duration_type: data.duration_type,
						reason: data.reason,
						day_type: data.day_type,
						status: data.status,
					});
					_this.start_date = data.start_date;
					_this.end_date = data.end_date;
				})
				.catch((error) => {
					_this.logError(error);
					_this.goBack();
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		}, */
  },
  created() {
    if (this.leaveCreate.staff > 0) {
      this.getCategoryOptions();
    }
  },

  mounted() {
    const _this = this;
    _this.getOptions();
    /* _this.categoryDays(); */

    _this.getProvision(this.userData.provision_date);
    _this.init(this.leaveUpdateData);
    _this.leave = toSafeInteger(_this.$route.params.id);
  },

  computed: {
    is_staff() {
      const user = this.currentUserData();
      const role_name = user.role;
      return role_name != "Admin";
    },
    disabledDate() {
      let catData = find(
        this.categoryList,
        (row) => row.category == this.leaveCreate.category
      );

      if (
        (catData && catData.balance > 0) ||
        this.leaveCreate.category == "UnPaid"
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
