<template>
  <DetailTemplate
    :custom-class="'ticket-detail detail-page'"
    v-if="getPermission('visit:view')"
  >
    <template v-slot:sidebar-detail>
      <div>
        <v-chip
          class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
          :color="getStatusColor(visit.status)"
          label
          text-color="white"
        >
          {{ visit.status_text }}
        </v-chip>
        <span v-if="detail?.quote_type">
          <v-chip
            class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
            :color="
              detail.quote_type == 'new'
                ? 'light-green darken-3'
                : 'orange darken-4 white--text'
            "
            label
            text-color="white"
          >
            {{ detail.quote_type }}
          </v-chip>
        </span>
        <span v-if="detail?.enquiry > 0">
          <v-chip
            label
            color="yellow darken-2 black--text"
            class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
            >Investigation</v-chip
          >
        </span>
        <v-chip
          v-if="visit?.is_signed == '1'"
          label
          color="red darken-4 white--text"
          text-color="white"
          class="mr-2 text-uppercase font-weight-600"
          >Signed</v-chip
        >
        <template v-if="detail?.has_tool == 1">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="green darken-4"
                class="ml-2"
                >mdi-tools</v-icon
              >
            </template>
            <span>Tools</span>
          </v-tooltip>
        </template>
        <v-tooltip bottom content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              v-if="visit.reschedule_count > 0"
              class="mr-2 my-1 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10 text-white"
              color="red lighten-1"
              label
              outlined
            >
              Revision ({{ visit.reschedule_count }})
            </v-chip>
          </template>
          <span>Revision ({{ visit.reschedule_count }})</span>
        </v-tooltip>
        <v-tooltip
          bottom
          content-class="custom-top-tooltip"
          v-if="parseInt(visit.follow_up) && visit && visit.followup"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="mr-2 my-1 d-inline-table font-weight-600"
              :color="`${getFollowColor(visit.followup.status)} white--text`"
              label
              outlined
            >
              <template
                v-if="visit && visit.followup && visit.followup.status == 2"
                >Follow Up Closed</template
              >
              <template v-else> Follow Up Open</template>
            </v-chip>
          </template>
          <span>
            <template
              v-if="visit && visit.followup && visit.followup.status == 2"
              >Follow Up Closed</template
            >
            <template v-else> Follow Up Open</template></span
          >
        </v-tooltip>
        <v-icon
          v-if="
            visit &&
            visit.closer &&
            visit.closer.need_cost == 1 &&
            visit.service_form_count > 0
          "
          :color="'red'"
          style="height: 25px; width: 25px; padding: 0"
          class="mr-2 my-1"
        >
          mdi-flag</v-icon
        >
      </div>
      <div class="mt-5">
        <table width="100%" v-if="detail.quote_type == 'new'">
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              <v-chip
                :color="visit.sdo_sent > 0 ? 'green' : 'red'"
                text-color="white"
                label
                small
                class="p-2"
              >
                <template v-if="visit.sdo_sent > 0"> Sent SDO </template>
                <template v-else> Pending SDO </template>
              </v-chip>
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td
              valign="top"
              style="width: 95px"
              class="py-1 font-size-18 font-weight-600"
            >
              Visit #
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0" v-if="visit && visit.barcode">
                <Barcode :barcode="visit.barcode"></Barcode>

                <!-- <v-chip
                  v-if="detail && detail.type == 2"
                  class="font-weight-600 custom-blue-border text-uppercase justify-center my-0 font-10 ml-2 my-1 custom-barcode"
                  color="chip-custom-blue"
                  label
                  outlined
                >
                  <span style="color: red" v-if="visit">{{
                    visit.recurring_count
                  }}</span>
                  /
                  <span style="color: green" v-if="ticket">{{
                    ticket.visit_count
                  }}</span>
                </v-chip> -->

                <!-- <template v-if="visit.trip_count > 0"
                  >{{ visit.barcode }}-{{ visit.trip_count }}</template
                >
                <template v-else>{{ visit.barcode }}-01</template> -->
              </label>
            </td>
          </tr>
          <tr v-if="detail?.type == 2">
            <td
              valign="top"
              style="width: 95px"
              class="py-1 font-size-18 font-weight-600"
            >
              Recurring #
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0" v-if="detail && detail.barcode">
                <Barcode :barcode="detail.barcode"></Barcode>
                <!-- <template v-if="visit.trip_count > 0"
                  >{{ visit.barcode }}-{{ visit.trip_count }}</template
                >
                <template v-else>{{ visit.barcode }}-01</template> -->
              </label>
            </td>
          </tr>
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Customer
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <router-link
                :to="
                  getDefaultRoute('customer.detail', {
                    params: {
                      id: detail?.customer?.id,
                    },
                  })
                "
              >
                <span
                  class="custom-read-more font-weight-600 font-size-18 pb-1"
                >
                  {{ detail?.customer?.display_name }}
                </span>
              </router-link>
            </td>
          </tr>
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Reference #
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0" v-if="detail && detail.reference">{{
                detail.reference
              }}</label>
              <em v-else class="text-muted"> no refernce </em>
            </td>
          </tr>
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Appointment
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <span
                style="color: #24326d !important"
                v-html="getAppointmentText"
              >
              </span>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Priority
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <Priority
                v-if="detail && detail.priority"
                class="my-1 ml-n2"
                :priority="detail.priority"
                small
              ></Priority>
            </td>
          </tr>
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">Type</td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 my-1 font-weight-600"
                    color="orange darken-4"
                    label
                    small
                    outlined
                  >
                    {{ getScheduleType }}
                  </v-chip>
                </template>
                <span>Schedule Type</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-if="visit.actual_start_date">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Actual Job Start Date
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">
                <v-chip small outlined color="red" label>
                  {{ formatDate(visit.actual_start_date) }}
                  {{ formatDateStartTime(visit.actual_start_date) }}
                </v-chip>
              </label>
            </td>
          </tr>
          <tr v-if="visit.actual_end_date">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Actual Job End Date
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">
                <v-chip small outlined color="green" label>
                  {{ formatDate(visit.actual_end_date) }}
                  {{ formatDateStartTime(visit.actual_end_date) }}
                </v-chip>
              </label>
            </td>
          </tr>
          <tr v-if="visit.schedule_message">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Schedule
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">{{ visit.schedule_message }}</label>
            </td>
          </tr>

          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">Tag</td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <template v-if="detail.tags && detail?.tags?.length">
                <v-chip
                  v-for="(row, index) in detail.tags"
                  :key="index"
                  class="mr-1 mb-1"
                  text-color="white"
                  small
                  label
                  :color="row.color"
                >
                  <span class="">
                    {{ row.text }}
                  </span>
                </v-chip>
              </template>
              <em v-else class="text-muted"> no tags</em>
            </td>
          </tr>
          <tr v-if="visit && visit.followup && visit.followup.follow_up_date">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Follow Up Date
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0" v-if="visit.followup.follow_up_date">
                <v-chip small outlined color="blue" label>
                  {{ formatDate(visit.followup.follow_up_date) }}
                </v-chip></label
              >
              <em v-else class="text-muted"> no follow up date</em>
            </td>
          </tr>
          <tr v-if="detail.quote_type == 'new'">
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              SDO PDF
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <v-icon
                color="red"
                :disabled="visit?.sdo_sent == 0 ? true : false"
                v-on:click.stop.prevent="downloadFile(visit?.pdf_url)"
                >mdi-file-pdf-box</v-icon
              >
            </td>
          </tr>
          <tr v-if="detail.quote_type == 'new'">
            <td valign="middle" class="py-2 font-size-18 font-weight-600">
              SDO Signed
            </td>
            <td valign="middle" class="py-2 font-size-18 font-weight-500">
              <v-icon
                color="cyan"
                :disabled="!visit?.sign_pdf_url"
                v-on:click.stop.prevent="downloadFile(visit?.sign_pdf_url)"
                >mdi-file-pdf-box</v-icon
              >
            </td>
          </tr>
        </table>
      </div>
      <div
        class="mt-5"
        v-if="
          visit && visit.contract && visit.contract > 0 && visit.contract_detail
        "
      >
        <v-flex md12>
          <table width="100%">
            <tr>
              <th
                colspan="3"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Contract Information
              </th>
            </tr>
            <tr>
              <td
                valign="top"
                width="60px"
                class="py-1 font-size-18 font-weight-500"
              >
                Title
              </td>
              <td align="center" valign="top" class="py-1" width="20px">:</td>
              <td valign="top" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ visit.contract_detail.title }}
                </label>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                Start
              </td>
              <td align="center" class="py-1">:</td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ formatDate(visit.contract_detail.contract_start) }}</label
                >
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                End
              </td>
              <td align="center" class="py-1">:</td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ formatDate(visit.contract_detail.contract_end) }}</label
                >
              </td>
            </tr>
          </table>
        </v-flex>
      </div>
      <!-- <div class="mt-5">
        <v-flex md12>
          <table width="100%">
            <tr>
              <th
                colspan="3"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Other Information
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                width="110px"
                class="py-1 font-size-18 font-weight-700"
              >
                Quotation No
              </td>
              <td align="center" width="20px">:</td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"> QT-0005 </label>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-700">
                PO Number
              </td>
              <td align="center">:</td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"> PO-00034</label>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-700">
                Payment Mode
              </td>
              <td align="center">:</td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"> Bank Transfer</label>
              </td>
            </tr>
          </table>
        </v-flex>
      </div> -->
      <div class="mt-5">
        <v-flex md12>
          <table width="100%">
            <tr>
              <th class="grey lighten-4 font-size-19 color-custom-blue py-1">
                Site Location
              </th>
            </tr>
            <tr
              v-if="
                detail && detail.property && detail.property.property_address
              "
            >
              <td class="py-0 font-size-18 font-weight-500 py-2">
                <a
                  target="_blank"
                  :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                  >{{ detail.property.property_address }}</a
                >
              </td>
            </tr>
            <tr>
              <th class="">Contact details</th>
            </tr>
            <tr v-if="detail.property_person">
              <td class="py-0 font-size-18 font-weight-500">
                <template v-if="detail.property_person.display_name">
                  {{ detail.property_person.display_name }}
                </template>
              </td>
            </tr>
            <tr v-if="detail.property_person">
              <td class="py-0 font-size-18 font-weight-500">
                <template v-if="detail.property_person.primary_phone">
                  {{ detail.property_person.primary_phone }}
                </template>
              </td>
            </tr>
            <tr v-if="detail.property_person">
              <td class="py-0 font-size-18 font-weight-500">
                <template v-if="detail.property_person.primary_email">
                  {{ detail.property_person.primary_email }}
                </template>
                <!--   <v-icon v-if="detail.property_person_notify" v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                }" content="Notification was sent to given email" color="green lighten-1">mdi-check</v-icon> -->
              </td>
            </tr>
          </table>
        </v-flex>
      </div>
      <!--
      <div class="mt-5">
        <v-flex md12>
          <table width="100%">
            <tr>
              <th class="grey lighten-4 font-size-19 color-custom-blue py-1">
                Billing Address
              </th>
            </tr>
            <tr
              v-if="detail && detail.billing && detail.billing.property_address"
            >
              <td class="py-0 font-size-18 font-weight-500 pt-2">
                <a
                  target="_blank"
                  :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                  >{{ detail.billing.property_address }}</a
                >
              </td>
            </tr>
            <tr>
              <th class="">Contact details</th>
            </tr>
            <tr v-if="detail.billing_person">
              <td class="py-0 font-size-18 font-weight-500">
                <template
                  v-if="
                    detail &&
                    detail.billing_person &&
                    detail.billing_person.display_name
                  "
                >
                  {{ detail.billing_person.display_name }}
                </template>
              </td>
            </tr>
            <tr v-if="detail.billing_person">
              <td class="py-0 font-size-18 font-weight-500">
                <template
                  v-if="
                    detail &&
                    detail.billing_person &&
                    detail.billing_person.primary_phone
                  "
                >
                  {{ detail.billing_person.primary_phone }}
                </template>
              </td>
            </tr>
            <tr v-if="detail.billing_person">
              <td class="py-0 font-size-18 font-weight-500">
                <template
                  v-if="
                    detail &&
                    detail.billing_person &&
                    detail.billing_person.primary_email
                  "
                >
                  {{ detail.billing_person.primary_email }}
                </template>
                <v-icon
                  v-if="detail.billing_person_notify"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                  }"
                  content="Notification was sent to given email"
                  color="green lighten-1"
                  >mdi-check</v-icon
                >
              </td>
            </tr>
          </table>
        </v-flex>
      </div> -->
    </template>
    <template v-slot:header-title>
      <!-- <v-chip color="blue darken-4" outlined label> -->
      <span
        class="color-custom-blue font-weight-700 font-size-26"
        v-if="ticket && ticket.title"
      >
        Trip:
        <template v-if="visit.trip_count > 0"
          >{{ visit.barcode }}-{{ visit.trip_count }}</template
        >
        <template v-else>{{ visit.barcode }}-01</template> / {{ ticket.title }}
      </span>
      <!-- </v-chip> -->
    </template>

    <template v-slot:header-action>
      <div class="d-flex align-center justify-end flex-wrap" v-if="pageLoaded">
        <v-btn
          v-if="visit.resume_btn"
          class="mx-2 custom-bold-button white--text mb-1"
          color="cyan"
          :disabled="timerLoading || visitLoading"
          :loading="timerLoading"
          v-on:click="resumeTimerDialog = true"
        >
          Resume Timer
        </v-btn>
        <v-btn
          v-if="visit.start_btn"
          class="mx-2 custom-bold-button white--text mb-1"
          color="cyan"
          :disabled="timerLoading || visitLoading"
          :loading="timerLoading"
          v-on:click="startTimer"
        >
          <v-icon left>mdi-clock-outline</v-icon>
          Start Timer
        </v-btn>
        <template v-if="visit.checkout_btn">
          <v-tooltip bottom content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                :disabled="timerLoading || visitLoading"
                :loading="timerLoading"
                v-on:click="VisitChecoutUpdate()"
              >
                CheckOut
              </v-btn>
            </template>
            <span>CheckOut</span>
          </v-tooltip>
        </template>
        <template v-if="visit.leave_btn">
          <v-tooltip bottom content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                :disabled="timerLoading || visitLoading"
                :loading="timerLoading"
                v-on:click="VisitChecoutUpdate('leave')"
              >
                Leave
              </v-btn>
            </template>
            <span>Leave</span>
          </v-tooltip>
        </template>
        <template v-if="visit.checkin_btn">
          <v-tooltip bottom content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                :disabled="timerLoading || visitLoading"
                :loading="timerLoading"
                v-on:click="VisitCheckinUpdate"
              >
                CheckIn
              </v-btn>
            </template>
            <span>CheckIn</span>
          </v-tooltip>
        </template>
        <template v-if="visit.hold_btn">
          <v-tooltip bottom content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                :disabled="timerLoading || visitLoading"
                :loading="timerLoading"
                v-on:click="HoldandStayandCheckoutDialog = true"
              >
                Hold
              </v-btn>
            </template>
            <span>Hold</span>
          </v-tooltip>
        </template>
        <template v-if="visit.hold_btn">
          <v-tooltip bottom content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                :disabled="timerLoading || visitLoading"
                :loading="timerLoading"
                v-on:click="closerDialog = true"
              >
                STOP
              </v-btn>
            </template>
            <span>Click here to create closer</span>
          </v-tooltip>
        </template>
        <template
          v-if="
            !is_staff &&
            visit.status != 4 &&
            visit.status != 5 &&
            assigned_team?.length > 0
          "
        >
          <template v-if="!runningTimer.id">
            <!-- <v-btn
              v-if="visit.status == 3 || visit.status == 6"
              class="mx-2 custom-bold-button white--text mb-1"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
              v-on:click="resumeTimerDialog = true"
            >
              
              Resume Timer
            
            </v-btn>
            <v-btn
              v-else
              class="mx-2 custom-bold-button white--text mb-1"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
              v-on:click="startTimer"
            >
              <v-icon left>mdi-clock-outline</v-icon>
              Start Timer
            </v-btn> -->
          </template>
          <template v-else>
            <v-tooltip
              bottom
              content-class="custom-top-tooltip"
              v-if="checkout.checkin_time"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 mb-1 custom-bold-button white--text"
                  color="cyan"
                  :disabled="timerLoading || visitLoading"
                  :loading="timerLoading"
                  v-on:click="assignServiceFormDetail"
                >
                  Service Form
                </v-btn>
              </template>
              <span>Click here to assign service form</span>
            </v-tooltip>
            <!--  <template v-if="!is_last_man">
              <template v-if="checkout && checkout.id != null">
                <v-tooltip bottom content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mx-2 mb-1 custom-bold-button white--text"
                      color="cyan"
                      :disabled="timerLoading || visitLoading"
                      :loading="timerLoading"
                      v-on:click="VisitChecoutUpdate"
                    >
                      CheckOut
                    </v-btn>
                  </template>
                  <span>CheckOut</span>
                </v-tooltip>
              </template>
            </template> -->
            <!-- <template v-if="checkout && checkout.id != null">
              <v-tooltip bottom content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 mb-1 custom-bold-button white--text"
                    color="cyan"
                    :disabled="timerLoading || visitLoading"
                    :loading="timerLoading"
                    v-on:click="HoldandStayandCheckoutDialog = true"
                  >
                    Hold
                  </v-btn>
                </template>
                <span>Hold</span>
              </v-tooltip>
            </template> -->
            <template v-else>
              <!-- <v-tooltip bottom content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 mb-1 custom-bold-button white--text"
                    color="cyan"
                    :disabled="timerLoading || visitLoading"
                    :loading="timerLoading"
                    v-on:click="VisitCheckinUpdate"
                  >
                    CheckIn
                  </v-btn>
                </template>
                <span>CheckIn</span>
              </v-tooltip> -->
            </template>
            <template v-if="!runningTimer.closer && checkout.checkin_time">
              <!-- <v-tooltip bottom content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 mb-1 custom-bold-button white--text"
                    color="cyan"
                    :disabled="timerLoading || visitLoading"
                    :loading="timerLoading"
                    v-on:click="closerDialog = true"
                  >
                    STOP
                  </v-btn>
                </template>
                <span>Click here to create closer</span>
              </v-tooltip> -->
            </template>
            <v-btn
              class="mx-2 mb-1 custom-bold-button white--text"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
            >
              {{ timerDuration.hour }}<span class="blink-me">:</span
              >{{ timerDuration.minute }}<span class="blink-me">:</span
              >{{ timerDuration.second }}
            </v-btn>
          </template>
        </template>

        <!--  <template v-if="visit && visit.followup">
          <v-chip
            class="mx-2 d-inline-table font-weight-600"
            :color="`${getFollowColor(visit.followup.status)} white--text`"
            label
            outlined
            v-on:click="closeFollowUp(visit.followup)"
          >
            Follow Up
            <template
              v-if="visit && visit.followup && visit.followup.status == 2"
              >Closed</template
            >
          </v-chip>
        </template> -->
        <!-- <template v-if="visit.status == 4">
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="share_dialog = true"
          >
            <v-icon left>mdi-share</v-icon>
            Share Service Report
          </v-btn>
        </template> -->

        <template v-if="visit && visit.followup">
          <v-menu
            offset-y
            min-width="100px"
            style="padding: 0px 4px"
            rounded
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-flex justify-space-between align-center"
                style="
                  padding: 0px 6px;
                  background-color: #f7faff;
                  width: 150px;
                  border-radius: 9px;
                "
                :style="`border:1px solid ${getFollowColor(
                  visit.followup.status
                )}`"
              >
                <span
                  :class="`text-capitalize font-level-1 ${getFollowText(
                    visit.followup.status
                  )}`"
                >
                  <template v-if="visit.followup.status == 1">
                    Follow Up Open</template
                  >
                  <template v-else> Follow Up Closed</template>
                </span>
                <v-icon
                  style="font-size: 22px"
                  :color="getFollowColor(visit.followup.status)"
                  small
                  >mdi-menu-down</v-icon
                >
              </div>
            </template>

            <v-list elevation>
              <v-list-item
                v-on:change="closeCompleteFollowUp(item.value, visit.followup)"
                class="list-item cursor-pointer p-1"
                v-for="(item, index) in followupUpdateList"
                :key="index"
              >
                <v-list-item-title class="pl-1">
                  <v-chip
                    style="height: 15px; width: 15px; padding: 0"
                    :color="item.color"
                  >
                  </v-chip>
                  {{ item.text }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-if="visit.status == 1">
          <v-btn
            depressed
            class="mx-2 mb-1 custom-bold-button white--text"
            color="cyan"
            :to="
              getDefaultRoute('job.create', {
                query: {
                  ticket: ticketId,
                  visit: visitId,
                  quotation: quotationId,
                  invoice: invoiceId,
                  enquiry: enquiryId,
                },
              })
            "
          >
            <v-icon>mdi-pencil </v-icon>
          </v-btn>
        </template>
        <!--  <v-btn
          v-on:click="downloadPDF(visit.pdf)"
          :key="index"
          :disabled="!visit.pdf"
          depressed
          color="cyan"
          class="custom-bold-button mx-1 text-white"
          ><v-icon small left>mdi-file-pdf-box</v-icon> Download Pdf</v-btn
        > -->
        <v-menu
          v-if="visit.status != 5 && !visit.is_signed"
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :disabled="statusLoading"
              class="mx-2 mb-1 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                v-if="more.action"
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="
                  detail.quote_type != 'new' && more.action == 'send_sdo'
                    ? true
                    : false
                "
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>

            <!-- <template v-if="closerdata.accept_term == 0 && is_staff">
              <v-list-item link v-on:click="getSignatureLink()" :key="2">
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">mdi-signature-freehand</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">
                  Signature Link
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="closerdata.accept_term == 0 && is_staff">
              <v-list-item link v-on:click="mail_dialog = true" :key="3">
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">mdi-email-send</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">
                  Send as Email
                </v-list-item-title>
              </v-list-item>
            </template>  -->
          </v-list>
        </v-menu>
        <template v-if="engineerList?.length">
          <v-menu
            v-model="engineer_menu"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0 d-flex"
              >
                <div>Select Team</div>
                <v-spacer></v-spacer>
                <div class="mr-2">Visit TL</div>
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in engineerList"
                  :key="index"
                >
                  <v-checkbox
                    v-model="assigned_team_new"
                    :label="team.display_name"
                    :value="team"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    :disabled="apptEngLoading"
                    multiple
                  >
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(team.profile_logo)"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                v-bind="attrs"
                                v-on="on"
                                class="font-size-16 font-weight-500"
                                v-html="team.display_name"
                              ></v-list-item-title>
                            </template>
                            <!--  <span class="text-capitalize">
                              <template v-if="team.skills">{{
                                team.skills.join(", ")
                              }}</template>
                              <template v-else-if="team.designation">{{
                                team.designation.value
                              }}</template>
                              <template v-else><em>No Skills</em></template>
                            </span> -->
                          </v-tooltip>
                          <v-list-item-subtitle v-if="team?.is_tl > 0">
                            <!--  <v-chip
                              label
                              small
                              color="cyan"
                              outlined
                              text-color=""
                            >
                              {{ team?.designation?.text }}
                            </v-chip> -->
                            <v-chip
                              v-if="team.is_tl == 1"
                              label
                              small
                              color="red"
                              outlined
                              text-color=""
                            >
                              Team Lead
                            </v-chip>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>
                  <div>
                    <v-switch
                      v-model.trim="team.mark_as_tl"
                      :value="1"
                      color="red"
                      hide-details
                      class="mt-0"
                      :disabled="team.is_tl ? false : true"
                      small
                    ></v-switch>
                  </div>
                </v-list-item>
              </perfect-scrollbar>
              <v-btn
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-on:click="updateEngineer()"
                color="cyan white--text"
                class="w-100 text-uppercase font-weight-700 font-size-16"
                >Save</v-btn
              >
            </v-list>
          </v-menu>
        </template>
        <v-btn
          depressed
          class="mx-2 mb-1 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="height: calc(100vh - 114px); position: relative"
      > -->
      <v-container fluid class="">
        <v-row v-if="pageLoaded">
          <v-col md="12" class="py-0">
            <v-tabs
              active-class="custom-tab-active"
              v-model="ticketTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
              show-arrows
            >
              <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#line-item"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Line Items
              </v-tab>
              <v-tab class="font-size-16 font-weight-600 px-8" href="#team">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/engineer.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Team
              </v-tab>
              <v-tab class="font-size-16 font-weight-600 px-8" href="#trips">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Trips
              </v-tab>
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#service-form"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/service-form.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Service Form
              </v-tab>
              <v-tab
                v-if="follow_up_route == 'follow-up' && false"
                class="font-size-16 font-weight-600 px-8"
                href="#chatNotes"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
                  <!--end::Svg Icon-->
                </span>
                Follow Up Notes
              </v-tab>
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#attachment"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/attachment.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Attachments
              </v-tab>

              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#projectMilestone"
                v-if="false"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/engineer.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Project Milestones
              </v-tab>

              <v-tab class="font-size-16 font-weight-600 px-8" href="#closer">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="$assetURL('media/custom-svg/closer.svg')" />
                  <!--end::Svg Icon-->
                </span>
                Closer
              </v-tab>
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#notes"
                v-if="false && follow_up_route != 'follow-up'"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="$assetURL('media/custom-svg/note.svg')" />
                  <!--end::Svg Icon-->
                </span>
                Notes
              </v-tab>

              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#timestamp"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/engineer.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Timestamp
              </v-tab>
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#available-tickets"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <inline-svg
                    :src="$assetURL('media/custom-svg/engineer.svg')"
                  />
                </span>
                Other Tickets
              </v-tab>
              <v-tab
                v-if="visit.status == 4 && visit.follow_up == 1 && false"
                class="font-size-16 font-weight-600 px-8"
                href="#followup"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <inline-svg
                    :src="$assetURL('media/custom-svg/engineer.svg')"
                  />
                </span>
                Follow Up
              </v-tab>
              <v-tab class="font-size-16 font-weight-600 px-8" href="#history">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/history.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                History
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="ticketTab">
              <v-tab-item value="line-item">
                <VisitLineItem
                  is-job
                  v-on:line-item="lengthCount"
                  :show-price="detail.show_price"
                  :discount-level="
                    detail.discount_type == 2 ? 'line_item' : 'transaction'
                  "
                  :related-id="ticketId"
                  :related-type="3"
                />
                <div v-if="false" class="mt-4 pr-2 font-weight-600">
                  <table width="100%">
                    <tr>
                      <td width="66%"></td>
                      <td width="18%" align="right">Sub Total</td>
                      <td width="18%" align="right">
                        {{ formatMoney(detail.sub_total) }}
                      </td>
                    </tr>
                    <tr v-if="detail.discount_type == 1">
                      <td></td>
                      <td align="right">
                        Discount
                        <span v-if="detail.discount_value_type == 1"
                          >({{ formatMoney(detail.discount_value) }})</span
                        >
                        <span v-if="detail.discount_value_type == 2"
                          >({{ formatNumber(detail.discount_value) }}%)</span
                        >
                      </td>
                      <td align="right">
                        {{ formatMoney(detail.discount_amount) }}
                      </td>
                    </tr>
                    <tr v-if="detail.tax_applied">
                      <td></td>
                      <td align="right">Tax [GST {{ detail.tax_value }}%]</td>
                      <td align="right">
                        {{ formatMoney(detail.tax_amount) }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td align="right">Adjustment</td>
                      <td align="right">
                        {{ formatMoney(detail.adjustment) }}
                      </td>
                    </tr>
                    <tr class="font-size-19">
                      <td></td>
                      <td align="right">Grand Total</td>
                      <td align="right">{{ formatMoney(detail.total) }}</td>
                    </tr>
                  </table>
                </div>
              </v-tab-item>
              <v-tab-item value="trips">
                <VisitTrips :visit-id="visitId"> </VisitTrips>
              </v-tab-item>
              <v-tab-item value="chatNotes"> </v-tab-item>
              <v-tab-item value="other">
                <v-container fluid class="px-0">
                  <v-card
                    v-if="visit && visit.followup && visit.followup.remark"
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >Follow Up Remark
                      </span>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          visit.followup.remark || '<em>No Description</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>
                  <v-row>
                    <v-col md="6">
                      <v-card
                        flat
                        class="custom-grey-border remove-border-radius mb-4 h-100"
                      >
                        <v-card-title class="headline grey lighten-4">
                          <span
                            class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >
                            Information</span
                          >
                        </v-card-title>
                        <v-card-text class="p-6 font-size-16">
                          <v-flex md12>
                            <table width="100%">
                              <tr>
                                <td
                                  valign="middle"
                                  width="170px"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  Is Service Form
                                </td>
                                <td align="center" width="20px">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <label class="m-0"
                                    ><v-checkbox
                                      color="cyan"
                                      disabled
                                      v-model="visit.is_service_form"
                                    ></v-checkbox
                                  ></label>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  valign="middle"
                                  width="120px"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  Line Item Visiable In PDF
                                </td>
                                <td align="center" width="20px">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <label class="m-0"
                                    ><v-checkbox
                                      color="cyan"
                                      disabled
                                      v-model="visit.is_item_pdf"
                                    ></v-checkbox
                                  ></label>
                                </td>
                              </tr>
                              <tr v-if="visit?.quotation_relation?.barcode">
                                <td
                                  valign="middle"
                                  width="110px"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  Quotation No
                                </td>
                                <td align="center" width="20px">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <label class="m-0">
                                    <Barcode
                                      dense
                                      small
                                      :barcode="
                                        visit.quotation_relation.barcode
                                      "
                                      :id="visit.quotation_relation.id"
                                      :route="'quotation.detail'"
                                    ></Barcode>
                                  </label>
                                </td>
                              </tr>
                              <tr v-if="visit?.invoice_relation?.barcode">
                                <td
                                  valign="middle"
                                  width="110px"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  Invoice No
                                </td>
                                <td align="center" width="20px">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <label class="m-0">
                                    <Barcode
                                      dense
                                      small
                                      :barcode="visit.invoice_relation.barcode"
                                      :id="visit.invoice_relation.id"
                                      :route="'invoice.detail'"
                                    ></Barcode>
                                  </label>
                                </td>
                              </tr>
                              <tr v-if="visit?.enquiry_relation?.barcode">
                                <td
                                  valign="middle"
                                  width="110px"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  Enquiry No
                                </td>
                                <td align="center" width="20px">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <label class="m-0">
                                    <Barcode
                                      dense
                                      small
                                      :barcode="visit.enquiry_relation.barcode"
                                      :id="visit.enquiry_relation.id"
                                      :route="'enquiry.detail'"
                                    ></Barcode>
                                  </label>
                                </td>
                              </tr>
                              <tr v-if="visit?.contract_relation?.barcode">
                                <td
                                  valign="middle"
                                  width="110px"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  Contract No
                                </td>
                                <td align="center" width="20px">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <label class="m-0">
                                    <Barcode
                                      dense
                                      small
                                      :barcode="visit.contract_relation.barcode"
                                      :id="visit.contract_relation.id"
                                      :route="'contract.detail'"
                                    ></Barcode>
                                  </label>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  PO Number
                                </td>
                                <td align="center">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <label class="m-0">
                                    <template v-if="detail.po_number">
                                      {{ detail.po_number }}</template
                                    >
                                    <em v-else class="text-muted">
                                      no po number</em
                                    >
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  Payment Mode
                                </td>
                                <td align="center">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <template
                                    v-if="
                                      detail.payment_mode &&
                                      detail.payment_mode.text
                                    "
                                  >
                                    {{ detail.payment_mode.text }}</template
                                  >
                                  <em v-else class="text-muted">
                                    no Payment Mode</em
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  valign="top"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  Job Type
                                </td>
                                <td align="center">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <v-tooltip
                                    v-for="(type, index) in detail.ticket_type"
                                    :key="'ticket-type' + index"
                                    top
                                    content-class="custom-top-tooltip"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-chip
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mr-2 font-weight-600"
                                        color="chip-custom-blue"
                                        label
                                        small
                                        outlined
                                      >
                                        {{ type }}
                                      </v-chip>
                                    </template>
                                    <span>Job Type</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <tr v-if="false">
                                <td
                                  valign="top"
                                  class="py-1 font-size-18 font-weight-700"
                                >
                                  License
                                </td>
                                <td align="center">:</td>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  <template v-if="detail.license">
                                    <v-tooltip
                                      v-for="(type, index) in detail.license"
                                      :key="'ticket-type' + index"
                                      top
                                      content-class="custom-top-tooltip"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-chip
                                          v-bind="attrs"
                                          v-on="on"
                                          class="mr-2 font-weight-600"
                                          color="chip-custom-blue"
                                          label
                                          small
                                          outlined
                                        >
                                          {{ type }}
                                        </v-chip>
                                      </template>
                                      <span>Job Type</span>
                                    </v-tooltip>
                                  </template>
                                  <template v-else>
                                    <em class="text-muted">no license</em>
                                  </template>
                                </td>
                              </tr>
                            </table>
                          </v-flex>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col md="6">
                      <v-card
                        flat
                        class="custom-grey-border remove-border-radius mb-4 h-100"
                      >
                        <v-card-title class="headline grey lighten-4">
                          <span
                            class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >
                            Billing Address</span
                          >
                        </v-card-title>
                        <v-card-text class="p-6 font-size-16">
                          <v-flex md12>
                            <table width="100%">
                              <tr
                                v-if="
                                  detail &&
                                  detail.billing &&
                                  detail.billing.property_address
                                "
                              >
                                <td
                                  class="py-0 font-size-18 font-weight-500 pt-2"
                                >
                                  <a
                                    target="_blank"
                                    :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                                    >{{ detail.billing.property_address }}</a
                                  >
                                </td>
                              </tr>
                              <tr>
                                <th class="">Contact details</th>
                              </tr>
                              <tr v-if="detail.billing_person">
                                <td class="py-0 font-size-18 font-weight-500">
                                  <template
                                    v-if="
                                      detail &&
                                      detail.billing_person &&
                                      detail.billing_person.display_name
                                    "
                                  >
                                    {{ detail.billing_person.display_name }}
                                  </template>
                                </td>
                              </tr>
                              <tr v-if="detail.billing_person">
                                <td class="py-0 font-size-18 font-weight-500">
                                  <template
                                    v-if="
                                      detail &&
                                      detail.billing_person &&
                                      detail.billing_person.primary_phone
                                    "
                                  >
                                    {{ detail.billing_person.primary_phone }}
                                  </template>
                                </td>
                              </tr>
                              <tr v-if="detail.billing_person">
                                <td class="py-0 font-size-18 font-weight-500">
                                  <template
                                    v-if="
                                      detail &&
                                      detail.billing_person &&
                                      detail.billing_person.primary_email
                                    "
                                  >
                                    {{ detail.billing_person.primary_email }}
                                  </template>
                                  <!--  <v-icon
                                    v-if="
                                      detail.billing_person_notify &&
                                      detail.billing_person.primary_email
                                    "
                                    v-tippy="{
                                      arrow: true,
                                      arrowType: 'round',
                                      animation: 'fade',
                                    }"
                                    content="Notification was sent to given email"
                                    color="green lighten-1"
                                    >mdi-check</v-icon
                                  > -->
                                </td>
                              </tr>
                            </table>
                          </v-flex>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                    v-if="visit.cancel_reason"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >Cancel Reason</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          visit.cancel_reason || '<em>No  Cancel Reason</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >Description</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="detail.description || '<em>No Description</em>'"
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >TERMS &amp; CONDITIONS</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          detail.term_conditions ||
                          '<em>No Terms &amp; Conditions</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >Notes</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-layout class="mb-4">
                        <v-flex md6 class="custom-border-right">
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Client Note
                            </span>
                          </v-card-title>
                          <read-more
                            class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              detail.client_remark || '<em>no Client Note</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                        <v-flex md6>
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Internal Note</span
                            >
                            <v-spacer></v-spacer>
                            <span
                              class="d-flex"
                              style="
                                margin-top: -3px !important;
                                font-size: 14px !important;
                              "
                            >
                              <template v-if="visit.is_remark_show">
                                <v-icon color="green" size="18"
                                  >mdi-check-circle</v-icon
                                >
                                <span class="ml-1 green--text"
                                  >Show In Mobile App</span
                                >
                              </template>
                            </span>
                          </v-card-title>

                          <read-more
                            class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              detail.admin_remark ||
                              '<em>No Internal Note </em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        v-if="detail.documents.length && false"
                        class="custom-border-top"
                      >
                        <v-flex md12>
                          <FileTemplate
                            :attachments="detail.documents"
                          ></FileTemplate>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
              <v-tab-item value="attachment">
                <AdditionalDocuments
                  :type-id="detail.id"
                  class="mx-4"
                  type-text="visit"
                  :type="302"
                  :extensive="false"
                ></AdditionalDocuments>
                <Files
                  :documents="detail.documents"
                  v-if="false"
                  type="visit"
                ></Files>
              </v-tab-item>

              <v-tab-item value="line-item-01">
                <TicketLineItem
                  v-if="false"
                  is-job
                  show-detail
                  :db-line-items="dbLineItems"
                  :db-equipments="dbEquipments"
                  :discount-value="detail.discount_value"
                  :discount-value-type="detail.discount_value_type"
                  :apply-tax="detail.tax_applied"
                  :adjustment-value="detail.adjustment"
                  :tax-value="detail.tax_value"
                  v-on:load:content="getVisit()"
                >
                </TicketLineItem>
              </v-tab-item>
              <v-tab-item value="timestamp">
                <VisitTimestamp
                  :detail="{ id: ticketId }"
                  :visit-id="visitId"
                  v-on:delete:engineer="getVisit()"
                >
                </VisitTimestamp>
              </v-tab-item>

              <v-tab-item value="followup">
                <VisitfollowupDetail
                  :detail="detail"
                  :visit="visit"
                  :page-loading="visitLoading"
                ></VisitfollowupDetail>
              </v-tab-item>
              <v-tab-item value="available-tickets">
                <VisitListingTemplate
                  :customer-id="detail.customer.id"
                  :property-id="detail.property.id"
                  detailType="tickets"
                  visitType="all"
                  v-on:sccuess="getVisit()"
                  internal
                  :visit-status="0"
                  detailRoute="visit.detail"
                ></VisitListingTemplate>
              </v-tab-item>
              <v-tab-item value="service-form">
                <ServiceFormDetail
                  :detail="detail"
                  :visit="visit"
                  :accept_term="detail.accept_term"
                  :customer="detail.customer"
                  :property="detail.property"
                  :billing="detail.billing"
                  :contact-person="detail.property_person"
                  :page-loading="visitLoading"
                  is-visit-detail
                >
                </ServiceFormDetail>
              </v-tab-item>
              <v-tab-item value="closer">
                <TicketCloserDetail
                  is-visit-detail
                  :visit-detail="visit"
                  :detail="detail"
                  :customer="detail.customer"
                  :property="detail.property"
                  :billing="detail.billing"
                  :contact-person="detail.property_person"
                  :page-loading="visitLoading"
                  v-on:load:visit="getVisit()"
                ></TicketCloserDetail>
              </v-tab-item>
              <v-tab-item value="notes">
                <VisitNotesDetail
                  v-if="false"
                  :detail="detail"
                  :visit="visit"
                  :notes="visit.notes"
                  :page-loading="visitLoading"
                >
                </VisitNotesDetail>
              </v-tab-item>
              <!--  <v-tab-item value="invoice">
                  <InternalInvoice
                    type="visit"
                    :type-id="visitId"
                  ></InternalInvoice>
                </v-tab-item>
                <v-tab-item value="quotation">
                  <InternalQuotation
                    type="visit"
                    :type-id="visitId"
                  ></InternalQuotation>
                </v-tab-item> -->
              <v-tab-item
                v-if="getPermission('history:view')"
                :value="'history'"
              >
                <InternalHistoryDetail
                  type="visit"
                  :type_id="visit.id"
                ></InternalHistoryDetail>
              </v-tab-item>
              <v-tab-item value="team">
                <VisitTeamDetail
                  :detail="{ id: ticketId }"
                  :visit-id="visitId"
                  v-on:delete:engineer="getVisit()"
                >
                </VisitTeamDetail>
              </v-tab-item>
              <v-tab-item value="projectMilestone" v-if="false">
                <table
                  class="line-item-multiple-table"
                  width="100%"
                  fixed-header
                >
                  <thead>
                    <tr class="custom-border">
                      <th class="text-left border-right-new">#</th>
                      <th class="text-left border-right-new">Name</th>
                      <th class="text-left border-right-new">Description</th>
                      <th class="text-left border-right-new">Dates</th>
                      <th class="text-left border-right-new">Milestones</th>
                      <th class="text-left border-right-new">Status</th>
                      <!--  <th class="text-left">&nbsp;&nbsp;&nbsp;Balance</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        visit && visit.milestone && visit.milestone?.length > 0
                      "
                    >
                      <tr
                        :key="index"
                        v-for="(data, index) in visit.milestone"
                        class="line-item-listing-row border-bottom-new"
                      >
                        <td class="text-left border-right-new" width="10%">
                          <Barcode
                            :barcode="
                              data &&
                              data.milestoneData &&
                              data.milestoneData.barcode
                            "
                          ></Barcode>
                        </td>
                        <td class="text-left border-right-new" width="20%">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <template
                              v-if="
                                data &&
                                data.milestoneData &&
                                data.milestoneData.name
                              "
                            >
                              {{ data.milestoneData.name }}
                            </template>
                          </p>
                        </td>
                        <td
                          class="text-left border-right-new"
                          width="30%"
                          style="max-width: 500px"
                        >
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            style="white-space: pre-wrap !important"
                          >
                            <template
                              v-if="
                                data &&
                                data.milestoneData &&
                                data.milestoneData.description
                              "
                            >
                              {{ data.milestoneData.description }}
                            </template>
                            <template v-else
                              ><em class="text--secondary"
                                >No description</em
                              ></template
                            >
                          </p>
                        </td>
                        <td class="text-left border-right-new" width="10%">
                          <div class="project-listing-dates">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Date: </b>
                              <template
                                v-if="
                                  data &&
                                  data.milestoneData &&
                                  data.milestoneData.date
                                "
                              >
                                {{ formatDate(data.milestoneData.date) }}
                              </template>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reminder: </b>
                              <template
                                v-if="
                                  data &&
                                  data.milestoneData &&
                                  data.milestoneData.reminder_date
                                "
                                >{{
                                  formatDate(data.milestoneData.reminder_date)
                                }}</template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >Not Reminder</em
                                ></template
                              >
                            </p>
                          </div>
                        </td>
                        <td class="text-left border-right-new" width="20%">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Milestone Amount: </b>
                            <template
                              v-if="
                                data &&
                                data.milestoneData &&
                                data.milestoneData.amount
                              "
                            >
                              {{ formatMoney(data.milestoneData.amount) }}
                            </template>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Milestone Percentage: </b>
                            <template
                              v-if="
                                data &&
                                data.milestoneData &&
                                data.milestoneData.progress
                              "
                            >
                              {{ data.milestoneData.progress }} %
                            </template>
                          </p>
                        </td>

                        <td class="text-left border-right-new" width="20%">
                          <v-chip
                            :color="
                              data.milestoneData.status == 1 ? 'green' : 'red'
                            "
                            outlined
                            text-color=""
                            label
                          >
                            <template v-if="data.milestoneData.status == 1">
                              Complete
                            </template>
                            <template v-else> Pending </template>
                          </v-chip>
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="5">
                        <p
                          class="m-0 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no milestone at the moment.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <!-- </perfect-scrollbar> -->
      <Dialog :common-dialog="startTimerDialog" v-if="false">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    Are you sure, you want to start timer for visit#
                    {{ visit.barcode }} ?
                  </p>
                </v-col>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="timerFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimerWithLater"
          >
            Assign Later
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimer"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="startTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandStayandCheckoutDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue"></p>
                </v-col>
                <TextAreaField
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  v-model="data.remark"
                  placeholder="Remark"
                  solo
                  flat
                  row-height="25"
                  counter="1024"
                />

                <!--  <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="timerFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col> -->
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="HoldandStayDialog = true"
          >
            HOLD & STAY
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="HoldandCheckoutDialog = true"
          >
            HOLD & CHECKOUT
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandStayandCheckoutDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandStayDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    {{ visit.barcode }} will be on hold and all engineers stay
                    assigned to the job
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateOrCreateHold('stop_timer', 'hold')"
          >
            OK
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandStayDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandCheckoutDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    {{ visit.barcode }} will be on hold and all engineer will be
                    unassigned
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="
              updateOrCreateHoldandCheckout('stop_timer', 'checkouthold')
            "
          >
            OK
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandCheckoutDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="assignServiceFormDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="assignServiceForm"
            v-model.trim="assignFormValid"
            lazy-validation
            v-on:submit.stop.prevent="assignServiceForm"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="assignFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="assignFormLoading || !assignFormValid || visitLoading"
            :loading="assignFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="assignServiceForm"
          >
            Assign
          </v-btn>
          <v-btn
            :disabled="assignFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="assignServiceFormDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="resumeTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue my-0">
                  Are you sure, you want to resume timer for visit#
                  {{ visit.barcode }} ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerLoading || timerFormLoading || visitLoading"
            :loading="timerLoading || timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="resumeTimer()"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerLoading || timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="resumeTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="remark_dialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure, you want to close ?
              </p>
              <v-layout class="my-4">
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >

                  <v-textarea
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Remark"
                    solo
                    flat
                    row-height="25"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="remark_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="appointmentUpdateDialog">
        <template v-slot:title>Reschedule Appointment</template>
        <template v-slot:body>
          <v-container fluid class="py-0" style="min-height: 27vh">
            <v-row>
              <v-col md="4" class="py-1">
                <div class="py-1 font-weight-600">Start Date</div>
                <DatePicker
                  v-model="starDateschdule"
                  key="visit-recurring-end-date"
                  placeholder="Select End Date"
                />
              </v-col>
              <v-col md="4" class="py-1">
                <div class="py-1 font-weight-600">Start Time</div>
                <TimePicker
                  key="visit-schedule-start-time"
                  id="schedule-start-time"
                  placeholder="Start Time"
                  v-model="starTimereschdule"
                  :min-time="todayTime"
                  v-on:change="addTime(starTimereschdule)"
                />
              </v-col>
              <v-col md="4" class="py-1">
                <div class="py-1 font-weight-600">End Time</div>
                <TimePicker
                  key="visit-schedule-end-time"
                  id="schedule-end-time"
                  placeholder="End Time"
                  v-model="endTimereschdule"
                />
              </v-col>

              <v-col md="12" class="py-2">
                <em
                  >All technicians will be removed from visit#
                  {{ visit.barcode }}</em
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            :loading="apptFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateAppointment()"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="appointmentUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="followUpStatusUpdateDialog">
        <template v-slot:title>Follow Up Status Update</template>
        <template v-slot:body>
          <v-form
            ref="followUpForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="createFollowup"
          >
            <v-container class="py-0">
              <v-row class="py-0">
                <v-col md="12">
                  <label
                    class="py-1 mb-1 font-weight-600 required"
                    style="margin-left: 5px !important"
                    >Remark</label
                  >
                  <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model.trim="followUp.remark"
                    placeholder="Remark"
                    solo
                    flat
                    row-height="20"
                    counter="200"
                  />
                  <!-- <v-textarea
                    v-model.trim="followUp.remark"
                    auto-grow
                    dense
                    filled
                    flat
                    label="Remark"
                    solo
                    color="cyan"
                    hide-details
                    row-height="20"
                    v-on:keypress="(e) => manageLimit(e)"
                    v-on:paste="(e) => onPaste(e)"
                  ></v-textarea> -->
                </v-col>
                <!-- <v-col md="12" class="text-right" style="margin-bottom: -38px"
                  >{{
                    followUp && followUp.remark ? followUp.remark.length : 0
                  }}/200</v-col
                > -->
                <v-col md="12">
                  <div class="py-1 ml-2 font-weight-600">Attachments</div>
                  <table
                    width="100%"
                    style="table-layout: fixed"
                    class="my-auto py-0 align-items-center"
                  >
                    <tr
                      v-for="(row, index) in followUp.files"
                      :key="`create-visit-attachment-${index}`"
                    >
                      <td class="">
                        <v-file-input
                          hide-details
                          class="px-0"
                          dense
                          filled
                          solo
                          flat
                          v-model="row.file"
                          placeholder="Select File"
                          prepend-icon=""
                          prepend-inner-icon="$file"
                          v-on:change="updateFile(index, $event)"
                          v-on:click:clear="updateFile(index, $event)"
                          color="cyan"
                        />
                      </td>
                      <td class="py-0">
                        <v-text-field
                          hide-details
                          class="px-0"
                          v-model="row.name"
                          placeholder="Name"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </td>
                      <td class="px-0 py-0" width="5%" align="center">
                        <v-btn
                          :disabled="followUp?.files?.length < 2"
                          v-on:click="removeFile(index)"
                          icon
                          class="w-100"
                          color="red lighten-1"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td class="" colspan="3">
                        <v-btn
                          color="cyan white--text"
                          class="custom-bold-button"
                          depressed
                          v-on:click="addMore()"
                          tile
                          >Add More</v-btn
                        >
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="pageLoading || visitLoading"
            :loading="pageLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="createFollowup"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="pageLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="followUpStatusUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="confirm_dialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure, you want to
                <span class="font-weight-700">Cancel</span>?
              </p>
              <!--  <p v-else class="font-weight-600 font-size-19">
                You are cancel a contract job, do you want to reverse consume
                service back to contract?
              </p> -->
              <label for="cancel-reason" class="font-weight-600 font-size-18"
                >Reason</label
              >
              <TextAreaField
                auto-grow
                dense
                filled
                color="cyan"
                v-model="cancel_reason"
                placeholder="Enter Reason..."
                solo
                flat
                row-height="25"
                counter="200"
              />
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            v-on:click="updateStatus('mark_as_cancel')"
            :disabled="!formValid || statusLoading"
            :loading="statusLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
          <v-btn
            :disabled="statusLoading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>

      <!--  <template v-if="team_dialog">
        <SelectTeam :team-dialog="team_dialog" v-on:resetAll="team_dialog = false" :visit-data="visit"
          :ticket-data="detail">
        </SelectTeam>
      </template> -->
      <CreateCloser
        v-if="runningTimer.id"
        is-visit-detail
        :ticketId="ticketId"
        :accept_term="detail.accept_term"
        :ticket-id="ticketId"
        :visit-id="visitId"
        :timesheet-id="runningTimer.id"
        :timesheet="runningTimer.id"
        :visit-detail="visit"
        :visit-payment="paymentmodes"
        :admin-email="company_email"
        :customer-mail="customer_email"
        :user-data="userArr.user_email"
        :user-detail="userArr"
        :closer-dialog="closerDialog"
        :detail="detail"
        v-on:create:ticket-closer="createdTimeCloser"
        v-on:close-dialog="closerDialog = false"
        :total-charge="runningAmount"
      ></CreateCloser>
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="visit"
        v-on:close="mail_dialog = false"
        :type-id="visitId"
        v-on:success="getVisit()"
      ></SendMail>
      <SendMail
        :mail-dialog="sdo_mail_dialog"
        title="Send SDO"
        type="visit-sdo"
        :pdf="visit.pdf_url"
        v-on:close="sdo_mail_dialog = false"
        :type-id="visitId"
        v-on:success="getVisit()"
      ></SendMail>
      <ShareServiceReport
        :mail-dialog="share_dialog"
        title="Share Service Forms"
        v-on:close="share_dialog = false"
        :visit-id="visitId"
        v-on:success="getVisit()"
        v-on:open="share_dialog = true"
      ></ShareServiceReport>
      <FullDialog
        v-if="notesDialog"
        :dialog="notesDialog"
        content-class="testdata"
      >
        <template v-slot:title
          >{{ ticket.title }}(
          <template v-if="visit.trip_count > 0"
            >{{ visit.barcode }}-{{ visit.trip_count }}</template
          >
          <template v-else>{{ visit.barcode }}-01</template>
          ) Notes
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="ml-2 mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
                :color="getStatusColor(visit.status)"
                label
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                {{ visit.status_text }}
              </v-chip>
            </template>
            <span>Status</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn
            class="text-white"
            color="red darken-4"
            depressed
            tile
            v-on:click="notesDialog = fa"
          >
            Close
          </v-btn>
        </template>
        <template v-slot:body>
          <ChatNote
            v-if="notesDialog"
            :relatedId="visitId"
            :relatedType="'visit'"
            :documentTypes="26"
            create-url="visit-notes"
            get-url="visit-notes"
          ></ChatNote>
        </template>
        <!-- <template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialog">
					Close
				</v-btn>
			</template> -->
      </FullDialog>
      <div class="floating-btn-wrapper">
        <div class="position-relative">
          <div
            class="call-btn-underlay"
            style="background-color: rgb(43, 149, 105)"
          ></div>
          <v-icon
            style="font-size: 50px !important"
            color="green"
            small
            v-on:click="notesDialog = true"
            >mdi-chat
          </v-icon>
        </div>
      </div>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ChatNote from "@/view/pages/partials/ChatNote";
import {
  SET_VIEW_LINE_ITEM,
  SET_VIEW_CALCULATION,
} from "@/core/services/store/line.item.module";

/* import CustomStatus from "@/view/pages/partials/CustomStatus.vue"; */
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import TicketLineItem from "@/view/pages/partials/Line-Item-Detail-New";
import Files from "@/view/components/Files";
import ValidationMixin from "@/core/plugins/validation-mixin";
import VisitTeamDetail from "@/view/pages/job/partials/Visit-Team-Detail.vue";
import VisitTrips from "@/view/pages/job/partials/Visit-trips.vue";
import VisitfollowupDetail from "@/view/pages/job/partials/Visit-follow-up-Detail.vue";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
import VisitTimestamp from "@/view/pages/job/partials/Visit-Timestamp.vue";
import ServiceFormDetail from "@/view/pages/job/partials/Service-Form-Detail.vue";
import TicketCloserDetail from "@/view/pages/job/partials/Ticket-Closer-Detail.vue";
import { ChatRefressEventBus } from "@/core/lib/job/chats.refress.mixin";

import VisitNotesDetail from "@/view/pages/job/partials/Visit-Notes-Detail.vue";
import VisitLineItem from "@/view/pages/partials/Line-Item-View-V2.vue";
import SendMail from "@/view/pages/partials/Send-Mail";
import ShareServiceReport from "@/view/pages/partials/Share-Service-Report-Mail";
//import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import DatePicker from "@/view/components/main-job/QDatePicker.vue";

//import Timepicker from "@/view/pages/partials/Timepicker.vue";
import TimePicker from "@/view/components/main-job/TimePicker.vue";

import { mapGetters } from "vuex";

import Dialog from "@/view/pages/partials/Dialog";
//import SelectTeam from "@/view/pages/partials/Select-Team.vue";
//import InternalQuotation from "@/view/pages/partials/Internal-Quotation";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";

// import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
//import {  CheckinCheckoutcata,} from "@/core/lib/checkin.lib";
import {
  GET,
  QUERY,
  PATCH,
  POST,
  PUT,
} from "@/core/services/store/request.module";
import Priority from "@/view/pages/partials/Priority.vue";
import CreateCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import JwtService from "@/core/services/jwt.service";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import { ADD_CHECKOUT_VISIT } from "@/core/services/store/common.module";
import FullDialog from "@/view/components/FullDialog";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";

import {
  ErrorEventBus,
  InitializeError,
  SuccessEventBus,
} from "@/core/lib/message.lib";
import {
  toSafeInteger,
  filter,
  head,
  last,
  isArray,
  isEmpty,
  concat,
  toNumber,
  find,
  forEach,
  toString,
  padStart,
} from "lodash";
import { downloadEvent } from "@/core/lib/ics.lib";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { currentUser } from "@/core/services/jwt.service";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ObjectPath from "object-path";

import {
  /* GET_OPTION,
  SET_SCHEDULE_TYPE, */
  SET_CUSTOMER,
  SET_BILLING,
  SET_BILLING_CONTACT,
  GET_AVAILABLE_TEAM,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  /*  RESET_STATE, */
} from "@/core/services/store/visit.module";
//import CreateTicketCloserMixin from "@/core/lib/ticket-closer/create.ticket.closer.mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [
    CommonMixin,
    ValidationMixin,
    JobRequestMixin,
    // CreateTicketCloserMixin,
  ],
  data() {
    return {
      visitId: 0,
      quotationId: 0,
      invoiceId: 0,
      enquiryId: 0,
      yourFormat: "hh:mm A",
      followupUpdateList: [
        {
          text: "Follow Up Open",
          value: 1,
          color: "green",
        },
        {
          text: "Follow Up Closed",
          value: 2,
          color: "red",
        },
      ],
      ticketId: 0,
      ticket: {},
      sdo_mail_dialog: false,
      is_last_man: false,
      share_dialog: false,
      followUpStatusUpdateDialog: false,
      cancel_reason: null,
      pageLoading: false,
      pageLoaded: false,
      statusLoading: false,
      notesDialog: false,
      confirm_loading: false,
      mail_dialog: false,
      visitLoading: false,
      serviceFormLoading: false,
      appointmentUpdateDialog: false,
      endTimereschdule: null,
      starTimereschdule: null,
      starDateschdule: null,
      timerFormLoading: false,
      serviceFormList: [],
      startTimerDialog: false,
      follow_up_route: null,
      /*  refress:false, */
      HoldandStayandCheckoutDialog: false,
      HoldandStayDialog: false,
      HoldandCheckoutDialog: false,
      timerLoading: false,
      team_dialog: false,
      remark_dialog: false,
      checkout: {},
      followUp: {
        remark: null,
        status: null,
        files: [
          {
            file: null,
            name: null,
          },
        ],
      },
      runningAmount: {
        service_form: null,
        ticket: null,
        visit: null,
        total_charge: 0,
      },
      data: {
        remark: null,
      },
      runningTimer: {
        barcode: null,
        closer: null,
        date: null,
        duration: null,
        end_at: null,
        end_date: null,
        id: null,
        is_service_form_assigned: false,
        is_service_form_filled: false,
        service_form: null,
        start_at: null,
        status: null,
        status_text: null,
        ticket: null,
        visit: null,
        total_charge: 0,
      },
      timerDuration: {
        hour: "00",
        minute: "00",
        second: "00",
      },
      isTimmerRunning: false,
      resumeTimerDialog: false,
      closerDialog: false,
      assignFormLoading: false,
      assignServiceFormDialog: false,
      timerFormValid: true,
      assignFormValid: true,
      timeInterval: null,
      durationInterval: null,
      timeIntervalLimit: 10000,
      updateAppointmentFormData: {
        date: null,
        start_time: null,
        end_date: null,
        end_time: null,
      },
      apptFormLoading: false,
      apptEngLoading: false,
      engineer_menu: false,
      /*    ticketTab: "line-item", */
      timerServiceForm: [],
      serviceformdata: [],
      moreActions: [],
      detail: {
        term_conditions: null,
      },
      visit: {},
      paymentmodes: {},
      company_email: {},
      dbLineItems: [],
      dbEquipments: [],
      engineerList: [],
      assigned_team_new: [],
      assigned_team: [],
      closerdata: [],
      confirm_dialog: false,
    };
  },
  methods: {
    downloadFile(url) {
      if (!url) {
        return false;
      }
      window.open(url, "_blank");
    },
    checkStartTimeButton() {
      const user = currentUser();
      // console.log(user, "user");
      if (this.visit.engineers) {
        let startTimerFormData = find(this.visit.engineers, (row) =>
          row.user == user && user.id ? user.id : 0
        );
        // console.log(this.visit.engineers, "this.visit.engineers");

        //  console.log(startTimerFormData, "startTimerFormData")
        if (startTimerFormData) {
          return true;
        }
      }
      return false;
    },
    createFollowup() {
      const _this = this;

      if (!_this.$refs.followUpForm.validate()) {
        return false;
      }
      _this.pageLoading = true;
      let formData = new FormData();

      formData.append(
        "status",
        this.followUp.status ? this.followUp.status : 1
      );
      if (this.followUp && this.followUp.remark) {
        formData.append(
          "remark",
          this.followUp.remark ? this.followUp.remark : null
        );
      }

      if (this.followUp.files && this.followUp.files.length) {
        for (let i = 0; i < this.followUp.files.length; i++) {
          if (
            this.followUp.files &&
            this.followUp.files[i] &&
            this.followUp.files[i].file
          ) {
            formData.append(`file[${i}][file]`, this.followUp.files[i].file);
          }
          if (
            this.followUp.files &&
            this.followUp.files[i] &&
            this.followUp.files[i].name
          ) {
            formData.append(`file[${i}][name]`, this.followUp.files[i].name);
          }
        }
      }

      _this.$store
        .dispatch(POST, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visit.id +
            "/followup-new/" +
            _this.visit.followup.id,
          data: formData,
        })
        .then(() => {
          _this.getVisit();
          _this.followUp.status = null;
          _this.followUp.remark = null;
          (_this.followUp.files = [
            {
              file: null,
              name: null,
            },
          ]),
            (_this.followUpStatusUpdateDialog = false);
          ChatRefressEventBus.$emit("refress:chats", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getStatusColor(status) {
      if (status == 1) {
        return "blue darken-1";
      }
      if (status == 2) {
        return "yellow darken-1";
      }
      if (status == 3) {
        return "orange darken-4";
      }
      if (status == 4) {
        return "green darken-1";
      }
      if (status == 5) {
        return "red lighten-1";
      }
      if (status == 6) {
        return "orange darken-4";
      }
      return null;
    },
    getFollowColor(data) {
      if (data == 1) {
        return "green";
      } else {
        return "red";
      }
    },
    getFollowText(data) {
      if (data == 1) {
        return "green--text";
      } else {
        return "red--text";
      }
    },
    VisitChecoutUpdate(type) {
      const _this = this;

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "checkout/" + this.visitId,
          data: {
            leave: type ? type : null,
          },
        })
        .then(() => {})
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    /* getClosers() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "job/" + this.ticketId + "/complate-closers",
        })
        .then((response) => {
          this.closerdata = response.data;
        });
    }, */
    VisitCheckinUpdate() {
      const _this = this;

      _this.formLoading = true;

      _this.$store
        .dispatch(POST, { url: "checkin/" + this.visitId })
        .then((data) => {
          _this.getActiveTimer();
          this.checkIn = data;
          ServiceFormEventBus.$emit("reload:timestam-refresh", true);
          // _this.getActiveTimer();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          //   console.log(data, "data")
          if (data?.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                to_equipment: data[i].to_equipment,
                product_type: data[i].product_type,
                project_price: data[i].project_price,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
                is_optional: data[i].is_optional,
              });
            }

            _this.$nextTick(() => {
              _this.dbLineItems = filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.dbEquipments = filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getDurationText() {
      const start_date = head(this.detail.duration);
      const last_date = last(this.detail.duration);
      return `${this.formatDate(start_date)} to ${this.formatDate(last_date)}`;
    },
    lengthCount(param) {
      this.itemCount = param;
    },
    getServiceFormList() {
      if (this.serviceFormLoading) {
        return false;
      }
      this.serviceFormLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: { customer: this.visit.customer },
        })
        .then(({ data }) => {
          this.serviceFormList = data;
          this.timerServiceForm = this.lodash.map(
            this.serviceFormList,
            (row) => row.value
          );

          this.startTimerDialog = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.serviceFormLoading = false;
        });
    },
    startTimer() {
      // alert(223);
      /* if (!this.$refs.timerStartForm.validate()) {
        return false;
      } */

      this.timerFormLoading = true;
      this.timerLoading = true;

      this.$store
        .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/timer/start",
          data: { service_form: this.timerServiceForm },
        })
        .then(() => {
          this.startTimerDialog = false;
          this.timerServiceForm = new Array();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerFormLoading = false;
          this.timerLoading = false;
          this.getVisit();
          this.checkTimerStatus();
          this.updateTimerDuration();
        });
    },

    updateOrCreateHold(action, type) {
      const _this = this;

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      const signatures = [];
      const formData = {
        action,
        type,
        ticket: _this.ticketId,
        visit: _this.visitId,
        timesheet: _this.runningTimer.id,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        payment_collected: _this.collected_amount,
        payment_status: _this.collected_status,
        payment_mode: _this.payment_mode,
        details: _this.data.details,
        remark: _this.data.remark,

        tested_with_client: _this.data.tested_with_client,
      };
      _this.$store
        .dispatch(POST, {
          url: "job/" + _this.ticketId + "/visit/" + _this.visitId + "/hold",
          data: formData,
        })
        .then(() => {
          this.HoldandStayDialog = false;
          this.HoldandStayandCheckoutDialog = false;
          this.getVisit();
          this.getActiveTimer();
          // _this.$emit("create:ticket-closer", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    updateFile(index, file) {
      if (file && file.name) {
        this.followUp.files[index].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.followUp.files[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.followUp.files[index].name = null;
        this.followUp.files[index].suffix = null;
      }
    },
    addMore() {
      this.followUp.files.push({
        file: null,
        name: null,
      });
    },

    removeFile(index) {
      this.followUp.files.splice(index, 1);
      if (this.followUp.files.length < 1) {
        this.addMore();
      }
    },
    updateOrCreateHoldandCheckout(action, type) {
      const _this = this;

      /*   if (!_this.$refs.ticketCloserForm.validate()) {
        return false;
      } */

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      const signatures = [];
      const formData = {
        action,
        type,
        ticket: _this.ticketId,
        visit: _this.visitId,
        timesheet: _this.runningTimer.id,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        payment_collected: _this.collected_amount,
        payment_status: _this.collected_status,
        payment_mode: _this.payment_mode,
        details: _this.data.details,
        remark: _this.data.remark,

        tested_with_client: _this.data.tested_with_client,
      };
      _this.$store
        .dispatch(POST, {
          url:
            "job/" + _this.ticketId + "/visit/" + _this.visitId + "/checkout",
          data: formData,
        })
        .then(() => {
          this.HoldandCheckoutDialog = false;
          this.HoldandStayandCheckoutDialog = false;
          this.getVisit();
          this.getActiveTimer();
          // _this.$emit("create:ticket-closer", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    startTimerWithLater() {
      if (this.isTimmerRunning) {
        this.closerDialog = true;
      } else {
        this.timerFormLoading = true;
        this.timerLoading = true;
        this.$store
          .dispatch(PATCH, {
            url:
              "job/" +
              this.ticketId +
              "/visit/" +
              this.visitId +
              "/timer/start",
          })
          .then(() => {
            this.startTimerDialog = false;
            this.timerServiceForm = new Array();
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerFormLoading = false;
            this.timerLoading = false;
            this.getVisit();
            this.checkTimerStatus();
            this.updateTimerDuration();
          });
      }
    },
    getActiveTimer() {
      if (this.visit.status == 2) {
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running/web",
          })
          .then(({ data }) => {
            this.runningTimer = new Object(data.result);
            this.checkout = new Object(data.checkOut);
            this.is_last_man = new Object(data.is_last_man);
            this.$store.dispatch(ADD_CHECKOUT_VISIT, this.checkout);

            //CheckinCheckoutcata.$emit("visit-newposts-code",this.checkout);
            // console.log(this.checkout, "this.checkout");
            this.isTimmerRunning = !!data;
            if (this.isTimmerRunning) {
              this.resumeTimerDialog = false;
            }
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      }
    },
    closeFollowUp(data) {
      const _this = this;
      if (data.status == 2) {
        return false;
      }
      _this.$store
        .dispatch(PATCH, {
          url: "close-followup",
          data: {
            id: data.id,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getVisit();
        });
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.followUp.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.followUp.remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (this.followUp.remark && this.followUp.remark.length > 199) {
        e.preventDefault();
      }
    },
    closeCompleteFollowUp(status) {
      if (status == this.visit.followup.status) {
        return false;
      }
      this.followUp.status = null;
      this.followUp.remark = null;
      (this.followUp.files = [
        {
          file: null,
          name: null,
        },
      ]),
        (this.followUp.status = status);
      this.followUpStatusUpdateDialog = true;
    },
    /*  getAmount() {
      if (this.visit.status != 1) {
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running-amount",
          })
          .then(({ data }) => {
            console.log(data,"data");
            this.runningAmount = new Object(data);
          })
          .catch((error) => {
            console.log(error,"error");
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      }
    },
 */
    /*   getActiveTimer() {
      
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running",
          })
          .then(({ data }) => {
          
            this.runningTimer = new Object(data);
            this.isTimmerRunning = !!data;
            if (this.isTimmerRunning) {
              this.resumeTimerDialog = false;
            }
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      
    }, */

    /*  checkSignatureStatus(action) {
      if (action == "signature_link" || action == "send_as_email") {
        if (this.closerdata.accept_term == 1 || this.is_staff) {
          return true;
        }
        return false;
      }

      return true;
    }, */

    checkTimerStatus() {
      const _this = this;
      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(
        _this.getActiveTimer,
        _this.timeIntervalLimit
      );
    },
    updateTimerDuration() {
      const _this = this;
      clearInterval(_this.durationInterval);
      _this.durationInterval = setInterval(_this.setTimerDuration, 1000);
    },
    setTimerDuration() {
      if (isEmpty(this.runningTimer) === false) {
        let startDate = toString(this.runningTimer.date);
        if (this.runningTimer.start_at) {
          startDate = toString(startDate + " " + this.runningTimer.start_at);
        }

        if (startDate && moment(startDate, ["YYYY-MM-DD HH:mm:ss"]).isValid()) {
          startDate = moment(startDate, ["YYYY-MM-DD HH:mm:ss"]);

          let duration = moment.duration(moment().diff(startDate));

          let hours = parseInt(duration.asHours());
          let minutes = parseInt(duration.asMinutes()) % 60;
          let seconds = parseInt(duration.asSeconds()) % 60;

          this.timerDuration = {
            hour: hours > 0 ? padStart(hours, 2, "0") : "00",
            minute: minutes > 0 ? padStart(minutes, 2, "0") : "00",
            second: seconds > 0 ? padStart(seconds, 2, "0") : "00",
          };
        }
      }
    },
    assignServiceFormDetail() {
      this.timerLoading = true;

      this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: {
            timesheet: this.runningTimer.id,
            visit: this.visitId,
            status: this.visit.status,
          },
        })
        .then(({ data }) => {
          this.serviceFormList = data;
          this.assignServiceFormDialog = true;
          this.timerServiceForm = this.lodash.map(
            this.serviceFormList,
            (row) => row.value
          );
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    assignServiceForm() {
      if (!this.$refs.assignServiceForm.validate()) {
        return false;
      }

      this.assignFormLoading = true;

      this.$store
        .dispatch(POST, {
          url: `job/${this.ticketId}/visit/${this.visitId}/service-form/${this.timerServiceForm}`,
          data: { timesheet: this.runningTimer.id || null },
        })
        .then(() => {
          this.timerServiceForm = new Array();
          ServiceFormEventBus.$emit("reload:service-form");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.assignFormLoading = false;
          this.assignServiceFormDialog = false;
          this.getActiveTimer();
        });
    },
    createdTimeCloser() {
      this.serviceFormLoading = false;
      this.timerFormLoading = false;
      this.serviceFormList = [];
      this.startTimerDialog = false;
      this.HoldandStayandCheckoutDialog = false;
      this.timerLoading = false;
      (this.userArr = null),
        (this.customer_email = null),
        (this.company_email = null),
        (this.runningTimer = {
          barcode: null,
          closer: null,
          date: null,
          duration: null,
          end_at: null,
          end_date: null,
          id: null,
          is_service_form_assigned: false,
          is_service_form_filled: false,
          service_form: null,
          start_at: null,
          status: null,
          status_text: null,
          ticket: null,
          visit: null,
        });
      this.timerDuration = {
        hour: "00",
        minute: "00",
        second: "00",
      };
      this.isTimmerRunning = false;
      this.resumeTimerDialog = false;
      this.closerDialog = false;
      this.assignFormLoading = false;
      this.assignServiceFormDialog = false;
      this.timerFormValid = true;
      this.assignFormValid = true;
      this.getVisit();
      clearInterval(this.timeInterval);
      clearInterval(this.durationInterval);
    },
    resumeTimer() {
      this.timerFormLoading = true;
      this.$store
        .dispatch(PATCH, { url: "visit/" + this.visitId + "/resume" })
        .then(() => {
          this.resumeTimerDialog = false;

          /* if (this.checkout && this.checkout.id == null) {
            this.VisitCheckinUpdate();
          } */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerFormLoading = false;
          this.getVisit();
          this.checkTimerStatus();
          this.updateTimerDuration();
        });
    },

    updateAppointment() {
      if (this.starTimereschdule && this.endTimereschdule) {
        let starttime = moment(this.starTimereschdule, "hh:mm A");
        let endtime = moment(this.endTimereschdule, "hh:mm A");
        if (starttime.isAfter(endtime) || starttime.isSame(endtime)) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError(`End Time must be after Start Time.`)
          );
          return false;
        }
      }

      this.apptFormLoading = true;

      this.$store
        .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/re-schedule",
          data: {
            date: this.starDateschdule
              ? moment(this.starDateschdule).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_date: this.starDateschdule
              ? moment(this.starDateschdule).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),

            start_time: this.starTimereschdule
              ? moment(this.starTimereschdule, ["hh:mm a"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
            end_time: this.endTimereschdule
              ? moment(this.endTimereschdule, ["hh:mm a"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
          },
        })
        .then(() => {
          this.appointmentUpdateDialog = false;
          this.getVisit();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.apptFormLoading = false;
        });
    },
    addTime(date) {
      let endTime = moment(date, "hh:mm A")
        .add(60, "minutes")
        .format("hh:mm A");

      this.endTimereschdule = endTime;
    },
    getAvailableEngineers() {
      let request = {
        start_date: moment(this.visit.started_at).format("YYYY-MM-DD"),
        start_time: moment(this.visit.started_at).format("HH:mm"),
        end_date: moment(this.visit.finished_at).format("YYYY-MM-DD"),
        end_time: moment(this.visit.finished_at).format("HH:mm"),
        force: 0,
        all: 1,
        "quote-type": this.visit?.quotation_relation?.type,
        quotation: this.visit?.quotation_relation?.id,
        enquiry: this.visit?.enquiry_relation?.id,
        visit: this.visit?.id,
      };
      this.loading = true;
      this.$store
        .dispatch(GET_AVAILABLE_TEAM, request)
        .then((output) => {
          if (output) {
            this.engineerList = this.vAvailableTeam;
            this.engineer_menu = true;
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loadingEngineers = false;
        });
    },
    getAvailableEngineers12() {
      this.loadingEngineers = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/available-engineer",
          data: {
            start_date: moment(this.visit.started_at).format("YYYY-MM-DD"),
            start_time: moment(this.visit.started_at).format("HH:mm"),
            end_date: moment(this.visit.finished_at).format("YYYY-MM-DD"),
            end_time: moment(this.visit.finished_at).format("HH:mm"),
            force: 0,
            all: 1,
            web: true,
          },
        })
        .then(({ data }) => {
          this.engineerList = data.engineers;
          this.engineer_menu = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loadingEngineers = false;
        });
    },

    routeToDuplicate() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            duplicate: this.ticketId,
          },
        })
      );
    },
    updateEngineer() {
      if (!this.assigned_team_new?.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select an engineer then try again.")
        );
        return false;
      }
      this.apptEngLoading = true;
      this.$store
        .dispatch(PUT, {
          url: "job/" + this.ticketId + "/visit/" + this.visitId + "/engineer",
          data: {
            engineer: this.assigned_team_new,
          },
        })
        .then(() => {
          this.engineerList = [];
          this.$nextTick(() => {
            this.getVisit();
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.apptEngLoading = false;
        });
    },
    updateEngineersadsa() {
      let EngineerId = [];
      let teamData = find(
        this.engineerList,
        (row) => row.id == this.assigned_team
      );
      let teamId = teamData.id;
      // console.log(teamData.id,"teamData");
      if (teamData && teamData.team_data) {
        forEach(teamData.team_data, (row3) => {
          EngineerId.push(row3.staff_data.id);
        });
      }

      // console.log(EngineerId,"EngineerId");

      this.apptEngLoading = true;

      if (isArray(EngineerId) && EngineerId?.length > 0) {
        this.$store
          .dispatch(PUT, {
            url:
              "job/" + this.ticketId + "/visit/" + this.visitId + "/engineer",
            data: {
              engineer: EngineerId,
              teamId: teamId,
            },
          })
          .then(() => {
            this.engineerList = [];
            SuccessEventBus.$emit(
              "update:success",
              this.visit.barcode + " Re-assign successfully."
            );
            this.$nextTick(() => {
              this.getVisit();
            });
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.apptEngLoading = false;
          });
      }
    },
    updateMoreAction(type) {
      switch (type) {
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "convert_quotation":
          this.routeToQuotation();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "re_schedule_visit":
          this.appointmentUpdateDialog = true;
          break;
        case "send_sdo":
          this.sdo_mail_dialog = true;
          break;
        case "mark_as_cancel":
          this.confirm_dialog = true;
          //this.updateStatus("mark_as_cancel");
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "available_staff":
          this.getAvailableEngineers();
          break;
        case "download_schedule":
          this.downloadSchedule();
          break;
        /* case "download_pdf":
          this.downloadPDF();
          break; */
        case "print":
          this.printPDF();
          break;
      }
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "visit/" + this.visitId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    downloadVisit() {
      try {
        let startDateArray = moment(this.visit.started_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let startTimeArray = moment(this.visit.started_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        let endDateArray = moment(this.visit.finished_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let endTimeArray = moment(this.visit.finished_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        if (startTimeArray[0] == "00") {
          startTimeArray = new Array();
        }

        const visit = {
          start: concat(startDateArray, startTimeArray),
          end: concat(endDateArray, endTimeArray),
          title: this.ticket.title,
          description: this.visit.instruction,
          location: this.detail.property.property_address,
          url: process.env.VUE_APP_BASE_URL,
          geo: {
            lat: toNumber(this.detail.property.latitude),
            lon: toNumber(this.detail.property.longitude),
          },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.visit.added_by.display_name,
            email: this.visit.added_by.user_email,
          },
          attendees: this.visit.engineers.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          }),
        };
        downloadEvent(visit);
      } catch (error) {
        console.log({ error });
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    updateStatus(status, reverse_contract = false) {
      if (this.statusLoading) {
        return false;
      }
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "visit/" + this.visitId + "/status",
          data: { status, reverse_contract, cancel_reason: this.cancel_reason },
        })
        .then(() => {
          this.getVisit();
          this.confirm_dialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },
    downloadSchedule() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}job/${id}/download-schedule?token=${token}`;
      window.open(url, "_blank");
    },
    /* downloadPDF() {
      const { id } = this.visit;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}visit/${id}/pdf/download?token=${token}`;
      window.open(url, "_blank");
    }, */
    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}visit-v1/${this.visitId}/pdf?token=${token}`;
      window.open(url, "_blank");
    },
    printPDF() {
      window.open(this.visit.pdf_url, "_blank");
    },
    routeToQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            ticket: this.ticketId,
            visit: this.visitId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            ticket: this.ticketId,
            visit: this.visitId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },

    getVisit() {
      this.$store
        .dispatch(GET, { url: "visit/v1/" + this.visitId })
        .then(({ data }) => {
          this.starDateschdule = moment(data.visit.started_at).format(
            "YYYY-MM-DD"
          );
          this.starTimereschdule = moment(data.visit.started_at).format(
            "hh:mm A"
          );
          this.endTimereschdule = moment(data.visit.finished_at).format(
            "hh:mm A"
          );

          this.detail = data.ticket;
          this.quotationId = data.visit.quotation;
          this.enquiryId = data.visit.enquiry;
          this.invoiceId = data.visit.invoice;
          this.company_email = data.company_email;
          this.customer_email = data.customer_email;
          this.userArr = data.userArr;
          this.visit_reschdule_count = data.visit_reschdule_count;
          this.visit = data.visit;
          this.ticket = data.ticket;
          this.paymentmodes = data.resultpayment_modes;
          if (this.detail.accept_term == 1) {
            this.detail.accept_term = true;
          }
          if (this.detail.accept_term == 0) {
            this.detail.accept_term = false;
          }

          this.ticketId =
            this.detail && this.detail.id ? toSafeInteger(this.detail.id) : 0;
          //this.getClosers();
          this.visitId =
            this.visit && this.visit.id ? toSafeInteger(this.visit.id) : 0;
          this.getServiceForms(this.ticketId);
          this.getActiveTimer();
          // this.getAmount();

          this.$store.dispatch(SET_VIEW_CALCULATION, {
            additional_rows: this.detail?.additional_rows ?? [],
            ctx_discount_label: this.detail?.ctx_discount_label ?? null,
            ctx_discount_value: this.detail?.ctx_discount_value ?? 0,
            ctx_discount: this.detail?.ctx_discount ?? 0,
            ctx_discount_type: this.detail?.ctx_discount_type ?? 1,
            sub_total: this.detail?.sub_total ?? 1,
            discount_type: this.detail?.discount_type ?? 1,
            discount_value: this.detail?.discount_value ?? 0,
            discount_amount: this.detail?.discount_amount ?? 0,
            tax_active: this.detail?.tax_active ?? false,
            tax_value: this.detail?.tax_value ?? 0,
            tax_amount: this.detail?.tax_amount ?? 0,
            adjustment: this.detail?.adjustment ?? 0,
            total: this.detail?.total ?? 0,
            show_price: true,
            discount_level: this.detail?.discount_level ?? "transaction",
          });

          this.$store.dispatch(SET_VIEW_LINE_ITEM, data.line_item);

          this.moreActions = data.visit.more_actions;

          if (this.visit.engineers && this.visit?.engineers?.length) {
            this.assigned_team = this.visit.engineers.map(
              (row) => row.engineer
            );
          }

          /*  this.$nextTick(() => {
            this.getLineItems({
              job: this.ticketId,
              visit: this.visitId,
            });
          }); */

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Visit", route: "visit" },
            { title: "Detail" },
            { title: this.getBarcodeCount(this.visit.barcode) },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.getActiveTimer();
        });
    },
    getBarcodeCount() {
      if (this.visit.trip_count && this.visit.trip_count > 0) {
        return this.visit.barcode + "-" + this.visit.trip_count;
      } else {
        return this.visit.barcode + "-" + "01";
      }
    },
    routeToUpdate() {
      this.$router.push(
        this.getDefaultRoute("job.update", {
          query: {
            do_update: 1,
            job_id: ObjectPath.get(this.detail, "id", null),
            job_type:
              ObjectPath.get(this.detail, "type", 1) == 1
                ? "one-off"
                : "recurring",
          },
        })
      );

      this.$store.commit(
        SET_CUSTOMER,
        ObjectPath.get(this.detail, "customer", null)
      );
      this.$store.commit(
        SET_PROPERTY,
        ObjectPath.get(this.detail, "property", null)
      );
      this.$store.commit(
        SET_PROPERTY_CONTACT,
        ObjectPath.get(this.detail, "property_person", null)
      );
      this.$store.commit(
        SET_BILLING,
        ObjectPath.get(this.detail, "billing", null)
      );
      this.$store.commit(
        SET_BILLING_CONTACT,
        ObjectPath.get(this.detail, "billing_person", null)
      );
    },
  },
  created() {
    this.visitId =
      this.$route && this.$route.params && this.$route.params.id
        ? toSafeInteger(this.$route.params.id)
        : 0;
  },
  mounted() {
    this.getVisit();
    this.checkTimerStatus();
    this.updateTimerDuration();
    // this.getAmount();

    this.getActiveTimer();

    ServiceFormEventBus.$on("save:service-form", () => {
      this.getActiveTimer();
    });
    ServiceFormEventBus.$on("refresh", () => {
      // this.getAmount();
    });

    this.follow_up_route = this.$route.meta.menuActive;
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.durationInterval);
  },
  computed: {
    ...mapGetters(["vAvailableTeam"]),
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
    is_staff_login() {
      const user = currentUser();
      const role_engineer = this.lodash.toLower(user.engineer);

      return role_engineer == role_engineer;
    },

    getAppointmentText() {
      if (isEmpty(this.visit) === false) {
        let date = this.formatDate(this.visit.started_at);
        let startTime = moment(this.visit.started_at).format("hh:mm A");
        let enddate = this.formatDate(this.visit.finished_at);
        let endTime = moment(this.visit.finished_at).format("hh:mm A");
        return `<div>From - ${date} ${startTime} </div>
                <div>To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - ${enddate} ${endTime} </div>`;
      }
      return null;
    },
    updateStartDate() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.started_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateEndDate() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.finished_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateStartTime() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.started_at).format("hh:mm A");
      }
      return null;
    },
    updateEndTime() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.finished_at).format("hh:mm A");
      }
      return null;
    },
    getScheduleType() {
      if (this.detail.type === 1) {
        return "One-Off Job";
      }
      if (this.detail.type === 2) {
        return "Recurring Job";
      }
      return null;
    },
    todayTime() {
      if (Number(moment(this.visit.started_at).format("mm")) > 0) {
        let startTime = moment(this.visit.started_at).add(60, "minutes");
        return `${startTime.format("hh")}:00 ${startTime.format("A")}`;
      }
      return moment(this.visit.started_at).format("hh:mm A");
    },
    updatedDate() {
      return moment(this.starTimereschdule, "hh:mm A")
        .add(60, "minutes")
        .format("hh:mm A");
    },

    ticketTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.ticketTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      },
    },
  },
  components: {
    Dialog,
    Priority,
    VisitLineItem,
    CreateCloser,
    /*  CustomStatus, */
    FileTemplate,
    DetailTemplate,
    DatePicker,
    Barcode,
    ChatNote,
    TimePicker,
    /*  InternalInvoice,
    InternalQuotation, */
    TicketLineItem,
    VisitTeamDetail,
    VisitTrips,
    VisitTimestamp,
    ServiceFormDetail,
    Files,
    TicketCloserDetail,
    VisitNotesDetail,
    SendMail,
    ShareServiceReport,
    // SelectTeam,
    InternalHistoryDetail,
    // VueTimepicker,
    VisitfollowupDetail,
    VisitListingTemplate,
    FullDialog,
    AdditionalDocuments,
    TextAreaField,
  },
};
</script>
