<template>
  <v-row class="customer-detail custom-border-top pt-4">
    <v-col md="8" sm="12" class="pt-0">
      <div class="new-white-box-overview-main nav">
        <div class="col-md-5 customer-image text-center">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="image"
          >
          </v-skeleton-loader>
          <template v-else>
            <v-img
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage"
              aspect-ratio="1"
              max-height="200"
              max-width="200"
              class="margin-auto custom-grey-border custom-border-radius-50"
              transition="fade-transition"
            >
            </v-img>
            <!-- <Status :status="customerActivated"></Status> -->
          </template>
        </div>
        <div class="col-md-7 gray-background pt-0">
          <h5
            class="font-weight-600 font-size-18 pt-3"
            v-if="customer.company_type == 'company'"
          >
            <v-skeleton-loader
              class="custom-skeleton"
              v-if="pageLoading"
              type="button"
            >
            </v-skeleton-loader>
            <Barcode v-else :barcode="getBarcode"></Barcode>
          </h5>

          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="text@5"
          >
          </v-skeleton-loader>

          <table v-else class="width-100">
            <template v-if="customer.company_type == 'company'">
              <tr v-if="false">
                <td class="pb-2 font-size-18 text-capitalize">Security Code</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ customer.security_code }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize" width="30">
                  Name
                </td>
                <td
                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                  width="70"
                >
                  {{ fullname }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Company Name</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="customer.company_name">{{
                    customer.company_name
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No company name</em
                    ></template
                  >
                </td>
              </tr>
              <tr v-if="customer?.transfer_from_relation?.barcode">
                <td class="pb-2 font-size-18 text-capitalize">
                  Related Customer
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="customer?.transfer_from_relation?.barcode">
                    <v-chip
                      label
                      color="orange"
                      class="text-white p-3"
                      outlined
                      small
                      >{{ customer?.transfer_from_relation?.barcode }}</v-chip
                    >
                  </template>
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Reference</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="customer.reference">{{
                    customer.reference
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No reference</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Display Name</td>
                <td
                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                  v-if="customer && customer.display_name"
                >
                  {{ customer.display_name }}
                </td>
                <em v-else class="text-muted ml-2"> no display name</em>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Company Email</td>
                <td
                  class="pb-2 font-size-18 font-weight-600"
                  v-if="customer && customer.company_email"
                >
                  {{ customer.company_email }}
                </td>
                <em v-else class="text-muted ml-2"> no company email</em>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">
                  Company Phone Number
                </td>
                <td
                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                  v-if="customer && customer.company_number"
                >
                  {{ customer.company_number }}
                </td>
                <em v-else class="text-muted ml-2"> no company phone number</em>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Company fax</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="customer.company_fax">{{
                    customer.company_fax
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No company fax</em
                    ></template
                  >
                </td>
              </tr>
              <!--  <tr>
                <td class="pb-2 font-size-18 text-capitalize">Group</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="customer.group">{{ customer.group }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No Group</em
                    ></template
                  >
                </td>
              </tr> -->
              <tr v-if="false">
                <td class="pb-2 font-size-18 text-capitalize">Phone</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="customer.phone">{{
                    customer.phone
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No Phone</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18">UEN</td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="customer.uen">{{ customer.uen }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No uen</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Website</td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="customer.website">{{
                    customer.website
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No Website</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Ship Code</td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="customer.shipcode">{{
                    customer.shipcode
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No shipcode</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Customer Id</td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="customer.import_ref">{{
                    customer.import_ref
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No customer id</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize d-flex">
                  Customer Remark
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="customer.customer_remark">{{
                    customer.customer_remark
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >no customer remark</em
                    ></template
                  >
                </td>
              </tr>
              <tr v-if="false">
                <td class="pb-2 font-size-18 text-capitalize">Entity</td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="customer.entity">{{
                    customer.entity
                  }}</template>
                  <template v-else
                    ><em class="text--secondary font-weight-500"
                      >No Entity</em
                    ></template
                  >
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Name</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ customer.default_person.full_name }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Display Name</td>
                <!--   <pre>{{ customer }}</pre> -->
                <td
                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                  v-if="
                    customer &&
                    customer.default_person &&
                    customer.default_person.display_name
                  "
                >
                  {{ customer.default_person.display_name }}
                </td>
                <em v-else class="text-muted ml-2"> no display name</em>
              </tr>
              <tr>
                <td class="pb-2 font-size-18">Email Address</td>
                <td
                  class="pb-2 font-size-18 font-weight-600"
                  v-if="
                    customer &&
                    customer.default_person &&
                    customer.default_person.primary_email
                  "
                >
                  {{ customer.default_person.primary_email }}
                </td>
                <em v-else class="text-muted ml-2"> no email address</em>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Mobile Number</td>
                <td
                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                  v-if="
                    customer &&
                    customer.default_person &&
                    customer.default_person.primary_phone
                  "
                >
                  {{ customer.default_person.primary_phone }}
                </td>
                <em v-else class="text-muted ml-2"> no mobile number</em>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">
                  Landline Number
                </td>
                <td
                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                  v-if="
                    customer &&
                    customer.default_person &&
                    customer.default_person.did
                  "
                >
                  {{ customer.default_person.did }}
                </td>
                <em v-else class="text-muted ml-2"> no Landline Number</em>
              </tr>
              <!--  <tr>
                <td class="pb-2 font-size-18 text-capitalize">Company fax</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize" v-if="customer && customer.default_person && customer.default_person">
                  {{ customer.company_fax }}
                </td>
                <em v-else class="text-muted ml-2"> no  company fax</em>
              </tr> -->
            </template>
          </table>
        </div>
      </div>
    </v-col>
    <!--    <v-col class="pt-0 gray-background" md="4" sm="12">
      <h5 class="font-weight-500 font-size-18 pt-3">
        <v-skeleton-loader
          class="custom-skeleton"
          v-if="pageLoading"
          type="button"
        >
        </v-skeleton-loader>
        <span v-else class="color-custom-blue font-weight-600"
          >Contact Details</span
        >
      </h5>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="text@4"
      >
      </v-skeleton-loader>
      <template v-else>
        <table class="width-100">
          <template v-for="(detail, index) in customer.details">
            <tr v-if="detail.value" :key="index">
              <td class="pb-2 font-size-18 text-capitalize">
                {{ getDetailType(detail.type) }}
              </td>
              <td
                class="pb-2 font-size-18 font-weight-600 text-lowercase width-50px"
              >
                ({{ detail.title }})
              </td>
              <td class="pb-2 pl-2 font-size-18 font-weight-600 text-lowercase">
                {{ detail.value }}
              </td>
            </tr>
          </template>
          <template v-if="false">
            <tr>
              <td class="pb-2 font-size-18 text-capitalize">Unit No.</td>
              <td
                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                colspan="2"
              >
                <template v-if="customer.unit_number">{{
                  customer.unit_number
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Unit No.</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td class="pb-2 font-size-18 text-capitalize">Address Line 1</td>
              <td
                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                colspan="2"
              >
                <template v-if="customer.address_line_1">{{
                  customer.address_line_1
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Address Line 1</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td class="pb-2 font-size-18 text-capitalize">Address Line 2</td>
              <td
                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                colspan="2"
              >
                <template v-if="customer.address_line_2">{{
                  customer.address_line_2
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Address Line 2</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td class="pb-2 font-size-18 text-capitalize">Postal Code</td>
              <td
                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                colspan="2"
              >
                <template v-if="customer.postal_code">{{
                  customer.postal_code
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Postal Code</em
                  ></template
                >
              </td>
            </tr>
          </template>
        </table>
      </template>
    </v-col> -->
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
// import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  name: "contact-details",
  title: "Customer",
  mixins: [CommonMixin],
  props: {
    customer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getDetailType(param) {
      if (param === 1) {
        return "Email";
      }
      return "Phone";
    },
  },
  components: {
    // Status,
    Barcode,
  },
  computed: {
    customerActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.customer) === false) {
        return _this.customer.activated;
      }
      return false;
    },
    fullname() {
      let name = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.persons) === false
      ) {
        for (let i = 0; i < _this.customer.persons.length; i++) {
          if (_this.customer.persons[i].default) {
            name =
              _this.lodash.startCase(_this.customer.persons[i].title) +
              ". " +
              _this.customer.persons[i].first_name +
              " " +
              _this.customer.persons[i].last_name;
          }
        }
      }
      return name;
    },
    primaryEmail() {
      let email = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.details) === false
      ) {
        for (let i = 0; i < _this.customer.details.length; i++) {
          if (_this.customer.details[i].type === 1) {
            email = _this.customer.details[i].value;
            break;
          }
        }
      }
      return email;
    },
    primaryPhone() {
      let phone = "";
      const _this = this;
      if (
        _this.customer &&
        _this.lodash.isEmpty(_this.customer.details) === false
      ) {
        for (let i = 0; i < _this.customer.details.length; i++) {
          if (_this.customer.details[i].type === 2) {
            phone = _this.customer.details[i].value;
            break;
          }
        }
      }
      return phone;
    },
    getProfileImage() {
      if (!this.customer.profile_logo) {
        return null;
      }
      return this.customer.profile_logo.file.url;
    },
    getBarcode() {
      if (!this.customer.barcode) {
        return "";
      }
      return this.customer.barcode;
    },
  },
};
</script>
