import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";

import request from "./request.module";
import common from "./common.module";
import line_item from "./line.item.module";
import quotation from "./quotation.module";
import customer from "./customer.module";
import visit from "./visit.module";
import job from "./job.module";
import contract from "./contract.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    request,
    common,
    line_item,
    quotation,
    customer,
    visit,
    job,
    contract,
  },
});
