<template>
  <Dialog :common-dialog="headerDialog" :dialog-width="dialog_width">
    <template v-slot:title>
      <v-layout>
        <v-flex>
          Header
          <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon>
          Line Item
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button mr-2"
            depressed
            :disabled="lProcessing"
            color="cyan white--text"
            v-on:click="save_line_item()"
            >Save</v-btn
          >
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            :disabled="lProcessing"
            v-on:click="$emit('close', true)"
            >Close</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div>
        <div class="d-flex mb-2">
          <v-text-field
            v-model="search"
            class="line-item-header-text px-0 v-slot-m-none"
            placeholder="Search Headers..."
            dense
            filled
            solo
            :disabled="lProcessing"
            clearable
            flat
            color="cyan"
            style="max-width: 300px"
            v-on:keypress.enter="find_items()"
            v-on:click:clear="
              search = null;
              find_items();
            "
          />
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button ml-2"
            depressed
            :disabled="lProcessing"
            v-on:click="find_items()"
            color="cyan white--text"
            >Search</v-btn
          >
        </div>
        <div>
          <div class="d-flex grey lighten-4">
            <div
              class="font-weight-600 py-2 text-center d-flex justify-center"
              style="width: 5%"
            >
              <v-checkbox
                v-on:change="update_all_line_item($event)"
                hide-details
                :value="select_all"
                :disabled="lProcessing"
                class="my-0 py-0 px-0 mx-0 dense-checkbox"
                color="cyan"
              />
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left text-capitalize"
              style="width: 95%"
            >
                Header
            </div>
          </div>
          <div style="overflow-y: scroll; max-height: calc(100vh - 360px)">
            <div v-if="lProcessing" class="my-4 py-4">
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </div>
            <div v-else-if="lDBLineItemHeader && lDBLineItemHeader.length">
              <div
                v-for="(row, index) in lDBLineItemHeader"
                :key="index"
                class="d-flex my-2"
              >
                <div
                  class="font-weight-600 text-center d-flex justify-center"
                  style="width: 5%"
                >
                  <v-checkbox
                    v-model="row.status"
                    hide-details
                    class="my-0 py-0 px-0 mx-0 dense-checkbox"
                    color="cyan"
                  />
                </div>
                <div class="text-center" style="width: 95%">
                  <v-text-field
                    class="line-item-header-text px-0 mr-4"
                    placeholder="Header"
                    dense
                    filled
                    solo
                    v-model="row.text"
                    flat
                    color="cyan"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <p
                class="m-0 py-4 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mr-2"
                  width="30"
                />
                Uhh... There are no item at the moment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import {
  GET_LINE_ITEM_HEADERS,
  SET_LOADING,
} from "@/core/services/store/line.item.module";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  name: "line-item-dialog-v2",
  props: {
    headerDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
      select_all: null,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    find_items() {
      this.select_all = false;
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(GET_LINE_ITEM_HEADERS, { search: this.search })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
    },
    save_line_item() {
      let selected_line_items = [];
      let status = false;
      for (let item of this.lDBLineItemHeader) {
        if (item.status) {
          status = true;
          selected_line_items.push(item);
        }
      }
      if (!status) {
        ErrorEventBus.$emit(
          "update:error",
          `Please select atleast one header.`
        );
        return false;
      }

      this.$emit("update-values", selected_line_items);
      this.$emit("close", true);
    },
    update_all_line_item(status) {
      this.select_all = status;
      for (let i = 0; i < this.lDBLineItemHeader.length; i++) {
        this.lDBLineItemHeader[i].status = status;
      }
    },
  },
  mounted() {
    if (this.headerDialog) {
      this.find_items();
    }
  },
  computed: {
    ...mapGetters(["lDBLineItemHeader", "lProcessing"]),
    dialog_width() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
};
</script>
