<template>
  <div
    class="bg-white px-8 enquiry-detail-page"
    style="height: calc(100vh - 50px)"
  >
    <v-row v-if="pageLoaded">
      <v-col md="12" class="py-0">
        <v-row>
          <v-col md="6" class="d-flex align-center justify-start">
            <div class="d-flex custom-border-right">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    label
                    outlined
                    color="blue darken-4"
                    class="font-weight-600"
                    >{{ dbEnquiry.barcode }}</v-chip
                  >
                </template>
                <span>Enquiry #</span>
              </v-tooltip>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    label
                    :color="dbEnquiry?.status_relation?.color"
                    :text-color="dbEnquiry?.status_relation?.textcolor"
                    class="ml-2 text-uppercase font-weight-600"
                    >{{ dbEnquiry?.status_relation?.text }}</v-chip
                  >
                </template>
                <span>Status</span>
              </v-tooltip>
              <template v-if="false">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      label
                      :color="dbEnquiry?.priority_relation?.color"
                      :text-color="dbEnquiry?.priority_relation?.textcolor"
                      class="ml-2 text-uppercase font-weight-600"
                      >{{ dbEnquiry?.priority_relation?.text }}</v-chip
                    >
                  </template>
                  <span>Priority</span>
                </v-tooltip>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      label
                      :color="dbEnquiry?.impact_relation?.color"
                      :text-color="dbEnquiry?.impact_relation?.textcolor"
                      class="mx-2 text-uppercase font-weight-600"
                      >{{ dbEnquiry?.impact_relation?.text }}</v-chip
                    >
                  </template>
                  <span>Impact</span>
                </v-tooltip>
              </template>
            </div>
            <div class="d-flex custom-border-left">
              <v-tooltip
                v-if="dbEnquiry?.quotation_convt_date"
                top
                content-class="custom-top-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    label
                    color="teal lighten-1 white--text"
                    class="ml-2 text-uppercase font-weight-600"
                    >Quotation</v-chip
                  >
                </template>
                <span>Quotation Converted</span>
              </v-tooltip>
              <v-tooltip
                v-if="dbEnquiry?.invoice_convt_date"
                top
                content-class="custom-top-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    label
                    color="lime darken-3 white--text"
                    class="ml-2 text-uppercase font-weight-600"
                    >Invoice</v-chip
                  >
                </template>
                <span>Invoice Converted</span>
              </v-tooltip>
              <v-tooltip
                v-if="dbEnquiry?.visit_convt_date"
                top
                content-class="custom-top-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    label
                    color="brown darken-2 white--text"
                    class="ml-2 text-uppercase font-weight-600"
                    >Visit</v-chip
                  >
                </template>
                <span>Visit Converted</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col md="6" class="d-flex align-center justify-end">
            <v-btn
              v-if="dbEnquiry?.status == 1"
              v-on:click="updateMoreAction('edit')"
              depressed
              color="cyan white--text"
              class="custom-bold-button mx-2"
              ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
            >
            <template
              v-if="dbEnquiry?.more_actions && dbEnquiry?.more_actions.length"
            >
              <template v-if="dbEnquiry?.more_actions.length > 3">
                <v-menu
                  v-if="
                    dbEnquiry?.more_actions && dbEnquiry?.more_actions.length
                  "
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="cyan white--text"
                      class="custom-bold-button mx-2"
                      >More...
                      <v-icon small right>mdi-chevron-down</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <template v-for="(more, index) in dbEnquiry?.more_actions">
                      <v-list-item
                        link
                        v-on:click="updateMoreAction(more.action)"
                        :key="index"
                        :disabled="more.disabled"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue">{{
                            more.icon
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                          class="font-weight-500 font-size-14"
                          >{{ more.title }}</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <template v-else>
                <v-btn
                  v-for="(more, index) in dbEnquiry?.more_actions"
                  v-on:click="updateMoreAction(more.action)"
                  :key="index"
                  :disabled="more.disabled"
                  depressed
                  color="cyan white--text"
                  class="custom-bold-button mx-2"
                  ><v-icon small left>{{ more.icon }}</v-icon>
                  {{ more.title }}</v-btn
                >
              </template>
            </template>
            <v-btn v-on:click="goBack()" depressed class="custom-bold-button"
              ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5">
        <v-row>
          <v-col md="12">
            <span
              v-on:click="routeToCustomer()"
              class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
              >{{ dbEnquiry?.customer_relation?.display_name }}</span
            >
          </v-col>
          <v-col md="6">
            <label class="my-0">Billing Location</label>
            <template v-if="dbEnquiry?.billing_relation?.id">
              <p class="mb-0" v-if="dbEnquiry?.billing_relation?.property_name">
                {{ dbEnquiry?.billing_relation?.property_name }}
              </p>
              <p class="mb-0">
                {{ dbEnquiry?.billing_relation?.property_address }}
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbEnquiry?.billing_person_relation?.id">
              <p class="mb-0">
                {{ dbEnquiry?.billing_person_relation?.display_name }}
              </p>
              <p class="mb-0">
                {{ dbEnquiry?.billing_person_relation?.primary_phone }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{ dbEnquiry?.billing_person_relation?.primary_email }}
              </p>
            </template>
            <div class="d-flex mt-3">
              <label class="my-0">Office No.</label>
              <p class="mb-0">
                <show-value
                  :object="dbEnquiry"
                  object-key="customer_relation.company_number"
                  label="Office No."
                />
              </p>
            </div>
            <div class="d-flex mt-3">
              <label class="my-0">Fax No.</label>
              <p class="mb-0">
                <show-value
                  :object="dbEnquiry"
                  object-key="customer_relation.company_fax"
                  label="Fax No."
                />
              </p>
            </div>
          </v-col>
          <v-col md="6">
            <label class="my-0">Site Location</label>
            <template v-if="dbEnquiry?.property_relation?.id">
              <p
                class="mb-0"
                v-if="dbEnquiry?.property_relation?.property_name"
              >
                {{ dbEnquiry?.property_relation?.property_name }}
              </p>
              <p class="mb-0">
                {{ dbEnquiry?.property_relation?.property_address }}
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbEnquiry?.property_person_relation?.id">
              <p class="mb-0">
                {{ dbEnquiry?.property_person_relation?.display_name }}
              </p>
              <p class="mb-0">
                {{ dbEnquiry?.property_person_relation?.primary_phone }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{ dbEnquiry?.property_person_relation?.primary_email }}
              </p>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="7" class="gray-background">
        <table width="100%">
          <tr>
            <td
              colspan="2"
              class="color-custom-blue font-weight-700 font-size-19 text-capitalize"
            >
              <show-value
                :object="dbEnquiry"
                object-key="title"
                label="Title"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Reference #</td>
            <td class="font-weight-600">
              <show-value
                :object="dbEnquiry"
                object-key="reference"
                label="Reference #"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">
              Door Location/Serial No.
            </td>
            <td class="font-weight-600">
              <show-value
                :object="dbEnquiry"
                object-key="door_location"
                label="Door Location/Serial No."
              />
            </td>
          </tr>
          <tr v-if="false">
            <td class="font-weight-600" width="20%">Call No.</td>
            <td class="font-weight-600">
              <show-value
                :object="dbEnquiry"
                object-key="call_no"
                label="Call No."
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Call Date</td>
            <td class="font-weight-600">
              {{ formatDate(dbEnquiry?.call_date) }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Due Date</td>
            <td class="font-weight-600">
              <template v-if="dbEnquiry?.due_date">
                {{ formatDate(dbEnquiry?.due_date) }}
              </template>

              <template v-else>
                <show-value
                  :object="dbEnquiry"
                  object-key="due_date"
                  label="due_date"
                />
              </template>
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Assign</td>
            <td class="font-weight-600">
              <show-value
                :object="dbEnquiry"
                object-key="assign_relation.display_name"
                label="Assign"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Tags</td>
            <td class="font-weight-600">
              <template v-if="dbEnquiry?.tags && dbEnquiry?.tags?.length">
                <span
                  v-for="(row, index) in dbEnquiry.tags"
                  :key="`enquiry-tag-${index}`"
                  class="px-4 custom-border-radius-10 mr-2"
                  :style="`color: ${row.color}; border: solid 1px ${row.color}`"
                  >{{ row.text }}</span
                >
              </template>
              <template v-else>
                <show-value
                  :object="dbEnquiry"
                  object-key="tags.text"
                  label="tags"
                />
              </template>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col md="12">
        <v-tabs
          active-class="custom-tab-active"
          v-model="enquiryTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-14 font-weight-600 px-8" href="#overview">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/enquiry.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#quotation">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/quotation.svg')" />
              <!--end::Svg Icon-->
            </span>
            Quotation
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#invoice">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Invoice
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#visit">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
              <!--end::Svg Icon-->
            </span>
            Visits
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="enquiryTab" class="custom-border-top">
          <v-tab-item value="overview">
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      PROBLEM & ATTACHMENTS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <v-row>
                    <v-col md="6">
                      <label class="font-size-16 font-weight-600"
                        >Problem</label
                      >
                      <div class="w-100 custom-grey-border p-4">
                        <template v-if="dbEnquiry?.problem">
                          {{ dbEnquiry.problem }}
                        </template>
                        <em v-else class="text-muted"> no problem </em>
                      </div>
                    </v-col>
                    <v-col md="6">
                      <label class="font-size-16 font-weight-600"
                        >Attachments ({{
                          dbEnquiry?.attachments?.length
                        }})</label
                      >
                      <table width="100%" class="custom-border-top-1px">
                        <tbody>
                          <tr
                            v-for="(row, index) in dbEnquiry.attachments"
                            :key="`view-attach-${index}`"
                          >
                            <td valign="middle" width="80%" class="pr-2 py-2">
                              {{ row.name }}
                            </td>
                            <td
                              valign="middle"
                              width="20%"
                              align="right"
                              class="pl-2 py-2"
                            >
                              <v-icon
                                color="cyan"
                                class="mr-2"
                                v-on:click="downloadFile(row?.file?.url)"
                                >mdi-download</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-col>
                    <v-col md="6" v-if="false">
                      <label class="font-size-16 font-weight-600"
                        >Technician Sketch</label
                      >
                      <div class="w-100 custom-grey-border p-4">
                        <template v-if="dbEnquiry?.technician_sketch">
                          {{ dbEnquiry.technician_sketch }}
                        </template>
                        <em v-else class="text-muted"> no technician sketch</em>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
          <v-tab-item value="quotation">
            <internal-quotation type="enquiry" :type-id="dbEnquiry?.id" />
          </v-tab-item>
          <v-tab-item value="invoice">
            <internal-invoice type="enquiry" :type-id="dbEnquiry?.id" />
          </v-tab-item>
          <v-tab-item value="visit">
            <internal-visit
              :customer-id="dbEnquiry?.customer"
              :property-id="dbEnquiry?.property"
              :enquiry-id="dbEnquiry?.id"
              detailType="tickets"
              visitType="all"
              :visit-status="0"
              detailRoute="visit.detail"
              internal
            />
          </v-tab-item>
          <v-tab-item value="history">
            <internal-history-detail type="enquiry" :type_id="dbEnquiry?.id" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p class="text-center font-weight-600 font-size-19">Please Wait...</p>
        <v-progress-linear rounded indeterminate height="8" color="orange" />
      </v-col>
    </v-row>
    <confirm-dialog
      key="confirm-dialog-enquiry"
      :common-dialog="confirmDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container class="px-10" fluid>
          <p class="font-weight-600 font-size-19">
            Are you sure, you want to
            <span class="font-weight-700">{{ getConfirmTitle() }}</span
            >?
          </p>
          <v-layout
            class="my-4"
            v-if="
              confirmType == 'mark_as_approved' ||
              confirmType == 'mark_as_rejected' ||
              confirmType == 'mark_as_accepted'
            "
          >
            <v-flex>
              <label class="font-weight-500 font-size-16 required"
                >Remark</label
              >
              <v-textarea
                v-model.trim="actionData.remark"
                auto-grow
                dense
                filled
                color="cyan"
                label="Remark"
                solo
                flat
                row-height="25"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout class="mt-4" v-if="confirmType == 'mark_as_accepted'">
            <v-flex md12>
              <label class="font-weight-500 font-size-16"
                >Attachments (Max 5)</label
              >
              <table width="100%">
                <tbody>
                  <tr
                    v-for="(row, index) in actionData.attachments"
                    :key="index"
                  >
                    <td width="50%" class="pl-0 pr-2 py-2">
                      <v-file-input
                        prepend-inner-icon="mdi-attachment mdi-rotate-90"
                        placeholder="Click here to select file"
                        label="Click here to select file"
                        prepend-icon=""
                        color="cyan"
                        solo
                        flat
                        v-model="row.accepted_file"
                        dense
                        v-on:change="updateFileName(index)"
                        hide-details
                      ></v-file-input>
                    </td>
                    <td width="50%" class="pr-0 pl-2 py-2">
                      <v-text-field
                        placeholder="File Name"
                        label="File Name"
                        dense
                        solo
                        flat
                        color="cyan"
                        v-model="row.file_name"
                        hide-details
                        class="has-delete-outer-icon"
                        append-outer-icon="mdi-delete"
                        v-on:click:append-outer="removeRow(index)"
                        :suffix="getFileExtension(row.file_type)"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="actionData.attachments.length < 5">
                    <td colspan="2" class="pl-0">
                      <v-btn
                        color="cyan white--text"
                        small
                        v-on:click="addAttachmentRow()"
                        class="custom-bold-button"
                        >Add More...</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirmLoading"
          v-on:click="confirmDialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="confirmSubmit()"
          :disabled="confirmLoading"
          :loading="confirmLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </confirm-dialog>
    <send-mail
      :mail-dialog="mailDialog"
      title="Send as Email"
      type="enquiry"
      v-on:close="mailDialog = false"
      :type-id="enquiryId"
      v-on:success="getEnquiry()"
    ></send-mail>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import { UPLOAD, PATCH, GET } from "@/core/services/store/request.module";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import ShowValue from "@/view/components/ShowValue.vue";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import InternalQuotation from "@/view/pages/partials/Internal-Quotation";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
/*import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);*/

export default {
  mixins: [CommonMixin],
  data() {
    return {
      formLoading: false,
      tab: null,
      enquiryTab: "line-item",
      dbEnquiry: {},
      enquiryId: 0,
      pageLoaded: false,
      mailDialog: false,
      confirmDialog: false,
      confirmLoading: false,
      confirmType: null,
      panel: 0,
      actionData: {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      pageLoading: true,
    };
  },
  methods: {
    confirmSubmit() {
      switch (this.confirmType) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
      }
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.actionData.attachments.length; i++) {
          if (_this.actionData.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.actionData.attachments[i].accepted_file,
              _this.actionData.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async updateStatus() {
      if (this.confirmLoading) {
        return false;
      }

      this.confirmLoading = true;

      this.actionData.status = this.confirmType;
      this.actionData.attachments = await this.uploadFiles();

      this.$store
        .dispatch(PATCH, {
          url: "enquiry/" + this.enquiryId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getEnquiry();
          this.resetConfirm();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    resetConfirm() {
      this.confirmDialog = false;
      this.confirmType = null;
      this.actionData = {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      };
    },
    updateFileName(index) {
      const file = this.actionData.attachments[index].accepted_file;
      if (file) {
        this.actionData.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.actionData.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.addAttachmentRow();
      }
    },
    removeRow(index) {
      ObjectPath.del(this.actionData.attachments, index);
      this.$nextTick(() => {
        if (!this.actionData.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      if (this.actionData.attachments.length < 5) {
        this.actionData.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    getConfirmTitle() {
      if (this.confirmType == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirmType == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirmType == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirmType == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirmType == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirmType == "mark_as_approved") {
        return "Mark as Approved";
      }
    },
    editEnquiry() {
      this.$router.push(
        this.getDefaultRoute("enquiry.create", {
          query: { edit: this.dbEnquiry?.id },
        })
      );
    },
    updateMoreAction(action) {
      this.resetConfirm();
      switch (action) {
        case "edit":
          this.editEnquiry();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "convert_quotation":
          this.routeToQuotation();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
      }
    },
    routeToCustomer() {
      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: { customer: this.dbEnquiry?.customer },
        })
      );
    },
    routeToJob() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: { enquiry: this.dbEnquiry?.id },
        })
      );
    },
    routeToQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { enquiry: this.dbEnquiry?.id },
        })
      );
    },
    routeToInvoice() {
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: { enquiry: this.dbEnquiry?.id },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("enquiry.create", {
          query: { duplicate: this.dbEnquiry?.id },
        })
      );
    },
    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}enquiry/${this.enquiryId}/pdf?token=${token}&action=download`;
      window.open(url, "_blank");
    },
    printPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}enquiry/${this.enquiryId}/pdf?token=${token}&action=print`;
      window.open(url, "_blank");
    },
    getEnquiry() {
      this.$store
        .dispatch(GET, { url: "enquiry/" + this.enquiryId })
        .then(({ data }) => {
          this.dbEnquiry = data;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Enquiry", route: "enquiry" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.pageLoading = false;
        });
    },
  },
  created() {
    this.enquiryId = this.lodash.toSafeInteger(this.$route.params.id);
    this.enquiryTab = this.$route.query.tab ?? "overview";
  },
  mounted() {
    this.getEnquiry();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  components: {
    "show-value": ShowValue,
    "internal-history-detail": InternalHistoryDetail,
    "internal-invoice": InternalInvoice,
    "internal-quotation": InternalQuotation,
    "internal-visit": InternalVisit,
    "confirm-dialog": Dialog,
    "send-mail": SendMail,
  },
};
</script>
<style lang="scss" scoped>
.enquiry-detail-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
</style>
