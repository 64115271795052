<template>
  <v-textarea
    :id="id"
    ref="input-text-area"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :hide-details="hideDetails"
    :dense="dense"
    :filled="filled"
    :auto-grow="autoGrow"
    color="cyan"
    :rows="rows"
    :rules="rules"
    :solo="solo"
    :flat="flat"
    class="pt-0"
    v-model="textareainput"
    :placeholder="placeholder"
  ></v-textarea>
</template>
<script>
export default {
  name: "textarea-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 3,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    label: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },

    autoGrow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textareainput: null,
    };
  },
  watch: {
    value() {
      this.textareainput = this.value;
    },
    textareainput() {
      this.$emit("input", this.textareainput);
    },
  },
  mounted() {
    this.textareainput = this.value;
  },
};
</script>
