<template>
  <v-container fluid class="col-lg-10" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Entities
          <!-- ({{ entities.length }}) -->
        </h3>
        <p
          v-if="reLoginRequired()"
          class="my-2 text-warning font-weight-600 font-size-18"
        >
          <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon> Please
          logout and login again to see the effect of updated entities.
        </p>
      </v-flex>
      <v-flex class="text-right">
        <!-- <v-btn
          depressed
          v-if="entities.length < $entityLimit"
          color="cyan white--text"
          class="custom-bold-button mr-3"
          v-on:click="addEntity()"
        >
          <v-icon small left>mdi-plus</v-icon>
          Add
        </v-btn> -->
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card
      flat
      class="custom-setting-height custom-grey-border remove-border-radius"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row style="overflow: auto">
          <v-col md="12">
            <ListingTable
              :columnCount="6"
              :dataLoading="pageLoading"
              :rowData="entities"
            >
              <template v-slot:thead>
                <thead>
                  <tr>
                    <th class="simple-table-th">#</th>
                    <th class="simple-table-th">UEN No.</th>
                    <th class="simple-table-th">Entity Name</th>
                    <th class="simple-table-th">Contact No.</th>
                    <th class="simple-table-th">Address</th>
                    <th class="simple-table-th">Status</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody class="custom-border-bottom">
                  <template v-if="entities.length">
                    <tr v-for="(row, index) in entities" :key="index" link>
                      <td
                        v-on:click="updateEntity(row)"
                        class="simple-table-td"
                      >
                        {{ index + 1 }}.
                      </td>
                      <td
                        v-on:click="updateEntity(row)"
                        class="simple-table-td"
                      >
                        {{ row.uen_no }}
                      </td>
                      <td
                        v-on:click="updateEntity(row)"
                        class="simple-table-td"
                      >
                        {{ row.name }}
                      </td>
                      <td
                        v-on:click="updateEntity(row)"
                        class="simple-table-td"
                      >
                        {{ row.contact_no }}
                      </td>
                      <td
                        v-on:click="updateEntity(row)"
                        class="simple-table-td"
                      >
                        {{ row.address }}
                      </td>
                      <td
                        class="cursor-default simple-table-td text-center"
                        align="center"
                      >
                        <v-switch
                          class="my-2 p-0"
                          color="cyan"
                          v-model="row.activated"
                          inset
                          hide-details
                          :disabled="statusLoading"
                          v-on:change="updateStatus(row.id)"
                        ></v-switch>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td :colspan="6" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no entity at the moment.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </ListingTable>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <Dialog :common-dialog="entity_dialog">
      <template v-slot:title>
        <span v-if="entity.id">Update</span><span v-else>New</span>&nbsp;Entity
      </template>
      <template v-slot:body>
        <v-container>
          <v-form
            ref="entityForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="onSubmit()"
          >
            <v-layout>
              <v-flex md4>
                <label
                  for="entity-uen-no"
                  class="font-size-16 font-weight-500 required"
                  >UEN No.</label
                >
              </v-flex>
              <v-flex md8>
                <v-text-field
                  id="entity-uen-no"
                  v-model.trim="entity.uen_no"
                  :rules="[
                    validateRules.required(entity.uen_no, 'entity uen no.'),
                  ]"
                  dense
                  filled
                  label="UEN No."
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  class="required-field"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label
                  for="entity-name"
                  class="font-size-16 font-weight-500 required"
                  >Entity Name</label
                >
              </v-flex>
              <v-flex md8>
                <v-text-field
                  id="entity-name"
                  v-model.trim="entity.name"
                  :rules="[validateRules.required(entity.name, 'entity name')]"
                  dense
                  filled
                  label="Entity Name"
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  class="required-field"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label
                  for="contact-no"
                  class="font-size-16 font-weight-500 required"
                  >Contact No.</label
                >
              </v-flex>
              <v-flex md8>
                <PhoneTextField
                  id="contact-no"
                  v-model="entity.contact_no"
                  :value="entity.contact_no"
                  required
                  :disabled="formLoading"
                  :loading="formLoading"
                >
                </PhoneTextField>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label for="unit-no" class="font-size-16 font-weight-500"
                  >Unit No.</label
                >
              </v-flex>
              <v-flex md8>
                <v-text-field
                  id="unit-no"
                  v-model.trim="entity.unit_no"
                  dense
                  filled
                  label="Unit No."
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label for="street-one" class="font-size-16 font-weight-500"
                  >Street 1</label
                >
              </v-flex>
              <v-flex md8>
                <v-text-field
                  id="street-one"
                  v-model.trim="entity.street_1"
                  dense
                  filled
                  label="Street 1"
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label for="street-two" class="font-size-16 font-weight-500"
                  >Street 2</label
                >
              </v-flex>
              <v-flex md8>
                <v-text-field
                  id="street-two"
                  v-model.trim="entity.street_2"
                  dense
                  filled
                  label="Street 2"
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label for="landmark" class="font-size-16 font-weight-500"
                  >Landmark</label
                >
              </v-flex>
              <v-flex md8>
                <v-text-field
                  id="landmark"
                  v-model.trim="entity.landmark"
                  dense
                  filled
                  label="Landmark"
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label for="zip_code" class="font-size-16 font-weight-500"
                  >Postal Code</label
                >
              </v-flex>
              <v-flex md8>
                <v-text-field
                  id="zip_code"
                  v-model.trim="entity.zip_code"
                  dense
                  filled
                  label="Postal Code"
                  solo
                  flat
                  v-mask="'######'"
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label for="zip_code" class="font-size-16 font-weight-500"
                  >GST %</label
                >
              </v-flex>
              <v-flex md1>
                <v-checkbox
                  id="gst"
                  dense
                  hide-details
                  v-model="entity.gst"
                  :disabled="formLoading"
                  :true-value="1"
                  :false-value="0"
                  color="cyan"
                ></v-checkbox>
              </v-flex>
              <v-flex md7>
                <v-text-field
                  id="gstvalue"
                  v-model.trim="entity.gstvalue"
                  dense
                  filled
                  label="GST%"
                  solo
                  flat
                  v-mask="'######'"
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="onSubmit()"
          >Update
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="entity_dialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { GET, POST, PATCH } from "@/core/services/store/request.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { setCookie, getCookie } from "@/core/plugins/bt-cookie";

export default {
  mixins: [ValidationMixin],
  name: "profile-company-setting",
  data() {
    return {
      formValid: true,
      statusLoading: false,
      pageLoading: true,
      formLoading: false,
      entity_dialog: false,
      entities: [],
      entity: {
        id: null,
        uen_no: null,
        unit_no: null,
        name: null,
        contact_no: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        gst: 0,
      },
    };
  },
  methods: {
    reLoginRequired() {
      return !!+getCookie("entity_reload");
    },
    onSubmit() {
      const _this = this;

      if (!_this.$refs.entityForm.validate()) {
        return false;
      }

      let reqType = POST;
      let reqURL = "setting/entity";

      if (_this.entity.id) {
        reqType = PATCH;
        reqURL = `setting/entity/${_this.entity.id}`;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(reqType, { url: reqURL, data: _this.entity })
        .then(({ data }) => {
          _this.entities = data;
          _this.entity_dialog = false;
          setCookie({ key: "entity_reload", value: 1 });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    updateStatus(id) {
      const _this = this;
      _this.statusLoading = true;
      _this.$store
        .dispatch(PATCH, { url: `setting/entity/${id}/status` })
        .then(({ data }) => {
          _this.entities = data;
          setCookie({ key: "entity_reload", value: 1 });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.statusLoading = false;
        });
    },
    addEntity() {
      this.entity = new Object({
        id: null,
        uen_no: null,
        unit_no: null,
        name: null,
        contact_no: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        gst: 0,
      });
      this.entity_dialog = true;
    },
    updateEntity(row) {
      this.entity = new Object({
        id: row.id,
        uen_no: row.uen_no,
        unit_no: row.unit_no,
        name: row.name,
        contact_no: row.contact_no,
        street_1: row.street_1,
        street_2: row.street_2,
        landmark: row.landmark,
        zip_code: row.zip_code,
        gst: row.gst,
        gstvalue: row.gstvalue,
      });
      this.entity_dialog = true;
    },
    getEntitySetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, { url: "setting/entity" })
        .then(({ data }) => {
          _this.entities = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    PhoneTextField,
    ListingTable,
  },
  mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.getEntitySetting();
  },
};
</script>
