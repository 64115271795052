<template>
  <v-container fluid>
    <v-form
      ref="passwordForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col v-if="isOldPassword" md="6" class="py-0 pt-4" offset-md="3">
          <v-text-field
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            v-on:click:append="show1 = !show1"
            dense
            filled
            label="Old Password"
            solo
            flat
            color="cyan"
            v-model.trim="formData.old_password"
            :rules="[
              validateRules.required(formData.old_password, 'Old Password'),
            ]"
          ></v-text-field>
        </v-col>
        <v-col
          md="6"
          class="py-0"
          :class="{ 'pt-4': !isOldPassword }"
          offset-md="3"
        >
          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            v-on:click:append="show2 = !show2"
            dense
            filled
            label="New Password"
            solo
            flat
            color="cyan"
            hint="At least 8 characters"
            v-model.trim="formData.password"
            v-on:keypress="(e) => manageLimit(e)"
            @input="validatePassword($event)"
            :rules="[validateRules.required(formData.password, 'New Password')]"
          ></v-text-field>
          <!--  <p class="frmValidation" :class="{'frmValidation--passed' : formData.password.length > 7}"><i class="frmIcon fas" :class="formData.password.length > 7 ? 'fa-check' : 'fa-times'"></i> Longer than 7 characters</p> -->
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_min }"
          >
            <i
              class="frmIcon fas"
              :class="has_min ? 'fa-check' : 'fa-times'"
            ></i>
            The password must be at least 8 character
          </p>
          <!--  <p class="frmValidation" :class="{'frmValidation--passed' :has_max }"><i class="frmIcon fas" :class="has_max ? 'fa-check' : 'fa-times'"></i> The password must be 16 character</p> -->
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_uppercase }"
          >
            <i
              class="frmIcon fas"
              :class="has_uppercase ? 'fa-check' : 'fa-times'"
            ></i>
            Has a capital letter
          </p>
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_lowercase }"
          >
            <i
              class="frmIcon fas"
              :class="has_lowercase ? 'fa-check' : 'fa-times'"
            ></i>
            Has a lowercase letter
          </p>
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_number }"
          >
            <i
              class="frmIcon fas"
              :class="has_number ? 'fa-check' : 'fa-times'"
            ></i>
            Has a number
          </p>
          <p
            class="frmValidation"
            :class="{ 'frmValidation--passed': has_special }"
          >
            <i
              class="frmIcon fas"
              :class="has_special ? 'fa-check' : 'fa-times'"
            ></i>
            Has a special character
          </p>
        </v-col>
        <v-col md="6" class="py-0" offset-md="3">
          <v-text-field
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            v-on:click:append="show3 = !show3"
            dense
            filled
            label="Confirm Password"
            solo
            flat
            :readonly="disabledDate()"
            color="cyan"
            hint="At least 8 characters"
            v-model.trim="formData.password_confirmation"
            v-on:keypress="(e) => manageLimitCon(e)"
            :rules="[
              validateRules.required(
                formData.password_confirmation,
                'Confirm Password'
              ),
              validateRules.confirmPassword(
                formData.password_confirmation,
                'Confirm Password',
                formData.password
              ),
            ]"
          ></v-text-field>
        </v-col>
        <v-col class="text-right" md="6" offset-md="3">
          <v-btn
            :disabled="!formValid || formLoading || disabledDate()"
            :loading="formLoading"
            v-on:click="onSubmit"
            class="mx-2 custom-grey-border custom-bold-button text-white"
            color="cyan"
          >
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<style>
/*  body{background-color: #EFEFEF;}
        .container{width:400px; margin:50px auto; background: white; padding:10px; font-family: Arial, Helvetica, sans-serif, sans-serif; border-radius: 3px;}
        h1{ font-size: 24px; text-align: center; text-transform: uppercase;}
        .frmField{background-color:white; color:#495057; line-height: 1.25; font-size: 16px; font-family: 'Roboto', sans-serif; border:0; padding: 10px; border:1px solid #dbdbdb;  border-radius: 3px;}
        .frmLabel{display: block; margin-bottom: 10px; font-weight: bold;}*/
.frmValidation {
  font-size: 13px;
  margin-bottom: 6px !important;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}

.howToBuild {
  text-align: center;
  color: purple;
}
.howToBuild a {
  color: grey;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}
</style>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import { PATCH } from "@/core/services/store/request.module";
export default {
  name: "password-update",
  mixins: [ValidationMixin],
  props: {
    user: {
      type: Number,
      default: 0,
      required: true,
    },
    isOldPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      formValid: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      disabledData: true,
      has_min: false,
      formLoading: false,
      formData: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    validatePassword(e) {
      // let result = true;
      let inputVal =
        (this.formData.password ? String(this.formData.password) : "") +
        String(e ? e.key : "");
      let a = /[0-9]+/;
      let b = /[a-z]/;
      let c = /[A-Z]/;
      let d = /[!@#$%^&*)(+=._-]/;
      this.has_number = a.test(inputVal);
      this.has_lowercase = b.test(inputVal);
      this.has_uppercase = c.test(inputVal);
      if (e && e == e.toUpperCase()) {
        this.has_lowercase = false;
      }
      this.has_special = d.test(inputVal);
      if (this.formData.password && this.formData.password.length > 7) {
        this.has_min = true;
      } else {
        this.has_min = false;
      }

      if (
        this.has_number &&
        this.has_lowercase &&
        this.has_uppercase &&
        this.has_special &&
        this.has_min &&
        this.has_max
      ) {
        console.log("result");
        this.formValid = true;
        /*  this.disabledData = false; */
      } else {
        /*  this.disabledData = true; */
        this.formValid = false;
      }
    },

    manageLimit(e) {
      // this.disabledDate();
      if (this.formData.password && this.formData.password.length > 15) {
        e.preventDefault();
      }
    },
    disabledDate() {
      if (
        this.has_number &&
        this.has_lowercase &&
        this.has_uppercase &&
        this.has_special
      ) {
        return false;
      }
      return true;
    },
    manageLimitCon(e) {
      if (this.formData.password && this.formData.password.length > 15) {
        e.preventDefault();
      }
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.passwordForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      let url = "user/" + _this.user + "/password";

      if (_this.isOldPassword) {
        url = "user/reset-password";
      }

      _this.$store
        .dispatch(PATCH, {
          url,
          data: _this.formData,
        })
        .then(() => {
          _this.formValid = true;
          _this.formData = {
            old_password: null,
            password: null,
            password_confirmation: null,
          };
          (this.has_number = false),
            (this.has_lowercase = false),
            (this.has_uppercase = false),
            (this.has_special = false),
            (this.has_min = false),
            (this.has_max = false),
            _this.$emit("password:updated");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  computed: {},
};
</script>
