<template>
  <div class="line-item-detail-new">
    <v-row>
      <v-col v-if="selected_equipments.length" md="12">
        <table class="line-item-multiple-table" width="100%">
          <thead>
            <tr>
              <th class="text-left" width="55%">&nbsp;&nbsp;&nbsp;Equipment</th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Serial No.
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Model</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Location</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(line_item, index) in selected_equipments"
              :key="index"
              class="line-item-listing-row"
              :class="{ 'custom-border-top': index > 0 }"
            >
              <td valign="top" class="text-left" width="55%">
                <v-layout>
                  <v-flex md12>
                    <p class="m-0 p-2 custom-grey-border">
                      {{ line_item.product }}
                    </p>
                  </v-flex>
                  <v-flex class="my-auto">
                    <v-layout>
                      <v-flex>
                        <v-chip
                          small
                          label
                          class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :color="getProductTypeColor(line_item.product_type)"
                        >
                          {{ getProductType(line_item.product_type) }}
                        </v-chip>
                      </v-flex>
                      <v-flex v-if="line_item.visit_barcode">
                        <v-chip
                          small
                          label
                          class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :style="{
                            'background-color': '#7db00e',
                          }"
                        >
                          {{ line_item.visit_barcode }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout v-if="false">
                  <v-flex class="max-width-50px">
                    <v-text-field
                      dense
                      filled
                      solo
                      flat
                      v-mask="'A'"
                      class="min-width-unset max-width-50px"
                      color="cyan"
                      v-model="line_item.group"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Product/Service"
                      dense
                      filled
                      solo
                      flat
                      readonly
                      color="cyan"
                      v-model="line_item.product"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <p class="m-0 mt-3 p-2 custom-grey-border">
                  <template v-if="line_item.description">
                    <pre>{{ line_item.description }}</pre>
                  </template>
                  <template v-else
                    ><em class="text--secondary">No Description</em></template
                  >
                </p>
              </td>
              <td valign="top" class="text-left" width="15%">
                <p class="m-0 p-2 custom-grey-border">
                  <template v-if="line_item.serial_no">{{
                    line_item.serial_no
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Serial No.</em></template
                  >
                </p>
                <WarrantyManage
                  readonly
                  v-if="$hasWarranty && line_item.has_warranty"
                  :warranty="line_item.warranty"
                  v-model="line_item.warranty"
                ></WarrantyManage>
              </td>
              <td valign="top" class="text-left" width="15%">
                <p class="m-0 p-2 custom-grey-border">
                  <template v-if="line_item.eq_model">{{
                    line_item.eq_model
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Model</em></template
                  >
                </p>
              </td>
              <td valign="top" width="15%">
                <p class="m-0 p-2 custom-grey-border">
                  <template v-if="line_item.location">{{
                    line_item.location
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Location</em></template
                  >
                </p>
                <v-btn
                  icon
                  color="red lighten-1"
                  v-if="selected_equipments.length > 1"
                  v-on:click="deleteLineItem(line_item, index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="selected_line_items.length" md="12">
        <table class="line-item-multiple-table" width="100%" v-if="!isJob">
          <thead>
            <tr>
              <th class="text-left" width="55%">&nbsp;&nbsp;&nbsp;Service</th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
              </th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;<!--Project Price-->
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(line_item, index) in selected_line_items">
              <tr
                v-if="!line_item.is_optional"
                :key="index"
                class="line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left" width="55%">
                  <v-layout>
                    <v-flex md12>
                      <p class="m-0 p-2 custom-grey-border">
                        {{ line_item.product }}
                      </p>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :color="getProductTypeColor(line_item.product_type)"
                          >
                            {{ getProductType(line_item.product_type) }}
                          </v-chip>
                        </v-flex>
                        <v-flex v-if="line_item.visit_barcode">
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color': '#7db00e',
                            }"
                          >
                            {{ line_item.visit_barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false">
                    <v-flex class="max-width-50px">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-mask="'A'"
                        class="min-width-unset max-width-50px"
                        color="cyan"
                        v-model="line_item.group"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <p class="m-0 mt-3 p-2 custom-grey-border">
                    <template v-if="line_item.description">
                      <pre>{{ line_item.description }}</pre>
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Description</em></template
                    >
                  </p>
                  <div v-if="line_item.to_equipment" class="d-flex mt-2 ml-2">
                    <span> Added to Equipment </span>
                    <span class="ml-2 w-50">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        content="The product was added to the equipments of the customer."
                        v-tippy="{
                          arrow: true,
                          arrowType: 'round',
                          animation: 'fade',
                        }"
                        style="max-width: 20px"
                        :src="$assetURL('media/custom-svg/attention.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                  <p></p>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatMoneyWithCurrency(line_item.rate, currency) }}
                  </p>
                  <WarrantyManage
                    readonly
                    v-if="$hasWarranty && line_item.has_warranty"
                    :warranty="line_item.warranty"
                    v-model="line_item.warranty"
                  ></WarrantyManage>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <!-- <p class="m-0 p-2 custom-grey-border">
                    {{
                      formatMoneyWithCurrency(line_item.project_price, currency)
                    }}
                  </p> -->
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatNumber(line_item.quantity) }} {{ line_item.uom }}
                  </p>
                </td>
                <td valign="top" align="right" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatMoneyWithCurrency(line_item.total, currency) }}
                  </p>
                  <!-- <v-btn
                    icon
                    color="red lighten-1"
                    v-if="selected_line_items.length > 1"
                    v-on:click="deleteLineItem(line_item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </template>
            <tr style="background: #f3f6f9">
              <th class="text-left" width="55%">
                &nbsp;&nbsp;&nbsp;Optional Item
              </th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
              </th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Is Optional
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
            </tr>
            <template v-for="(line_item, index) in selected_line_items">
              <tr
                v-if="line_item.is_optional"
                :key="index"
                class="line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left" width="55%">
                  <v-layout>
                    <v-flex md12>
                      <p class="m-0 p-2 custom-grey-border">
                        {{ line_item.product }}
                      </p>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :color="getProductTypeColor(line_item.product_type)"
                          >
                            {{ getProductType(line_item.product_type) }}
                          </v-chip>
                        </v-flex>
                        <v-flex v-if="line_item.visit_barcode">
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color': '#7db00e',
                            }"
                          >
                            {{ line_item.visit_barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false">
                    <v-flex class="max-width-50px">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-mask="'A'"
                        class="min-width-unset max-width-50px"
                        color="cyan"
                        v-model="line_item.group"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <p class="m-0 mt-3 p-2 custom-grey-border">
                    <template v-if="line_item.description">
                      <pre>{{ line_item.description }}</pre>
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Description</em></template
                    >
                  </p>
                  <div v-if="line_item.to_equipment" class="d-flex mt-2 ml-2">
                    <span> Added to Equipment </span>
                    <span class="ml-2 w-50">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        content="The product was added to the equipments of the customer."
                        v-tippy="{
                          arrow: true,
                          arrowType: 'round',
                          animation: 'fade',
                        }"
                        style="max-width: 20px"
                        :src="$assetURL('media/custom-svg/attention.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                  <p></p>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatMoneyWithCurrency(line_item.rate, currency) }}
                  </p>
                  <WarrantyManage
                    readonly
                    v-if="$hasWarranty && line_item.has_warranty"
                    :warranty="line_item.warranty"
                    v-model="line_item.warranty"
                  ></WarrantyManage>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <!-- <p class="m-0 p-2 custom-grey-border">
                    {{
                      formatMoneyWithCurrency(line_item.project_price, currency)
                    }}
                  </p> -->
                  <template>
                    <v-checkbox
                      color="cyan"
                      v-model="line_item.is_optional"
                      class="mt-0 mb-2"
                      readonly
                    >
                      <!--   <template v-slot:label>
                        <span class="color-custom-blue font-weight-700"
                          >Is Optional ?</span
                        >
                      </template> -->
                    </v-checkbox>
                  </template>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatNumber(line_item.quantity) }} {{ line_item.uom }}
                  </p>
                </td>
                <td valign="top" align="right" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatMoneyWithCurrency(line_item.total, currency) }}
                  </p>
                  <!-- <v-btn
                    icon
                    color="red lighten-1"
                    v-if="selected_line_items.length > 1"
                    v-on:click="deleteLineItem(line_item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </template>

            <tr class="calculation-row">
              <td colspan="4"></td>
            </tr>
            <tr class="calculation-row">
              <td colspan="4" class="custom-border-top"></td>
            </tr>

            <tr class="calculation-row">
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Sub Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(sub_total, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>
            <tr class="calculation-row" v-if="discount">
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Discount [
                <template v-if="discount_value_type == 1">{{
                  formatMoneyWithCurrency(discount_value, currency)
                }}</template>
                <template v-else>{{ formatNumber(discount_value) }} %</template>
                ]
              </td>
              <td valign="middle" class="p-0"></td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(discount, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>
            <tr class="calculation-row" v-if="adjustment">
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Trade-In
              </td>
              <td valign="middle" class="p-0"></td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(adjustment, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>
            <tr class="calculation-row" v-if="apply_tax && total_tax">
              <td valign="middle"></td>
              <td
                valign="middle"
                colspan="2"
                class="font-size-16 py-3 font-weight-500"
              >
                <span class="d-flex align-center"
                  >Tax [GST {{ tax_value }}%]
                </span>
              </td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(total_tax, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>

            <tr class="calculation-row">
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Grand Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(grand_total, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="line-item-multiple-table" width="100%" v-if="isJob">
          <thead>
            <tr>
              <th class="text-left" width="55%">&nbsp;&nbsp;&nbsp;Service</th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
              </th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Project Price
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(line_item, index) in selected_line_items">
              <tr
                v-if="!line_item.is_optional"
                :key="index"
                class="line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left" width="55%">
                  <v-layout>
                    <v-flex md12>
                      <p class="m-0 p-2 custom-grey-border">
                        {{ line_item.product }}
                      </p>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :color="getProductTypeColor(line_item.product_type)"
                          >
                            {{ getProductType(line_item.product_type) }}
                          </v-chip>
                        </v-flex>
                        <v-flex v-if="line_item.visit_barcode">
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color': '#7db00e',
                            }"
                          >
                            {{ line_item.visit_barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false">
                    <v-flex class="max-width-50px">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-mask="'A'"
                        class="min-width-unset max-width-50px"
                        color="cyan"
                        v-model="line_item.group"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <p class="m-0 mt-3 p-2 custom-grey-border">
                    <template v-if="line_item.description">
                      <pre>{{ line_item.description }}</pre>
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Description</em></template
                    >
                  </p>
                  <div v-if="line_item.to_equipment" class="d-flex mt-2 ml-2">
                    <span> Added to Equipment </span>
                    <span class="ml-2 w-50">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        content="The product was added to the equipments of the customer."
                        v-tippy="{
                          arrow: true,
                          arrowType: 'round',
                          animation: 'fade',
                        }"
                        style="max-width: 20px"
                        :src="$assetURL('media/custom-svg/attention.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                  <p></p>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatMoneyWithCurrency(line_item.rate, currency) }}
                  </p>
                  <WarrantyManage
                    readonly
                    v-if="$hasWarranty && line_item.has_warranty"
                    :warranty="line_item.warranty"
                    v-model="line_item.warranty"
                  ></WarrantyManage>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{
                      formatMoneyWithCurrency(line_item.project_price, currency)
                    }}
                  </p>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatNumber(line_item.quantity) }} {{ line_item.uom }}
                  </p>
                </td>
                <td valign="top" align="right" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatMoneyWithCurrency(line_item.total, currency) }}
                  </p>
                  <!-- <v-btn
                    icon
                    color="red lighten-1"
                    v-if="selected_line_items.length > 1"
                    v-on:click="deleteLineItem(line_item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </template>
            <tr style="background: #f3f6f9">
              <th class="text-left" width="55%">
                &nbsp;&nbsp;&nbsp;Optional Item
              </th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
              </th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Project Price
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
            </tr>
            <template v-for="(line_item, index) in selected_line_items">
              <tr
                v-if="line_item.is_optional"
                :key="index"
                class="line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left" width="55%">
                  <v-layout>
                    <v-flex md12>
                      <p class="m-0 p-2 custom-grey-border">
                        {{ line_item.product }}
                      </p>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :color="getProductTypeColor(line_item.product_type)"
                          >
                            {{ getProductType(line_item.product_type) }}
                          </v-chip>
                        </v-flex>
                        <v-flex v-if="line_item.visit_barcode">
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color': '#7db00e',
                            }"
                          >
                            {{ line_item.visit_barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false">
                    <v-flex class="max-width-50px">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-mask="'A'"
                        class="min-width-unset max-width-50px"
                        color="cyan"
                        v-model="line_item.group"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <p class="m-0 mt-3 p-2 custom-grey-border">
                    <template v-if="line_item.description">
                      <pre>{{ line_item.description }}</pre>
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Description</em></template
                    >
                  </p>
                  <div v-if="line_item.to_equipment" class="d-flex mt-2 ml-2">
                    <span> Added to Equipment </span>
                    <span class="ml-2 w-50">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        content="The product was added to the equipments of the customer."
                        v-tippy="{
                          arrow: true,
                          arrowType: 'round',
                          animation: 'fade',
                        }"
                        style="max-width: 20px"
                        :src="$assetURL('media/custom-svg/attention.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                  <p></p>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatMoneyWithCurrency(line_item.rate, currency) }}
                  </p>
                  <WarrantyManage
                    readonly
                    v-if="$hasWarranty && line_item.has_warranty"
                    :warranty="line_item.warranty"
                    v-model="line_item.warranty"
                  ></WarrantyManage>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{
                      formatMoneyWithCurrency(line_item.project_price, currency)
                    }}
                  </p>
                  <template>
                    <v-checkbox
                      color="cyan"
                      v-model="line_item.is_optional"
                      class="mt-0 mb-2"
                      readonly
                    >
                      <template v-slot:label>
                        <span class="color-custom-blue font-weight-700"
                          >Is Optional ?</span
                        >
                      </template>
                    </v-checkbox>
                  </template>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatNumber(line_item.quantity) }} {{ line_item.uom }}
                  </p>
                </td>
                <td valign="top" align="right" width="15%">
                  <p class="m-0 p-2 custom-grey-border">
                    {{ formatMoneyWithCurrency(line_item.total, currency) }}
                  </p>
                  <!-- <v-btn
                    icon
                    color="red lighten-1"
                    v-if="selected_line_items.length > 1"
                    v-on:click="deleteLineItem(line_item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </template>

            <tr class="calculation-row">
              <td colspan="4"></td>
            </tr>
            <tr class="calculation-row">
              <td colspan="4" class="custom-border-top"></td>
            </tr>

            <tr class="calculation-row">
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Sub Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(sub_total, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>
            <tr class="calculation-row" v-if="discount">
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Discount [
                <template v-if="discount_value_type == 1">{{
                  formatMoneyWithCurrency(discount_value, currency)
                }}</template>
                <template v-else>{{ formatNumber(discount_value) }} %</template>
                ]
              </td>
              <td valign="middle" class="p-0"></td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(discount, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>
            <!-- <tr class="calculation-row" v-if="adjustment">
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Trade-In
              </td>
              <td valign="middle" class="p-0"></td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(adjustment, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr> -->
            <tr class="calculation-row" v-if="apply_tax && total_tax">
              <td valign="middle"></td>
              <td
                valign="middle"
                colspan="2"
                class="font-size-16 py-3 font-weight-500"
              >
                <span class="d-flex align-center"
                  >Tax [GST {{ tax_value }}%]
                </span>
              </td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(total_tax, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>

            <tr class="calculation-row">
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Grand Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                <p class="m-0 p-2 custom-grey-border">
                  {{
                    formatMoneyWithCurrency(grand_total, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <DeleteDialog :delete-dialog="delete_line_item_dialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ delete_line_item.product }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ delete_line_item.product }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="delete_line_item_dialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          color="red lighten-1 white--text"
          v-on:click="deleteLineItemConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
  </div>
</template>
<script>
import { DELETE } from "@/core/services/store/request.module";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import WarrantyManage from "@/view/pages/partials/Warranty-Manage";
import { toNumber, sum, round, toSafeInteger } from "lodash";

export default {
  mixins: [CommonFunctionMixin],
  data() {
    return {
      selected_line_items: [],
      equipments: [],
      selected_equipments: [],
      apply_tax: false,
      sub_total: 0,
      discount: 0,
      total_tax: 0,
      tax_value: 0,
      adjustment: 0,
      grand_total: 0,
      discount_value: 0,
      discount_value_type: 1,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      delete_loading: false,
      delete_line_item_dialog: false,
      delete_line_item: {},
    };
  },
  props: {
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isInvoice: {
      type: Boolean,
      default: false,
    },
    isJob: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dbLineItems: {
      type: Array,
      default() {
        return [];
      },
    },
    dbEquipments: {
      type: Array,
      default() {
        return [];
      },
    },
    propertyId: {
      type: Number,
      default: 0,
    },
    discountValue: {
      type: Number,
      default: 0,
    },
    discountValueType: {
      type: Number,
      default: 0,
    },
    taxValue: {
      type: Number,
      default: 0,
    },
    applyTax: {
      type: Number,
      default: 0,
    },
    adjustmentValue: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: "SGD",
    },
  },
  watch: {
    dbLineItems: {
      deep: true,
      immediate: true,
      handler() {
        this.initUpdate();
      },
    },
    dbEquipments: {
      deep: true,
      immediate: true,
      handler() {
        this.initUpdate();
      },
    },
  },
  methods: {
    getProductTypeColor(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "cyan darken-1 white--text";
          break;
        case "service":
          result = "orange darken-1 white--text";
          break;
        case "equipment":
          result = "green darken-1 white--text";
          break;
      }
      return result;
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Service";
          break;
        case "equipment":
          result = "Equipment";
          break;
      }
      return result;
    },
    initUpdate() {
      this.selected_line_items = [];
      this.selected_equipments = [];

      if (this.dbLineItems.length) {
        for (let i = 0; i < this.dbLineItems.length; i++) {
          this.selected_line_items.push({
            status: true,
            id: this.dbLineItems[i].id,
            visit_barcode: this.dbLineItems[i].visit_barcode,
            group: this.dbLineItems[i].group,
            group_primary: this.dbLineItems[i].group_primary,
            product: this.dbLineItems[i].product,
            product_id: this.dbLineItems[i].product_id,
            serial_no: this.dbLineItems[i].serial_no,
            eq_model: this.dbLineItems[i].eq_model,
            location: this.dbLineItems[i].location,
            product_type: this.dbLineItems[i].product_type,
            has_warranty: this.dbLineItems[i].has_warranty,
            to_equipment: this.dbLineItems[i].to_equipment,
            warranty: this.dbLineItems[i].warranty,
            description: this.dbLineItems[i].description,
            rate: this.dbLineItems[i].rate,
            threshold_price: this.dbLineItems[i].threshold_price,
            project_price: this.dbLineItems[i].project_price,
            is_optional: this.dbLineItems[i].is_optional,

            quantity: this.dbLineItems[i].quantity,
            uom: this.dbLineItems[i].uom,
            total: this.dbLineItems[i].total,
          });
          this.$nextTick(() => {
            this.calculateTotal(i, "selected-line-item");
          });
        }
      }

      if (this.dbEquipments.length) {
        for (let z = 0; z < this.dbEquipments.length; z++) {
          this.selected_equipments.push({
            status: true,
            id: this.dbEquipments[z].id,
            visit_barcode: this.dbEquipments[z].visit_barcode,
            group: this.dbEquipments[z].group,
            group_primary: this.dbEquipments[z].group_primary,
            product: this.dbEquipments[z].product,
            product_id: this.dbEquipments[z].product_id,
            serial_no: this.dbEquipments[z].serial_no,
            eq_model: this.dbEquipments[z].eq_model,
            location: this.dbEquipments[z].location,
            product_type: this.dbEquipments[z].product_type,
            has_warranty: this.dbEquipments[z].has_warranty,
            to_equipment: this.dbEquipments[z].to_equipment,
            warranty: this.dbEquipments[z].warranty,
            description: this.dbEquipments[z].description,
            rate: this.dbEquipments[z].rate,
            project_price: this.dbLineItems[z].project_price,
            is_optional: this.dbLineItems[z].is_optional,
            quantity: this.dbEquipments[z].quantity,
            uom: this.dbEquipments[z].uom,
            total: this.dbEquipments[z].total,
          });
        }
      }

      this.apply_tax = this.applyTax;
      this.adjustment = this.adjustmentValue;
      this.discount_value = this.discountValue;
      this.discount_value_type = this.discountValueType;
      this.tax_value = this.taxValue;
      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },
    deleteLineItem(row, index) {
      if (row.id) {
        this.delete_line_item = row;
        this.delete_line_item_dialog = true;
      } else {
        if (row.product_type == "equipment") {
          this.selected_equipments.splice(index, 1);
        } else {
          this.selected_line_items.splice(index, 1);
        }
      }
    },
    deleteLineItemConfirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, { url: `line-item/${this.delete_line_item.id}` })
        .then(() => {
          this.$emit("load:content", true);
          this.delete_line_item = {};
          this.delete_line_item_dialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    calculateGrandTotal() {
      const item_total = [];

      for (let i = 0; i < this.selected_line_items.length; i++) {
        if (this.selected_line_items[i].is_optional) {
          continue;
        }
        const { rate, quantity } = this.selected_line_items[i];
        const total = round(toNumber(rate), 2) * round(toNumber(quantity), 2);
        item_total.push(total);
      }

      let sub_total = sum(item_total);

      this.sub_total = round(toNumber(sub_total), 2);

      if (this.discount_value_type == 1) {
        this.discount = round(toNumber(this.discount_value), 2);
      }

      if (this.discount_value_type == 2) {
        this.discount =
          (round(toNumber(sub_total), 2) *
            round(toNumber(this.discount_value), 2)) /
          100;
      }

      sub_total = sub_total - round(toNumber(this.discount), 2);
      sub_total = sub_total - round(toNumber(this.adjustment), 2);
      if (this.apply_tax) {
        //this.total_tax = (round(toNumber(sub_total), 2) * 7) / 100;
        this.total_tax = (round(toNumber(sub_total), 2) * this.tax_value) / 100;
      } else {
        this.total_tax = 0;
      }

      sub_total = sub_total + round(toNumber(this.total_tax), 2);

      this.grand_total = round(toNumber(sub_total), 2);

      this.$nextTick(() => {
        const calculations = {
          apply_tax: toSafeInteger(this.apply_tax),
          adjustment: round(toNumber(this.adjustment), 2),
          discount_value: round(toNumber(this.discount_value), 2),
          discount_value_type: toSafeInteger(this.discount_value_type),
        };

        if (!this.showDetail) {
          this.$emit("update:line-item", this.selected_line_items);
          this.$emit("update:equipment", this.selected_equipments);
          this.$emit("update:line-item-calculation", calculations);
        }
      });
    },
    calculateTotal(index, type) {
      let item = this.selected_line_items[index];

      if (item) {
        const total = toNumber(item.rate) * toNumber(item.quantity);
        this.selected_line_items[index].total = total;
      }

      if (type == "selected-line-item") {
        this.$nextTick(() => {
          this.calculateGrandTotal();
        });
      }
    },
  },
  components: {
    WarrantyManage,
    DeleteDialog,
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
