<template>
  <Dialog :common-dialog="qtyQialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex class="d-flex align-center">
          Update Door Type - {{ qtyObject.product }} ({{ totalQty }}/
          <template v-if="qtyObject.quantity">
            {{ qtyObject.quantity }}
          </template>
          <template v-else> 0 </template>
          )
        </v-flex>
        <v-flex
          class="text-right"
          style="min-width: 400px"
        >
          <div class="d-flex justify-end align-center">
            <div class="mr-4">
              <v-checkbox
                :input-value="showBreakdown"
                v-on:change="updateBreakdown"
                depressed
                hide-details
                label="Show Breakdown"
                color="cyan"
              />
              <v-checkbox
                v-if="showBreakdownModel"
                :input-value="hideValue"
                v-on:change="$emit('updateHideValue', $event)"
                depressed
                hide-details
                label="Hide Values"
                color="cyan"
              />
            </div>
            <div class="d-flex align-center">
              <v-btn
                style="height: 32px !important"
                class="custom-bold-button mx-2"
                depressed
                :disabled="disabledQty"
                color="cyan white--text"
                v-on:click="saveQty()"
                >Save</v-btn
              >
              <v-btn
                style="height: 32px !important"
                class="custom-bold-button"
                depressed
                v-on:click="$emit('close', true)"
                >Close</v-btn
              >
            </div>
          </div>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div class="custom-grey-border">
        <table width="100%" style="table-layout: fixed">
          <thead>
            <tr>
              <th
                class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
              >
                Size <span style="text-transform: none">(width X height)</span>
              </th>
              <th
                class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="16%"
              >
                Location
              </th>
              <th
                class="text-left font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="16%"
              >
                Serial Number
              </th>
              <th
                class="text-left font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="14%"
              >
                Quantity
              </th>
              <th
                class="text-left font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="14%"
              >
                Unit Cost
              </th>
              <th
                v-if="quoteType == 1"
                class="text-left font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="14%"
              >
                Total Cost
              </th>
              <th
                class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="5%"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in qtyArray"
              :key="`door-type-qty-row-${index}`"
              class="alternate-listing-row custom-border-top"
            >
              <td>
                <div class="d-flex align-center justify-center">
                  <div class="w-100">
                    <v-text-field
                      dense
                      filled
                      placeholder="Enter width"
                      v-model="row.width"
                      solo
                      suffix="mmW"
                      flat
                      :readonly="!isCustomDoorType && quoteType == 2"
                      @change="updateSize(index)"
                      color="cyan"
                    />
                  </div>
                  <div class="mx-2">
                    <v-icon>mdi-close</v-icon>
                  </div>
                  <div class="w-100">
                    <v-text-field
                      dense
                      filled
                      placeholder="Enter height"
                      v-model="row.height"
                      suffix="mmH"
                      solo
                      flat
                      :readonly="!isCustomDoorType && quoteType == 2"
                      @change="updateSize(index)"
                      color="cyan"
                    />
                  </div>
                </div>
              </td>
              <td>
                <v-text-field
                  dense
                  filled
                  placeholder="Enter location"
                  v-model="row.location"
                  solo
                  flat
                  :readonly="!isCustomDoorType && quoteType == 2"
                  color="cyan"
                />
              </td>
              <td>
                <v-text-field
                  dense
                  filled
                  :ref="`field${index}SerialNoRef`"
                  placeholder="Enter Serial Number"
                  solo
                  flat
                  v-model="row.serial_no"
                  maxlength="10"
                  color="cyan"
                />
              </td>
              <td>
                <v-text-field
                  dense
                  filled
                  placeholder="Enter Quantity"
                  solo
                  flat
                  :ref="`field${index}QtyRef`"
                  v-model="row.qty"
                  color="cyan"
                  :maxlength="6"
                  type="number"
                  v-on:keyup="calculateTotal(index)"
                  v-on:keypress="limitDecimal($event, row.qty)"
                />
              </td>
              <td>
                <v-text-field
                  dense
                  filled
                  placeholder="Enter Unit Cost"
                  solo
                  flat
                  v-model="row.price"
                  color="cyan"
                  :maxlength="6"
                  type="number"
                  v-on:keyup="calculateTotal(index)"
                  v-on:keydown.tab="pushQtyRow(index)"
                  v-on:keypress="limitDecimal($event, row.price)"
                />
              </td>
              <td v-if="quoteType == 1">
                <v-text-field
                  dense
                  filled
                  placeholder="Total Cost"
                  solo
                  flat
                  v-model="row.total"
                  color="cyan"
                  type="number"
                  readonly
                />
              </td>
              <td align="right">
                <v-btn
                  class="custom-bold-button"
                  depressed
                  icon
                  v-on:click="deleteQtyRow(index)"
                  color="red lighten-1 white--text"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-slot:action>
      <div v-if="isCustomDoorType || quoteType == 1">
        <v-btn
          style="height: 28px !important"
          class="custom-bold-button"
          depressed
          :disabled="disabledQty"
          v-on:click="addQtyRow()"
          color="cyan white--text"
          >+ Add Row</v-btn
        >
      </div>
    </template>
  </Dialog>
</template>

<script>
import { toSafeNumber, safeAdd, safeMultiply } from "@/core/lib/math.lib";
import CommonMixin from "@/core/plugins/common-mixin.js";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { ErrorEventBus, ClearEventBus } from "@/core/lib/message.lib";
import { cloneDeep } from "lodash";

export default {
  name: "line-item-door-type",
  props: {
    showBreakdown: {
      type: Boolean,
      default: true,
    },
    hideValue: {
      type: Boolean,
      default: false,
    },
    qtyQialog: {
      type: Boolean,
      default: false,
    },
    qtyArray: {
      type: Array,
      default() {
        return [];
      },
    },
    qtyObject: {
      type: Object,
      default() {
        return {};
      },
    },
    defObject: {
      type: Object,
      default() {
        return {};
      },
    },
    quoteType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showBreakdownModel: true,
    }
  },  
  mixins: [CommonMixin],
  methods: {
    updateBreakdown(status) {
      this.showBreakdownModel = status
      this.$emit('updateBreakdown', status)
      if(!status){
        this.$emit('updateHideValue', status)
      }
    },
    pushQtyRow(index) {
      const nextIndex = index + 1;
      if (nextIndex >= this.qtyArray.length) {
        this.addQtyRow();
      }
    },
    addQtyRow() {
      if (this.disabledQty) {
        return false;
      }

      const def_object = cloneDeep(this.defObject);

      if(this.isCustomDoorType){
        def_object.serial_no = 'NA';
      }
      
      this.qtyArray.push(def_object);
    },
    updateSize(index) {
      this.qtyArray[
        index
      ].size = `${this.qtyArray[index].width} X ${this.qtyArray[index].height}`;
    },
    saveQty() {
      ClearEventBus.$emit("clear:error");

      if (this.blankSizeIndex > -1) {
        if (this.quoteType == 2) {
          this.$refs[`field${this.blankSizeIndex}SerialNoRef`][0].$refs[
            "input"
          ].focus();
          ErrorEventBus.$emit("update:error", "Please Serial Number");
          return false;
        } else {
          this.$refs[`field${this.blankSizeIndex}QtyRef`][0].$refs[
            "input"
          ].focus();
          ErrorEventBus.$emit("update:error", "Please Quantity");
          return false;
        }
      }

      if (
        toSafeNumber(this.totalQty) != toSafeNumber(this.qtyObject.quantity)
      ) {
        ErrorEventBus.$emit(
          "update:error",
          `Sum of quantity must be ${this.qtyObject.quantity}`
        );
        return false;
      }

      this.$emit("save", cloneDeep(this.qtyArray));
    },
    calculateTotal(index) {
      this.qtyArray[index].total = safeMultiply(
        this.qtyArray[index].price,
        this.qtyArray[index].qty
      );
    },
    deleteQtyRow(index) {
      this.qtyArray.splice(index, 1);
      if (this.qtyArray.length < 1) {
        this.addQtyRow();
      }
    },
  },
  components: {
    Dialog,
  },
  computed: {
    isCustomDoorType() {
      return this.qtyObject?.is_custom_door_type || false
    },
    blankSizeIndex() {
      if (this.quoteType == 2) {
        return this.qtyArray.findIndex(
          (item) => item.serial_no == null || item.serial_no == ""
        );
      }
      return this.qtyArray.findIndex(
        (item) => item.qty == null || item.qty == ""
      );
    },
    totalQty() {
      return this.qtyArray.reduce(
        (acc, row) => safeAdd(acc, row.qty == null ? 0 : toSafeNumber(row.qty)),
        0
      );
    },
    disabledQty() {
      return (
        toSafeNumber(this.totalQty) > toSafeNumber(this.qtyObject.quantity)
      );
    },
    dialogWidth() {
      return toSafeNumber((document.body.clientWidth / 100) * 90);
    },
  },
};
</script>
