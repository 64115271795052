<template>
  <v-navigation-drawer
    v-model="isDrawer"
    app
    bottom
    temporary
    stateless
    right
    :width="dialogWith"
    style="z-index: 100"
    class="notification-drawer"
  >
    <v-card text elevation="0">
      <div class="dialog-custom-header header-sticky">
        <v-card-title class="border-bottom-grey">
          <span
            class="text-uppercase custom-header-blue-text"
            style="font-size: 18px"
          >
            Notification
          </span>
          <v-badge
            v-if="notification_count"
            color="green"
            :content="notification_count"
            inline
            style="font-size: 15px"
          >
          </v-badge>
          <v-spacer></v-spacer>
          <v-btn class="mr-3" icon small @click="updateDialogWith()">
            <v-icon size="30">mdi-fullscreen-exit</v-icon>
          </v-btn>
          <v-btn icon small @click="$emit('close', false)">
            <v-icon size="30">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-layout class="py-2 px-5">
          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            offset-y
            z-index="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                depressed
                color="blue darken-4"
                text
              >
                <v-icon left>mdi-filter-outline</v-icon>
                Filters
              </v-btn>
            </template>

            <v-card width="250">
              <div class="px-5 py-2 text-h6">
                Filter By
                <div class="mt-2">
                  <v-text-field
                    class="rounded"
                    outlined
                    hide-details
                    placeholder="Search..."
                    v-model="searchString"
                    dense
                    filled
                    :maxlength="250"
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </div>
              </div>
              <v-list class="pt-0">
                <template v-for="row in filterModuleList">
                  <v-list-item :key="row.text">
                    <v-list-item-icon size="35" class="mr-0 my-2">
                      <v-checkbox
                        dense
                        hide-details
                        v-model="row.check"
                        class="m-0"
                      ></v-checkbox>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase">{{
                        row.text
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>

              <v-divider class="my-0"></v-divider>

              <v-card-actions class="pa-5">
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  outlined
                  @click="
                    filterMenu = false;
                    resetFilter();
                  "
                >
                  Clear
                </v-btn>
                <v-btn
                  depressed
                  color="blue darken-4"
                  class="white--text"
                  @click="
                    filterMenu = false;
                    getNotification();
                  "
                >
                  Apply Filter
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
          <template v-if="isFilter()">
            <v-btn
              min-width="30"
              height="26"
              text
              color="red lighten-1"
              class="white--text ml-2"
              @click="resetFilter()"
            >
              <span class="text-capitalize"> clear Filter </span>
            </v-btn>
          </template>
          <v-spacer></v-spacer>
          <template
            v-if="
              Array.isArray(selectedNotification) && selectedNotification.length
            "
          >
            <v-btn
              min-width="30"
              height="26"
              text
              color="red lighten-1"
              class="white--text mr-2"
              @click="selectedNotification = []"
            >
              <span class="text-capitalize"> clear selection </span>
            </v-btn>
            <v-btn
              min-width="30"
              width="30"
              height="26"
              small
              outlined
              color="red lighten-1"
              class="white--text mr-2"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn
              depressed
              color="blue darken-4"
              class="white--text"
              @click="markAsRead"
              :loading="loading"
            >
              Mark as read
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              depressed
              color="blue darken-4"
              class="white--text"
              @click="markAllAsRead"
              :loading="loading"
            >
              Mark all as read
            </v-btn>
          </template>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                class="ml-2 custom-bold-button white--text"
                color="blue darken-4"
                tile
                :disabled="loading"
                v-on:click="getNotification"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Reload</span>
          </v-tooltip>
        </v-layout>
      </div>

      <v-card-text class="px-3 py-3">
        <template v-if="Array.isArray(notifications) && notifications.length">
          <div v-for="(row, index) in notifications" :key="index + 'data'">
            <div class="text-center pb-3" v-if="row.is_divider">
              <div class="border-bottom">
                <strong
                  style="
                    position: relative;
                    top: 9.5px;
                    background: #fff;
                    padding: 2px 12px;
                    font-size: 16px;
                  "
                >
                  {{ getdate(row.date) }}
                </strong>
              </div>
            </div>
            <div
              v-else
              class="d-flex px-2 py-1 mb-2 rounded"
              :class="{ 'grey lighten-4': !row.is_marked }"
            >
              <div class="" style="width: 40px">
                <v-checkbox
                  v-if="!row.is_marked"
                  dense
                  hide-details
                  class="m-0"
                  v-model="selectedNotification"
                  :value="row.id"
                  multiple
                ></v-checkbox>
              </div>

              <div
                class="flex-grow-1"
                style="font-size: 1rem"
                v-on:click="pushToRoute(row)"
              >
                <b class="font-weight-700">{{ row.message }}</b>
                <div>
                  <span style="color: red; font-weight: 500">{{
                    row.title
                  }}</span>
                  <template v-if="row.module == 'visit'">
                    <span style="color: black"> has a pending follow up </span>
                  </template>
                  <template v-else>
                    <span style="color: black"> has a pending approval </span>
                  </template>
                </div>
                <div>
                  <em>{{ row.datetime }}</em>
                </div>
              </div>
              <v-spacer v-on:click="pushToRoute(row)"> </v-spacer>
              <div v-on:click="pushToRoute(row)">
                <template v-if="row.module == 'visit'">
                  <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                  </span>
                </template>
                <template v-else>
                  <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                  </span>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center text-h4 my-10">
            <v-icon size="100">mdi-bell-off-outline</v-icon>
            <div class="py-5">No Notification</div>
          </div>
        </template>

        <!-- <v-btn
          v-if="total_page > current_page"
          depressed
          tile
          block
          color="cyan"
          class="text-white float-right mb-4 d-block"
          v-on:click="getNotification"
          :disabled="pageScrollLoading"
          :loading="pageScrollLoading"
        >
          <v-icon left> mdi-eye </v-icon>
          Load More
        </v-btn> -->
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { QUERY, PATCH } from "@/core/services/store/request.module";
import {
  SET_MESSAGE /* SET_ERROR */,
  SET_NOTIFICATION_COUNT,
} from "@/core/services/store/common.module";
import { NotificationReloadEventBus } from "@/core/lib/notification.reload.lib";

export default {
  name: "NotificationDrawer",
  data() {
    return {
      total_page: 0,
      current_page: 1,
      isDrawer: false,
      dialogWith: "40%",
      filterMenu: false,
      selectedNotification: [],
      searchString: "",
      filterModuleList: [
        {
          text: "Visit",
          value: "visit",
          check: false,
        },
        {
          text: "Quotation",
          value: "quotation",
          check: false,
        },
      ],
      notification_count: 0,
      scrollTimeOut: null,
      pageScrollLoading: false,
      notifications: [],
      loading: true,
    };
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    drawer(param) {
      this.isDrawer = param;
      if (param) {
        this.getNotification();
      }
    },
  },
  methods: {
    pushToRoute(item) {
      if (item.route) {
        this.$router.push(
          this.getDefaultRoute(item.route.name, {
            params: item.route.params,
          })
        );
      }
    },
    getdate(date) {
      if (date) {
        // Convert the provided date to a Date object
        const inputDate = new Date(date);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        // Remove time components for an accurate date-only comparison
        const inputDateString = inputDate.toDateString();
        const todayString = today.toDateString();
        const yesterdayString = yesterday.toDateString();

        if (inputDateString === todayString) {
          return "Today";
        } else if (inputDateString === yesterdayString) {
          return "Yesterday";
        } else {
          return date;
        }
      }
    },
    isFilter() {
      if (this.searchString) {
        return true;
      }
      const selectedModule = this.filterModuleList.filter((row) => {
        return row.check;
      });
      if (selectedModule.length) {
        return true;
      }
      return false;
    },
    resetFilter() {
      this.searchString = null;
      const _module = this.filterModuleList.map((row) => {
        return { ...row, check: false };
      });
      this.filterModuleList = _module;
      this.getNotification();
    },

    getNotification() {
      this.loading = true;
      this.pageScrollLoading = true;
      const selectedModule = this.filterModuleList
        .filter((row) => {
          return row.check;
        })
        .map((row) => row.value)
        .join(",");

      this.$store
        .dispatch(QUERY, {
          url: `all-notifications`,
          data: {
            status: "all",
            search: this.searchString,
            module: selectedModule,
            current_page: this.current_page + 1,
            per_page: 20,
          },
        })
        .then(({ data }) => {
          this.current_page = data.current_page;
          this.notification_count = data.unread_rows;
          this.$store.commit(SET_NOTIFICATION_COUNT, this.notification_count);
          this.total_page = data.total_page;
          const _data = data?.rows.map((row) => {
            return {
              id: row.id,
              is_divider: row.is_divider,
              date: row.date,
              message: row?.data?.tooltip,
              description: row?.data?.desc,
              title: row?.data?.title,
              user: row?.data?.user,
              is_marked: row.is_marked,
              module: row.module,
              route: row?.data?.route,
              added_by: 0,
              datetime: row.added_at,
              user_name: "Super Admin",
              profile_img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
              added_at: row.added_at,
            };
          });
          this.notifications = _data;
        })
        .catch((error) => {
          console.log({
            error,
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.pageScrollLoading = false;
            this.loading = false;
          }, 1000);
        });
    },
    markAllAsRead() {
      this.selectedNotification = this.notifications
        .filter((row) => row.id)
        .map((row) => row.id);
      if (this.selectedNotification.length) {
        this.markAsRead();
      }
    },
    markAsRead() {
      this.loading = true;
      this.$store
        .dispatch(PATCH, {
          url: `all-notifications/bulk-action`,
          data: {
            action: "mark_as_read",
            selected: this.selectedNotification,
          },
        })
        .then(() => {
          this.$store.commit(SET_MESSAGE, [
            {
              model: true,
              message: `Success ! Notification successfully updated.`,
            },
          ]);
          this.selectedNotification = [];
          this.getNotification();
        })
        .catch((error) => {
          console.log({
            error,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateDialogWith() {
      if (this.dialogWith == "80%") {
        this.dialogWith = "760";
      } else {
        this.dialogWith = "80%";
      }
    },
  },
  mounted() {
    const _this = this;
    _this.getNotification();
    NotificationReloadEventBus.$on("notification:reload", function () {
      _this.getNotification();
    });
  },
};
</script>
<style>
.header-sticky {
  position: sticky;
  top: 0px;
  background: #fff;
  border-top: 8px solid #f57c00 !important;
  border-radius: 0 !important;
  z-index: 1;
}
.border-bottom-grey {
  border-bottom: 1px solid lightgrey;
}
.scroll-page-loading {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  background: white;
  width: 100%;
}
</style>
