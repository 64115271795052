<template>
  <v-container
    fluid
    v-if="isActive && getPermission('attachment:view')"
    class="custom-border-grey-dashed p-4 mt-1"
  >
    <div class="d-none">
      <v-text-field
        type="hidden"
        v-model="isUploading"
        :rules="[validateRules.required(!isUploading)]"
      ></v-text-field>
    </div>
    <v-row :class="{ 'pointer-events-none': isUploading }">
      <template
        v-if="
          lodash.isEmpty(documentArray) == false &&
          lodash.isArray(documentArray)
        "
      >
        <template v-for="(document, index) in documentArray">
          <v-col
            v-if="lodash.isEmpty(document.file) === false"
            class="custom-mime-template py-0 mb-5"
            :key="index"
            :class="{ 'custom-border-right': documentArray[index + 1] }"
          >
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <div class="custom-mime-content" v-bind="attrs" v-on="on">
                    <template v-if="isValidImage(document.file.path)">
                      <img
                        width="100%"
                        :src="document.file.url"
                        :alt="document.file.name"
                      />
                      <!-- <template >
                            <div class="primary_label">Primary</div>
                          </template> -->
                    </template>
                    <template v-else>
                      <span class="svg-icon svg-icon-lg custom-mime-icon mr-4">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          :src="
                            $assetURL(
                              'media/mime/' +
                                getFileExtension(document.file.path) +
                                '.svg'
                            )
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </template>
                    <div v-if="hover" class="custom-mime-action text-center">
                      <template v-if="getPermission('attachment:delete')">
                        <v-btn
                          depressed
                          v-if="allowDelete"
                          class="mx-2 custom-bold-button"
                          color="red lighten-1"
                          outlined
                          small
                          :loading="isDeleting(index)"
                          icon
                          v-on:click="
                            deleteAPIFile(document.id, index, documentArray)
                          "
                          ><v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-if="getPermission('attachment:view')">
                        <v-btn
                          depressed
                          v-if="allowDownload"
                          class="mx-2 custom-bold-button"
                          color="cyan"
                          outlined
                          small
                          :loading="isDownloading(index)"
                          icon
                          v-on:click="
                            downloadAPIFile(
                              document.id,
                              document.file.name,
                              index
                            )
                          "
                          ><v-icon>mdi-download</v-icon>
                        </v-btn>
                      </template>
                    </div>
                  </div>
                </v-hover>
              </template>
              <span>{{ document.file.name }}</span>
            </v-tooltip>
          </v-col>
        </template>
      </template>
      <template v-if="getPermission('attachment:create')">
        <v-col
          v-if="allowUpload && documentArray.length < uploadCountLimit"
          :md="documentArray.length > 0 ? 3 : 12"
          class="py-0"
        >
          <div
            style="height: 100%"
            class="d-flex justify-center text-center custom-grey-border"
            v-if="documentArray.length < 1"
          >
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey lighten-1"
              indeterminate
              class="my-6"
              v-if="isUploading"
            ></v-progress-circular>
            <p
              v-else
              class="p-2 my-auto font-size-16 font-weight-500 py-6 cursor-pointer"
              v-on:click="$refs.attachment.$refs.input.click()"
            >
              Images<br /><span>
                Allowed JPG, GIF, PNG. <br />
                Max size of 5 MB
              </span>
              Click here to select
            </p>
          </div>
          <div class="d-none">
            <v-file-input
              ref="attachment"
              v-on:change="uploadFile"
              :accept="acceptFile"
            ></v-file-input>
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
  
  <script>
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [FileManagerMixin, ValidationMixin],
  props: {
    attachments: {
      type: Array,
      default() {
        return new Array();
      },
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    fileType: {
      type: Boolean,
      default: false,
    },
    acceptFile: {
      type: String,
      default: null,
    },
    moduleType: {
      type: String,
      default: null,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
    allowUpload: {
      type: Boolean,
      default: false,
    },
    isMinDisplay: {
      type: Boolean,
      default: false,
    },
    maxLimit: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      uploadCountLimit: 5,
      uploadSizeLimit: 5 * 1024 * 1024, // 10 MB
      isUploading: false,
      documentArray: [],
    };
  },
  watch: {
    documentArray: {
      deep: true,
      immediate: true,
      handler(param) {
        this.updateFiles(param);
      },
    },
    attachments: {
      deep: true,
      immediate: true,
      handler(param) {
        let a = this.lodash.cloneDeep(this.attachments);
        let b = this.lodash.cloneDeep(this.documentArray);
        if (this.lodash.isEqual(a, b) === false) {
          this.documentArray = param;
        }
      },
    },
  },
  mounted() {
    if (this.lodash.isEmpty(this.attachments) === false) {
      this.documentArray = this.attachments;
    }
  },
  methods: {
    isValidImage(file_url) {
      try {
        const _this = this;
        let validImageExtension = ["png", "jpg", "jpeg"];
        let fileExtention = _this.lodash.last(file_url.split("."));
        return _this.lodash.includes(
          validImageExtension,
          fileExtention.toLowerCase()
        );
      } catch (error) {
        this.logError(error);
        return false;
      }
    },
    updateFiles(param) {
      let filesArray = [];
      for (var i = 0; i < param.length; i++) {
        if (this.lodash.isEmpty(param[i]) === false) {
          filesArray.push(param[i].id);
        }
      }
      if (this.lodash.isEmpty(filesArray) === false) {
        this.$emit("file:updated", filesArray);
      }
    },
    uploadFile(file) {
      if (file && file.type && this.fileType) {
        let fileExtention = file.type.split("/");
        fileExtention = fileExtention[0];
        if (fileExtention != "image") {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Only image allowed")
          );
          return false;
        }
      }

      this.isUploading = true;
      this.uploadAttachment(file)
        .then((response) => {
          for (let i = response.length - 1; i >= 0; i--) {
            this.documentArray.push(response[i]);
          }
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
  },
  computed: {
    isActive() {
      return (
        this.allowUpload || this.lodash.isEmpty(this.documentArray) === false
      );
    },
  },
};
</script>
  
  <style scoped>
.primary_label {
  position: absolute;
  overflow: hidden;
  top: 7%;
  background-color: #00bcd4;
  color: #fff;
  font-size: 15px;
  padding: 3px 20px;
  text-align: center;
  transform: rotate(45deg);
  right: -24px;
}
.custom-mime-content img {
  height: 130px;
  object-fit: cover;
}
</style>
  