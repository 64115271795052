<template>
  <div>
    <v-layout v-if="canUpdate">
      <v-flex md6>
        <v-btn-toggle v-model="product_type" color="cyan" group>
          <v-btn
            v-if="false && !isJob"
            color="cyan"
            :disabled="searching || loading"
            class="custom-bold-button"
            v-on:click="openDialog('goods', 'Product')"
            value="goods"
          >
            <template v-if="showDetail">Add New</template> Product
          </v-btn>
          <v-btn
            color="cyan"
            :disabled="searching || loading"
            class="custom-bold-button mx-2"
            v-on:click="openDialog('service', 'Service')"
            value="service"
          >
            <template v-if="showDetail">Add New</template> Service
          </v-btn>
          <v-btn
            v-if="false"
            color="cyan"
            :disabled="searching || loading"
            class="custom-bold-button"
            v-on:click="openDialog('equipment', 'Equipment')"
            value="equipment"
          >
            <template v-if="showDetail">Add New</template> Equipment
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-flex md6 class="text-right">
        <v-btn
          :disabled="loading"
          v-if="showDetail && save_enabled"
          v-on:click="updateLineItem()"
          small
          depressed
          color="cyan white--text custom-bold-button"
          >Save</v-btn
        >
      </v-flex>
    </v-layout>
    <v-row>
      <v-col v-if="false" md="12">
        <table class="line-item-multiple-table" width="100%">
          <thead>
            <tr>
              <th class="text-left" width="55%">&nbsp;&nbsp;&nbsp;Equipment</th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Serial No.
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Model</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Location</th>
            </tr>
          </thead>
          <tbody v-if="selected_equipments.length">
            <tr
              v-for="(line_item, index) in selected_equipments"
              :key="index"
              class="alternate-listing-row line-item-listing-row"
              :class="{ 'custom-border-top': index > 0 }"
            >
              <td valign="top" class="text-left" width="55%">
                <v-layout>
                  <v-flex md12>
                    <v-text-field
                      label="Equipment"
                      dense
                      filled
                      solo
                      flat
                      readonly
                      color="cyan"
                      v-model="line_item.product"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="my-auto">
                    <v-layout>
                      <v-flex>
                        <v-chip
                          small
                          label
                          class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :color="getProductTypeColor(line_item.product_type)"
                        >
                          {{ getProductType(line_item.product_type) }}
                        </v-chip>
                      </v-flex>
                      <v-flex v-if="line_item.visit_barcode">
                        <v-chip
                          small
                          label
                          class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :style="{
                            'background-color': '#7db00e',
                          }"
                        >
                          {{ line_item.visit_barcode }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout v-if="false">
                  <v-flex class="max-width-50px">
                    <v-text-field
                      dense
                      filled
                      solo
                      flat
                      v-mask="'A'"
                      class="min-width-unset max-width-50px"
                      color="cyan"
                      v-model="line_item.group"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Product/Service"
                      dense
                      filled
                      solo
                      flat
                      readonly
                      color="cyan"
                      v-model="line_item.product"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-textarea
                  auto-grow
                  dense
                  filled
                  flat
                  v-model="line_item.description"
                  label="Description"
                  solo
                  :readonly="!canUpdate"
                  color="cyan"
                  hide-details
                  row-height="10"
                  v-on:change="save_enabled = true"
                ></v-textarea>
              </td>
              <td valign="top" class="text-left" width="15%">
                <v-text-field
                  label="Serial No."
                  dense
                  filled
                  solo
                  flat
                  readonly
                  v-on:change="save_enabled = true"
                  color="cyan"
                  v-model="line_item.serial_no"
                ></v-text-field>
                <WarrantyManage
                  :readonly="!canUpdate"
                  v-on:change="save_enabled = true"
                  v-if="$hasWarranty && line_item.has_warranty && false"
                  :warranty="line_item.warranty"
                  v-model="line_item.warranty"
                ></WarrantyManage>
              </td>
              <td valign="top" class="text-left" width="15%">
                <v-text-field
                  label="Model"
                  dense
                  filled
                  solo
                  flat
                  readonly
                  color="cyan"
                  v-on:change="save_enabled = true"
                  v-model="line_item.eq_model"
                ></v-text-field>
              </td>
              <td valign="top" align="right" width="15%">
                <v-text-field
                  label="Location"
                  dense
                  filled
                  solo
                  flat
                  readonly
                  color="cyan"
                  v-on:change="save_enabled = true"
                  v-model="line_item.location"
                ></v-text-field>
                <v-btn
                  icon
                  color="red lighten-1"
                  v-if="
                    canUpdate &&
                    selected_equipments.length + selected_line_items.length > 1
                  "
                  v-on:click="deleteLineItem(line_item, index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tfoot v-else>
            <tr>
              <td colspan="4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no equipment at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </v-col>
      <v-col v-if="!isJob" md="12">
        <table class="line-item-multiple-table" width="100%">
          <thead>
            <tr>
              <th class="text-left" width="3%">&nbsp;&nbsp;&nbsp;#</th>
              <th class="text-left" width="55%">&nbsp;&nbsp;&nbsp;Service</th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
              </th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;<!-- Project Price1 -->
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
            </tr>
          </thead>

          <Draggable
            v-if="selected_line_items.length"
            tag="tbody"
            v-model="selected_line_items"
            class="draggable-group"
            handle=".draggable-drag-icon"
            @end="emitData"
          >
            <template v-for="(line_item, index) in selected_line_items">
              <tr
                v-if="!line_item.is_optional"
                :key="index"
                class="alternate-listing-row line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left" width="3%">
                  <v-icon
                    class="draggable-drag-icon cursor-move"
                    color="blue darken-4"
                    >mdi-drag</v-icon
                  >
                </td>
                <td valign="top" class="text-left" width="55%">
                  <v-layout>
                    <v-flex md12>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :color="getProductTypeColor(line_item.product_type)"
                          >
                            {{ getProductType(line_item.product_type) }}
                          </v-chip>
                        </v-flex>
                        <v-flex v-if="line_item.visit_barcode">
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color': '#7db00e',
                            }"
                          >
                            {{ line_item.visit_barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false">
                    <v-flex class="max-width-50px">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-mask="'A'"
                        class="min-width-unset max-width-50px"
                        color="cyan"
                        v-model="line_item.group"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-textarea
                    auto-grow
                    dense
                    filled
                    flat
                    v-model="line_item.description"
                    label="Description"
                    solo
                    :readonly="!canUpdate"
                    color="cyan"
                    hide-details
                    row-height="10"
                    v-on:change="save_enabled = true"
                  ></v-textarea>

                  <template v-if="!isCreditNote && !line_item.id">
                    <v-layout
                      v-if="!showDetail && line_item.product_type == 'goods'"
                      class="mt-2 justify-center align-center"
                    >
                      <v-flex v-if="false" class="w-100">
                        <v-checkbox
                          inset
                          v-model="line_item.to_equipment"
                          color="cyan"
                          dense
                          class="pt-0 mt-0"
                          hide-details
                          :false-value="0"
                          :true-value="1"
                        >
                          <template v-slot:label>
                            <span> Add to Equipment </span>
                            <span class="ml-2 w-50">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                content="Once you enable, The product will be added to equipments of customer."
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                                style="max-width: 20px"
                                :src="
                                  $assetURL('media/custom-svg/attention.svg')
                                "
                              />
                              <!--end::Svg Icon-->
                            </span>
                          </template>
                        </v-checkbox>
                      </v-flex>
                    </v-layout>
                  </template>
                </td>

                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Rate"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    type="number"
                    color="cyan"
                    v-model="line_item.rate"
                    v-model.trim.number.lazy="line_item.rate"
                    v-on:keypress="limitDecimal($event, line_item.rate)"
                  ></v-text-field>
                  <WarrantyManage
                    :readonly="!canUpdate"
                    v-on:change="save_enabled = true"
                    v-if="$hasWarranty && line_item.has_warranty && false"
                    :warranty="line_item.warranty"
                    v-model="line_item.warranty"
                  ></WarrantyManage>
                  <template v-if="false">
                    <v-text-field
                      label="Rate"
                      dense
                      filled
                      solo
                      v-on:change="calculateTotal(index, 'line-item')"
                      :disabled="searching"
                      flat
                      type="number"
                      color="cyan"
                      v-model="line_item.rate"
                      v-model.trim.number.lazy="line_item.rate"
                      v-on:keypress="limitDecimal($event, line_item.rate)"
                    ></v-text-field>

                    <WarrantyManage
                      :readonly="!canUpdate"
                      v-on:change="save_enabled = true"
                      v-if="$hasWarranty && line_item.has_warranty && false"
                      :warranty="line_item.warranty"
                      v-model="line_item.warranty"
                    ></WarrantyManage>
                  </template>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <!-- <v-text-field
                    label="Project Price"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    type="number"
                    color="cyan"
                    v-model="line_item.project_price"
                    v-model.trim.number.lazy="line_item.project_price"
                    v-on:keypress="
                      limitDecimal($event, line_item.project_price)
                    "
                  ></v-text-field> -->
                </td>
                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Quantity"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    type="number"
                    color="cyan"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    :suffix="line_item.uom"
                    v-model="line_item.quantity"
                    v-mask="'####'"
                  ></v-text-field>
                </td>
                <td valign="top" align="right" width="15%">
                  <v-text-field
                    label="Total"
                    dense
                    filled
                    solo
                    color="cyan"
                    flat
                    type="number"
                    readonly
                    v-model="line_item.total"
                  ></v-text-field>
                  <v-btn
                    icon
                    color="red lighten-1"
                    v-if="canUpdate && selected_line_items.length > 1"
                    v-on:click="deleteLineItem(line_item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="6"></td>
            </tr>
            <tr>
              <td colspan="6" class="custom-border-top"></td>
            </tr>
            <template v-if="false">
              <tr>
                <td valign="middle" colspan="3"></td>
                <td valign="middle" class="font-size-16 py-3 font-weight-500">
                  Sub Total
                </td>
                <td valign="middle"></td>
                <td valign="middle" align="right">
                  {{
                    formatMoneyWithCurrency(sub_total, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr v-if="!isJob">
                <td valign="middle" colspan="3"></td>
                <td valign="middle" class="font-size-16 py-3 font-weight-500">
                  Discount
                </td>
                <td valign="middle" class="p-0">
                  <v-layout>
                    <v-flex md8>
                      <v-text-field
                        label="Discount"
                        dense
                        filled
                        solo
                        color="cyan"
                        flat
                        :readonly="!canUpdate"
                        type="number"
                        v-on:keyup="
                          validateDiscount();
                          calculateGrandTotal();
                        "
                        v-on:change="
                          save_enabled = true;
                          validateDiscount();
                          calculateGrandTotal();
                        "
                        v-model.trim.number.lazy="discount_value"
                        v-on:keypress="limitDecimal($event, discount_value)"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-select
                        :items="discount_type_list"
                        v-model.trim="discount_value_type"
                        dense
                        filled
                        flat
                        hide-details
                        solo
                        :readonly="!canUpdate"
                        item-text="type"
                        item-value="value"
                        color="cyan"
                        item-color="cyan"
                        v-on:change="
                          save_enabled = true;
                          validateDiscount();
                          calculateGrandTotal();
                        "
                        class="discount-append-outer"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </td>
                <td valign="middle" align="right">
                  {{
                    formatMoneyWithCurrency(discount, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr v-if="isGSTAllowed()">
                <td valign="middle" colspan="3"></td>
                <td
                  valign="middle"
                  colspan="2"
                  class="font-size-16 py-3 font-weight-500"
                >
                  <span class="d-flex align-center"
                    >Tax [GST12 {{ gstValue }}%]
                    <v-checkbox
                      id="apply-tax"
                      color="cyan"
                      :disabled="!canUpdate"
                      class="ml-4 m-0 p-0"
                      v-model="apply_tax"
                      v-on:change="
                        save_enabled = true;
                        calculateGrandTotal();
                      "
                      hide-details
                    ></v-checkbox>
                  </span>
                </td>
                <td valign="middle" align="right">
                  {{
                    formatMoneyWithCurrency(total_tax, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr v-if="!isJob">
                <td valign="middle"></td>
                <td valign="middle" class="font-size-16 py-3 font-weight-500">
                  Trade-In
                </td>
                <td valign="middle" class="p-0">
                  <v-text-field
                    label="Trade-In"
                    dense
                    filled
                    solo
                    :readonly="!canUpdate"
                    flat
                    type="number"
                    color="cyan"
                    v-on:keyup="calculateGrandTotal()"
                    v-on:change="
                      save_enabled = true;
                      calculateGrandTotal();
                    "
                    v-model.trim.number.lazy="adjustment"
                    v-on:keypress="limitDecimal($event, adjustment)"
                  ></v-text-field>
                </td>
                <td valign="middle" align="right">
                  {{ formatMoney(adjustment) }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr>
                <td valign="middle" colspan="3"></td>
                <td valign="middle" class="font-size-16 py-3 font-weight-500">
                  Grand Total
                </td>
                <td valign="middle"></td>
                <td valign="middle" align="right">
                  {{
                    formatMoneyWithCurrency(grand_total, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
            </template>
          </Draggable>
          <tr style="background: #f3f6f9">
            <th class="text-left" width="3%">&nbsp;&nbsp;&nbsp;#</th>
            <th class="text-left" width="55%">
              &nbsp;&nbsp;&nbsp;Optional Items
            </th>
            <th class="text-left" width="15%">
              &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
            </th>
            <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Is Optional</th>
            <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
            <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
          </tr>
          <Draggable
            v-if="selected_line_items.length"
            tag="tbody"
            v-model="selected_line_items"
            class="draggable-group"
            handle=".draggable-drag-icon"
            @end="emitData"
          >
            <template v-for="(line_item, index) in selected_line_items">
              <tr
                v-if="line_item.is_optional"
                :key="index"
                class="alternate-listing-row line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left" width="3%">
                  <v-icon
                    class="draggable-drag-icon cursor-move"
                    color="blue darken-4"
                    >mdi-drag</v-icon
                  >
                </td>
                <td valign="top" class="text-left" width="55%">
                  <v-layout>
                    <v-flex md12>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :color="getProductTypeColor(line_item.product_type)"
                          >
                            {{ getProductType(line_item.product_type) }}
                          </v-chip>
                        </v-flex>
                        <v-flex v-if="line_item.visit_barcode">
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color': '#7db00e',
                            }"
                          >
                            {{ line_item.visit_barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false">
                    <v-flex class="max-width-50px">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-mask="'A'"
                        class="min-width-unset max-width-50px"
                        color="cyan"
                        v-model="line_item.group"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-textarea
                    auto-grow
                    dense
                    filled
                    flat
                    v-model="line_item.description"
                    label="Description"
                    solo
                    :readonly="!canUpdate"
                    color="cyan"
                    hide-details
                    row-height="10"
                    v-on:change="save_enabled = true"
                  ></v-textarea>

                  <template v-if="!isCreditNote && !line_item.id">
                    <v-layout
                      v-if="!showDetail && line_item.product_type == 'goods'"
                      class="mt-2 justify-center align-center"
                    >
                      <v-flex v-if="false" class="w-100">
                        <v-checkbox
                          inset
                          v-model="line_item.to_equipment"
                          color="cyan"
                          dense
                          class="pt-0 mt-0"
                          hide-details
                          :false-value="0"
                          :true-value="1"
                        >
                          <template v-slot:label>
                            <span> Add to Equipment </span>
                            <span class="ml-2 w-50">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                content="Once you enable, The product will be added to equipments of customer."
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                                style="max-width: 20px"
                                :src="
                                  $assetURL('media/custom-svg/attention.svg')
                                "
                              />
                              <!--end::Svg Icon-->
                            </span>
                          </template>
                        </v-checkbox>
                      </v-flex>
                    </v-layout>
                  </template>
                </td>

                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Rate"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    type="number"
                    color="cyan"
                    v-model="line_item.rate"
                    v-model.trim.number.lazy="line_item.rate"
                    v-on:keypress="limitDecimal($event, line_item.rate)"
                  ></v-text-field>
                  <WarrantyManage
                    :readonly="!canUpdate"
                    v-on:change="save_enabled = true"
                    v-if="$hasWarranty && line_item.has_warranty && false"
                    :warranty="line_item.warranty"
                    v-model="line_item.warranty"
                  ></WarrantyManage>
                  <template v-if="false">
                    <v-text-field
                      label="Rate"
                      dense
                      filled
                      solo
                      v-on:change="calculateTotal(index, 'line-item')"
                      :disabled="searching"
                      flat
                      type="number"
                      color="cyan"
                      v-model="line_item.rate"
                      v-model.trim.number.lazy="line_item.rate"
                      v-on:keypress="limitDecimal($event, line_item.rate)"
                    ></v-text-field>

                    <WarrantyManage
                      :readonly="!canUpdate"
                      v-on:change="save_enabled = true"
                      v-if="$hasWarranty && line_item.has_warranty && false"
                      :warranty="line_item.warranty"
                      v-model="line_item.warranty"
                    ></WarrantyManage>
                  </template>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <!--  <v-text-field
                    label="Project Price"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    type="number"
                    color="cyan"
                    v-model="line_item.project_price"
                    v-model.trim.number.lazy="line_item.project_price"
                    v-on:keypress="
                      limitDecimal($event, line_item.project_price)
                    "
                  ></v-text-field> -->
                  <template>
                    <v-checkbox
                      color="cyan"
                      v-model="line_item.is_optional"
                      class="mt-0 mb-2"
                      v-on:change="calculateTotal(index, 'selected-line-item')"
                    >
                      <template v-slot:label>
                        <span class="color-custom-blue font-weight-700"
                          ><!-- Is Optional ? --></span
                        >
                      </template>
                    </v-checkbox>
                  </template>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Quantity"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    type="number"
                    color="cyan"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    :suffix="line_item.uom"
                    v-model="line_item.quantity"
                    v-mask="'####'"
                  ></v-text-field>
                </td>
                <td valign="top" align="right" width="15%">
                  <v-text-field
                    label="Total"
                    dense
                    filled
                    solo
                    color="cyan"
                    flat
                    type="number"
                    readonly
                    v-model="line_item.total"
                  ></v-text-field>
                  <v-btn
                    icon
                    color="red lighten-1"
                    v-if="canUpdate && selected_line_items.length > 1"
                    v-on:click="deleteLineItem(line_item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <!--  <tr v-if="!line_item.is_optional" :key="index">
                  <td colspan="5" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no product at the moment.
                    </p>
                  </td>
                </tr> -->
            </template>

            <tr>
              <td colspan="6"></td>
            </tr>
            <tr>
              <td colspan="6" class="custom-border-top"></td>
            </tr>
            <tr>
              <td valign="middle" colspan="3"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Sub Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{
                  formatMoneyWithCurrency(sub_total, currency)
                }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr v-if="!isJob">
              <td valign="middle" colspan="3"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Discount
              </td>
              <td valign="middle" class="p-0">
                <v-layout>
                  <v-flex md8>
                    <v-text-field
                      label="Discount"
                      dense
                      filled
                      solo
                      color="cyan"
                      flat
                      :readonly="!canUpdate"
                      type="number"
                      v-on:keyup="
                        validateDiscount();
                        calculateGrandTotal();
                      "
                      v-on:change="
                        save_enabled = true;
                        validateDiscount();
                        calculateGrandTotal();
                      "
                      v-model.trim.number.lazy="discount_value"
                      v-on:keypress="limitDecimal($event, discount_value)"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex md10>
                    <v-select
                      :items="discount_type_list"
                      v-model.trim="discount_value_type"
                      dense
                      filled
                      flat
                      hide-details
                      solo
                      :readonly="!canUpdate"
                      item-text="type"
                      item-value="value"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="
                        save_enabled = true;
                        validateDiscount();
                        calculateGrandTotal();
                      "
                      class="discount-append-outer"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </td>
              <td valign="middle" align="right">
                {{
                  formatMoneyWithCurrency(discount, currency)
                }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr v-if="!isJob">
              <td valign="middle" colspan="3"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Trade-In
              </td>
              <td valign="middle" class="p-0">
                <v-text-field
                  label="Trade-In"
                  dense
                  filled
                  solo
                  :readonly="!canUpdate"
                  flat
                  type="number"
                  color="cyan"
                  v-on:keyup="calculateGrandTotal()"
                  v-on:change="
                    save_enabled = true;
                    calculateGrandTotal();
                  "
                  v-model.trim.number.lazy="adjustment"
                  v-on:keypress="limitDecimal($event, adjustment)"
                ></v-text-field>
              </td>
              <td valign="middle" align="right">
                {{ formatMoney(adjustment) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr v-if="isGSTAllowed()">
              <td valign="middle" colspan="3"></td>
              <td
                valign="middle"
                colspan="2"
                class="font-size-16 py-3 font-weight-500"
              >
                <span class="d-flex align-center"
                  >Tax [GST {{ gstValue }}%]
                  <v-checkbox
                    id="apply-tax"
                    color="cyan"
                    :disabled="!canUpdate"
                    class="ml-4 m-0 p-0"
                    v-model="apply_tax"
                    v-on:change="
                      save_enabled = true;
                      calculateGrandTotal();
                    "
                    hide-details
                  ></v-checkbox>
                </span>
              </td>
              <td valign="middle" align="right">
                {{
                  formatMoneyWithCurrency(total_tax, currency)
                }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>

            <tr>
              <td valign="middle" colspan="3"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Grand Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{
                  formatMoneyWithCurrency(grand_total, currency)
                }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
          </Draggable>

          <tfoot v-else>
            <tr>
              <td colspan="6">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no service at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </v-col>

      <v-col v-if="!isContract && !isQuotation && !isInvoice" md="12">
        <table class="line-item-multiple-table" width="100%">
          <thead>
            <tr>
              <th class="text-left" width="3%">&nbsp;&nbsp;&nbsp;#</th>
              <th class="text-left" width="55%">&nbsp;&nbsp;&nbsp;Service</th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
              </th>
              <th class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;<!-- Project Price -->
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
            </tr>
          </thead>

          <Draggable
            v-if="selected_line_items.length"
            tag="tbody"
            v-model="selected_line_items"
            class="draggable-group"
            handle=".draggable-drag-icon"
            @end="emitData"
          >
            <template v-for="(line_item, index) in selected_line_items">
              <tr
                v-if="!line_item.is_optional"
                :key="index"
                class="alternate-listing-row line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left" width="3%">
                  <v-icon
                    class="draggable-drag-icon cursor-move"
                    color="blue darken-4"
                    >mdi-drag</v-icon
                  >
                </td>
                <td valign="top" class="text-left" width="55%">
                  <v-layout>
                    <v-flex md12>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :color="getProductTypeColor(line_item.product_type)"
                          >
                            {{ getProductType(line_item.product_type) }}
                          </v-chip>
                        </v-flex>
                        <v-flex v-if="line_item.visit_barcode">
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color': '#7db00e',
                            }"
                          >
                            {{ line_item.visit_barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false">
                    <v-flex class="max-width-50px">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-mask="'A'"
                        class="min-width-unset max-width-50px"
                        color="cyan"
                        v-model="line_item.group"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-textarea
                    auto-grow
                    dense
                    filled
                    flat
                    v-model="line_item.description"
                    label="Description"
                    solo
                    :readonly="!canUpdate"
                    color="cyan"
                    hide-details
                    row-height="10"
                    v-on:change="save_enabled = true"
                  ></v-textarea>

                  <template v-if="!isCreditNote && !line_item.id">
                    <v-layout
                      v-if="!showDetail && line_item.product_type == 'goods'"
                      class="mt-2 justify-center align-center"
                    >
                      <v-flex v-if="false" class="w-100">
                        <v-checkbox
                          inset
                          v-model="line_item.to_equipment"
                          color="cyan"
                          dense
                          class="pt-0 mt-0"
                          hide-details
                          :false-value="0"
                          :true-value="1"
                        >
                          <template v-slot:label>
                            <span> Add to Equipment </span>
                            <span class="ml-2 w-50">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                content="Once you enable, The product will be added to equipments of customer."
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                                style="max-width: 20px"
                                :src="
                                  $assetURL('media/custom-svg/attention.svg')
                                "
                              />
                              <!--end::Svg Icon-->
                            </span>
                          </template>
                        </v-checkbox>
                      </v-flex>
                    </v-layout>
                  </template>
                </td>

                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Rate"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    type="number"
                    color="cyan"
                    v-model="line_item.rate"
                    v-model.trim.number.lazy="line_item.rate"
                    v-on:keypress="limitDecimal($event, line_item.rate)"
                  ></v-text-field>
                  <WarrantyManage
                    :readonly="!canUpdate"
                    v-on:change="save_enabled = true"
                    v-if="$hasWarranty && line_item.has_warranty && false"
                    :warranty="line_item.warranty"
                    v-model="line_item.warranty"
                  ></WarrantyManage>
                  <template v-if="false">
                    <v-text-field
                      label="Rate"
                      dense
                      filled
                      solo
                      v-on:change="calculateTotal(index, 'line-item')"
                      :disabled="searching"
                      flat
                      type="number"
                      color="cyan"
                      v-model="line_item.rate"
                      v-model.trim.number.lazy="line_item.rate"
                      v-on:keypress="limitDecimal($event, line_item.rate)"
                    ></v-text-field>

                    <WarrantyManage
                      :readonly="!canUpdate"
                      v-on:change="save_enabled = true"
                      v-if="$hasWarranty && line_item.has_warranty && false"
                      :warranty="line_item.warranty"
                      v-model="line_item.warranty"
                    ></WarrantyManage>
                  </template>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <!--   <v-text-field
                    label="Project Price"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    type="number"
                    color="cyan"
                    v-model="line_item.project_price"
                    v-model.trim.number.lazy="line_item.project_price"
                    v-on:keypress="
                      limitDecimal($event, line_item.project_price)
                    "
                  ></v-text-field> -->
                </td>
                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Quantity"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    type="number"
                    color="cyan"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    :suffix="line_item.uom"
                    v-model="line_item.quantity"
                    v-mask="'####'"
                  ></v-text-field>
                </td>
                <td valign="top" align="right" width="15%">
                  <v-text-field
                    label="Total"
                    dense
                    filled
                    solo
                    color="cyan"
                    flat
                    type="number"
                    readonly
                    v-model="line_item.total"
                  ></v-text-field>
                  <v-btn
                    icon
                    color="red lighten-1"
                    v-if="canUpdate && selected_line_items.length > 1"
                    v-on:click="deleteLineItem(line_item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="6"></td>
            </tr>
            <tr>
              <td colspan="6" class="custom-border-top"></td>
            </tr>
            <template v-if="false">
              <tr>
                <td valign="middle" colspan="3"></td>
                <td valign="middle" class="font-size-16 py-3 font-weight-500">
                  Sub Total
                </td>
                <td valign="middle"></td>
                <td valign="middle" align="right">
                  {{
                    formatMoneyWithCurrency(sub_total, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr>
                <td valign="middle" colspan="3"></td>
                <td valign="middle" class="font-size-16 py-3 font-weight-500">
                  Discount
                </td>
                <td valign="middle" class="p-0">
                  <v-layout>
                    <v-flex md8>
                      <v-text-field
                        label="Discount"
                        dense
                        filled
                        solo
                        color="cyan"
                        flat
                        :readonly="!canUpdate"
                        type="number"
                        v-on:keyup="
                          validateDiscount();
                          calculateGrandTotal();
                        "
                        v-on:change="
                          save_enabled = true;
                          validateDiscount();
                          calculateGrandTotal();
                        "
                        v-model.trim.number.lazy="discount_value"
                        v-on:keypress="limitDecimal($event, discount_value)"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-select
                        :items="discount_type_list"
                        v-model.trim="discount_value_type"
                        dense
                        filled
                        flat
                        hide-details
                        solo
                        :readonly="!canUpdate"
                        item-text="type"
                        item-value="value"
                        color="cyan"
                        item-color="cyan"
                        v-on:change="
                          save_enabled = true;
                          validateDiscount();
                          calculateGrandTotal();
                        "
                        class="discount-append-outer"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </td>
                <td valign="middle" align="right">
                  {{
                    formatMoneyWithCurrency(discount, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr v-if="isGSTAllowed()">
                <td valign="middle" colspan="3"></td>
                <td
                  valign="middle"
                  colspan="2"
                  class="font-size-16 py-3 font-weight-500"
                >
                  <span class="d-flex align-center"
                    >Taxfddd [GST {{ gstValue }}%]
                    <v-checkbox
                      id="apply-tax"
                      color="cyan"
                      :disabled="!canUpdate"
                      class="ml-4 m-0 p-0"
                      v-model="apply_tax"
                      v-on:change="
                        save_enabled = true;
                        calculateGrandTotal();
                      "
                      hide-details
                    ></v-checkbox>
                  </span>
                </td>
                <td valign="middle" align="right">
                  {{
                    formatMoneyWithCurrency(total_tax, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr>
                <td valign="middle"></td>
                <td valign="middle" class="font-size-16 py-3 font-weight-500">
                  Trade-In
                </td>
                <td valign="middle" class="p-0">
                  <v-text-field
                    label="Trade-In"
                    dense
                    filled
                    solo
                    :readonly="!canUpdate"
                    flat
                    type="number"
                    color="cyan"
                    v-on:keyup="calculateGrandTotal()"
                    v-on:change="
                      save_enabled = true;
                      calculateGrandTotal();
                    "
                    v-model.trim.number.lazy="adjustment"
                    v-on:keypress="limitDecimal($event, adjustment)"
                  ></v-text-field>
                </td>
                <td valign="middle" align="right">
                  {{ formatMoney(adjustment) }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
              <tr>
                <td valign="middle" colspan="3"></td>
                <td valign="middle" class="font-size-16 py-3 font-weight-500">
                  Grand Total
                </td>
                <td valign="middle"></td>
                <td valign="middle" align="right">
                  {{
                    formatMoneyWithCurrency(grand_total, currency)
                  }}&nbsp;&nbsp;&nbsp;
                </td>
              </tr>
            </template>
          </Draggable>
          <tr style="background: #f3f6f9">
            <th class="text-left" width="3%">&nbsp;&nbsp;&nbsp;#</th>
            <th class="text-left" width="55%">
              &nbsp;&nbsp;&nbsp;Optional Items
            </th>
            <th class="text-left" width="15%">
              &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
            </th>
            <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Is Optional</th>
            <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
            <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
          </tr>
          <Draggable
            v-if="selected_line_items.length"
            tag="tbody"
            v-model="selected_line_items"
            class="draggable-group"
            handle=".draggable-drag-icon"
            @end="emitData"
          >
            <template v-for="(line_item, index) in selected_line_items">
              <tr
                v-if="line_item.is_optional"
                :key="index"
                class="alternate-listing-row line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left" width="3%">
                  <v-icon
                    class="draggable-drag-icon cursor-move"
                    color="blue darken-4"
                    >mdi-drag</v-icon
                  >
                </td>
                <td valign="top" class="text-left" width="55%">
                  <v-layout>
                    <v-flex md12>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="my-auto">
                      <v-layout>
                        <v-flex>
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :color="getProductTypeColor(line_item.product_type)"
                          >
                            {{ getProductType(line_item.product_type) }}
                          </v-chip>
                        </v-flex>
                        <v-flex v-if="line_item.visit_barcode">
                          <v-chip
                            small
                            label
                            class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color': '#7db00e',
                            }"
                          >
                            {{ line_item.visit_barcode }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false">
                    <v-flex class="max-width-50px">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-mask="'A'"
                        class="min-width-unset max-width-50px"
                        color="cyan"
                        v-model="line_item.group"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Product/Service"
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="line_item.product"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-textarea
                    auto-grow
                    dense
                    filled
                    flat
                    v-model="line_item.description"
                    label="Description"
                    solo
                    :readonly="!canUpdate"
                    color="cyan"
                    hide-details
                    row-height="10"
                    v-on:change="save_enabled = true"
                  ></v-textarea>

                  <template v-if="!isCreditNote && !line_item.id">
                    <v-layout
                      v-if="!showDetail && line_item.product_type == 'goods'"
                      class="mt-2 justify-center align-center"
                    >
                      <v-flex v-if="false" class="w-100">
                        <v-checkbox
                          inset
                          v-model="line_item.to_equipment"
                          color="cyan"
                          dense
                          class="pt-0 mt-0"
                          hide-details
                          :false-value="0"
                          :true-value="1"
                        >
                          <template v-slot:label>
                            <span> Add to Equipment </span>
                            <span class="ml-2 w-50">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                content="Once you enable, The product will be added to equipments of customer."
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                                style="max-width: 20px"
                                :src="
                                  $assetURL('media/custom-svg/attention.svg')
                                "
                              />
                              <!--end::Svg Icon-->
                            </span>
                          </template>
                        </v-checkbox>
                      </v-flex>
                    </v-layout>
                  </template>
                </td>

                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Rate"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    type="number"
                    color="cyan"
                    v-model="line_item.rate"
                    v-model.trim.number.lazy="line_item.rate"
                    v-on:keypress="limitDecimal($event, line_item.rate)"
                  ></v-text-field>
                  <WarrantyManage
                    :readonly="!canUpdate"
                    v-on:change="save_enabled = true"
                    v-if="$hasWarranty && line_item.has_warranty && false"
                    :warranty="line_item.warranty"
                    v-model="line_item.warranty"
                  ></WarrantyManage>
                  <template v-if="false">
                    <v-text-field
                      label="Rate"
                      dense
                      filled
                      solo
                      v-on:change="calculateTotal(index, 'line-item')"
                      :disabled="searching"
                      flat
                      type="number"
                      color="cyan"
                      v-model="line_item.rate"
                      v-model.trim.number.lazy="line_item.rate"
                      v-on:keypress="limitDecimal($event, line_item.rate)"
                    ></v-text-field>

                    <WarrantyManage
                      :readonly="!canUpdate"
                      v-on:change="save_enabled = true"
                      v-if="$hasWarranty && line_item.has_warranty && false"
                      :warranty="line_item.warranty"
                      v-model="line_item.warranty"
                    ></WarrantyManage>
                  </template>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Project Price"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    type="number"
                    color="cyan"
                    v-model="line_item.project_price"
                    v-model.trim.number.lazy="line_item.project_price"
                    v-on:keypress="
                      limitDecimal($event, line_item.project_price)
                    "
                  ></v-text-field>
                  <template>
                    <v-checkbox
                      color="cyan"
                      v-model="line_item.is_optional"
                      class="mt-0 mb-2"
                      v-on:change="calculateTotal(index, 'selected-line-item')"
                    >
                      <template v-slot:label>
                        <span class="color-custom-blue font-weight-700"
                          ><!-- Is Optional ? --></span
                        >
                      </template>
                    </v-checkbox>
                  </template>
                </td>
                <td valign="top" class="text-left" width="15%">
                  <v-text-field
                    label="Quantity"
                    dense
                    filled
                    solo
                    flat
                    :readonly="!canUpdate"
                    type="number"
                    color="cyan"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    :suffix="line_item.uom"
                    v-model="line_item.quantity"
                    v-mask="'####'"
                  ></v-text-field>
                </td>
                <td valign="top" align="right" width="15%">
                  <v-text-field
                    label="Total"
                    dense
                    filled
                    solo
                    color="cyan"
                    flat
                    type="number"
                    readonly
                    v-model="line_item.total"
                  ></v-text-field>
                  <v-btn
                    icon
                    color="red lighten-1"
                    v-if="canUpdate && selected_line_items.length > 1"
                    v-on:click="deleteLineItem(line_item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>

            <tr>
              <td colspan="6"></td>
            </tr>
            <tr>
              <td colspan="6" class="custom-border-top"></td>
            </tr>
            <tr>
              <td valign="middle" colspan="3"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Sub Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{
                  formatMoneyWithCurrency(sub_total, currency)
                }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr v-if="!isJob">
              <td valign="middle" colspan="3"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Discount
              </td>
              <td valign="middle" class="p-0">
                <v-layout>
                  <v-flex md8>
                    <v-text-field
                      label="Discount"
                      dense
                      filled
                      solo
                      color="cyan"
                      flat
                      :readonly="!canUpdate"
                      type="number"
                      v-on:keyup="
                        validateDiscount();
                        calculateGrandTotal();
                      "
                      v-on:change="
                        save_enabled = true;
                        validateDiscount();
                        calculateGrandTotal();
                      "
                      v-model.trim.number.lazy="discount_value"
                      v-on:keypress="limitDecimal($event, discount_value)"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex md10>
                    <v-select
                      :items="discount_type_list"
                      v-model.trim="discount_value_type"
                      dense
                      filled
                      flat
                      hide-details
                      solo
                      :readonly="!canUpdate"
                      item-text="type"
                      item-value="value"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="
                        save_enabled = true;
                        validateDiscount();
                        calculateGrandTotal();
                      "
                      class="discount-append-outer"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </td>
              <td valign="middle" align="right">
                {{
                  formatMoneyWithCurrency(discount, currency)
                }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr v-if="!isJob">
              <td valign="middle" colspan="3"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Trade-In
              </td>
              <td valign="middle" class="p-0">
                <v-text-field
                  label="Trade-In"
                  dense
                  filled
                  solo
                  :readonly="!canUpdate"
                  flat
                  type="number"
                  color="cyan"
                  v-on:keyup="calculateGrandTotal()"
                  v-on:change="
                    save_enabled = true;
                    calculateGrandTotal();
                  "
                  v-model.trim.number.lazy="adjustment"
                  v-on:keypress="limitDecimal($event, adjustment)"
                ></v-text-field>
              </td>
              <td valign="middle" align="right">
                {{ formatMoney(adjustment) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr v-if="isGSTAllowed()">
              <td valign="middle" colspan="3"></td>
              <td
                valign="middle"
                colspan="2"
                class="font-size-16 py-3 font-weight-500"
              >
                <span class="d-flex align-center"
                  >Tax [GST {{ gstValue }}%]
                  <v-checkbox
                    id="apply-tax"
                    color="cyan"
                    :disabled="!canUpdate"
                    class="ml-4 m-0 p-0"
                    v-model="apply_tax"
                    v-on:change="
                      save_enabled = true;
                      calculateGrandTotal();
                    "
                    hide-details
                  ></v-checkbox>
                </span>
              </td>
              <td valign="middle" align="right">
                {{
                  formatMoneyWithCurrency(total_tax, currency)
                }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>

            <tr>
              <td valign="middle" colspan="3"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Grand Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{
                  formatMoneyWithCurrency(grand_total, currency)
                }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
          </Draggable>

          <tfoot v-else>
            <tr>
              <td colspan="6">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no service at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </v-col>
    </v-row>

    <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
      <template v-slot:title>
        Select {{ product_type_text }}s
        <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon> Line
        Item
      </template>
      <template v-slot:body>
        <v-row class="mx-1">
          <v-col v-if="categories.length" md="12" class="alphabets-filter">
            <div class="alphabets-scrollable">
              <v-btn
                :disabled="searching"
                depressed
                v-on:click="getLineItem(null, 'category')"
                :class="{
                  'cyan--text': null == category,
                  'cyan white--text': null != category,
                }"
                class="custom-bold-button"
                small
                >All</v-btn
              >
              <v-btn
                :disabled="searching"
                depressed
                v-for="(row, index) in categories"
                v-on:click="getLineItem(row, 'category')"
                :class="{
                  'cyan--text': row == category,
                  'cyan white--text': row != category,
                }"
                class="custom-bold-button"
                small
                :key="index"
                >{{ row }}</v-btn
              >
            </div>
          </v-col>
          <v-col v-if="alphabets.length" md="12" class="alphabets-filter">
            <div class="alphabets-scrollable">
              <v-btn
                :disabled="searching"
                depressed
                v-on:click="getLineItem(null, 'alphabet')"
                :class="{
                  'cyan--text': null == alphabet,
                  'cyan white--text': null != alphabet,
                }"
                class="custom-bold-button"
                small
                >All</v-btn
              >
              <v-btn
                :disabled="searching"
                depressed
                v-for="(row, index) in alphabets"
                v-on:click="getLineItem(row, 'alphabet')"
                :class="{
                  'cyan--text': row == alphabet,
                  'cyan white--text': row != alphabet,
                }"
                class="custom-bold-button"
                small
                :key="index"
                >{{ row }}</v-btn
              >
            </div>
          </v-col>
          <v-col md="12">
            <v-layout>
              <v-flex
                md6
                class="font-weight-700 font-size-17 color-custom-blue"
              >
                <v-btn
                  small
                  depressed
                  :disabled="searching"
                  :class="{
                    'green white--text': show_selected,
                    'white green--text': !show_selected,
                  }"
                  class="font-size-17 font-weight-700"
                  v-on:click="show_selected = !show_selected"
                  >{{ selectedCount() }}</v-btn
                >
                out of
                <v-btn
                  small
                  depressed
                  :disabled="searching"
                  class="font-size-17 font-weight-700"
                  color="white cyan--text"
                  v-on:click="showAll()"
                  >{{ totalCount() }}</v-btn
                >
                {{ product_type_text }} Selected
              </v-flex>
              <v-flex md6>
                <v-text-field
                  v-model="search"
                  label="Search"
                  placeholder="Start typing then press enter to search..."
                  dense
                  filled
                  solo
                  flat
                  :disabled="searching"
                  color="cyan"
                  append-icon="mdi-magnify"
                  clearable
                  v-on:click:clear="
                    search = null;
                    getLineItem(search, 'search');
                  "
                  v-on:keyup.enter="getLineItem(search, 'search')"
                  v-on:click:append="getLineItem(search, 'search')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <br />
            <div class="custom-border-top">
              <br />
              <table
                v-if="product_type"
                class="line-item-multiple-table"
                width="100%"
              >
                <thead>
                  <tr>
                    <th class="text-center" width="5%">
                      <v-checkbox
                        :disabled="searching || !line_items.length"
                        color="cyan"
                        v-model="allcheckbox"
                        hide-details
                        class="m-0 p-0"
                        v-on:change="checkAll($event)"
                      ></v-checkbox>
                    </th>
                    <th class="text-left" width="50%">
                      &nbsp;&nbsp;&nbsp;{{ product_type_text }}
                    </th>
                    <template v-if="product_type == 'equipment'">
                      <th class="text-left" width="15%">
                        &nbsp;&nbsp;&nbsp;Serial No.
                      </th>
                      <th class="text-left" width="15%">
                        &nbsp;&nbsp;&nbsp;Model
                      </th>
                      <th class="text-left" width="15%">
                        &nbsp;&nbsp;&nbsp;Location
                      </th>
                    </template>
                    <template v-else>
                      <th class="text-left" width="15%">
                        &nbsp;&nbsp;&nbsp;Selling Price / Unit Price
                      </th>
                      <th class="text-left" width="15%">
                        &nbsp;&nbsp;&nbsp;Is Optional
                      </th>
                      <th class="text-left" width="15%">
                        &nbsp;&nbsp;&nbsp;Quantity
                      </th>
                      <th class="text-left" width="15%">
                        &nbsp;&nbsp;&nbsp;Total
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody v-if="line_items.length">
                  <template v-for="(line_item, index) in line_items">
                    <tr v-if="checkSelected(line_item)" :key="index">
                      <td valign="top" class="text-center" width="5%">
                        <v-checkbox
                          color="cyan"
                          hide-details
                          :disabled="searching"
                          v-model="line_item.status"
                          v-on:change="
                            validateCheckbox($event, line_item.product_id)
                          "
                          class="m-0 p-0 mt-2"
                        ></v-checkbox>
                      </td>
                      <td valign="top" class="text-left" width="50%">
                        <v-text-field
                          :label="product_type_text"
                          dense
                          filled
                          solo
                          flat
                          :disabled="searching"
                          readonly
                          color="cyan"
                          v-model="line_item.product"
                        ></v-text-field>
                        <v-textarea
                          auto-grow
                          dense
                          filled
                          flat
                          :disabled="searching"
                          v-model="line_item.description"
                          label="Description"
                          solo
                          color="cyan"
                          hide-details
                          row-height="10"
                        ></v-textarea>
                        <template v-if="!isCreditNote && !line_item.id">
                          <v-layout
                            v-if="
                              !showDetail && line_item.product_type == 'goods'
                            "
                            class="mt-2 justify-center align-center"
                          >
                            <v-flex v-if="false" class="w-100">
                              <v-checkbox
                                inset
                                v-model="line_item.to_equipment"
                                color="cyan"
                                dense
                                class="pt-0 mt-0"
                                hide-details
                                :false-value="0"
                                :true-value="1"
                              >
                                <template v-slot:label>
                                  <span> Add to Equipment </span>
                                  <span class="ml-2 w-50">
                                    <!--begin::Svg Icon-->
                                    <inline-svg
                                      content="Once you enable, The product will be added to equipments of customer."
                                      v-tippy="{
                                        arrow: true,
                                        arrowType: 'round',
                                        animation: 'fade',
                                      }"
                                      style="max-width: 20px"
                                      :src="
                                        $assetURL(
                                          'media/custom-svg/attention.svg'
                                        )
                                      "
                                    />
                                    <!--end::Svg Icon-->
                                  </span>
                                </template>
                              </v-checkbox>
                            </v-flex>
                          </v-layout>
                        </template>
                      </td>
                      <template v-if="product_type == 'equipment'">
                        <td valign="top" class="text-left" width="15%">
                          <v-text-field
                            label="Serial No."
                            dense
                            filled
                            solo
                            :disabled="searching"
                            flat
                            readonly
                            color="cyan"
                            v-model="line_item.serial_no"
                          ></v-text-field>
                          <WarrantyManage
                            v-if="
                              $hasWarranty && line_item.has_warranty && false
                            "
                            :warranty="line_item.warranty"
                            v-model="line_item.warranty"
                          ></WarrantyManage>
                        </td>
                        <td valign="top" class="text-left" width="15%">
                          <v-text-field
                            label="Model"
                            dense
                            filled
                            solo
                            :disabled="searching"
                            flat
                            color="cyan"
                            readonly
                            v-model="line_item.eq_model"
                          ></v-text-field>
                        </td>
                        <td valign="top" class="text-left" width="15%">
                          <v-text-field
                            label="Location"
                            dense
                            filled
                            solo
                            :disabled="searching"
                            flat
                            color="cyan"
                            readonly
                            v-model="line_item.location"
                          ></v-text-field>
                        </td>
                      </template>
                      <template v-else>
                        <td valign="top" class="text-left" width="15%">
                          <!-- <v-text-field
                            label="Rate"
                            dense
                            filled
                            solo
                            v-on:keyup="calculateTotal(index, 'line-item')"
                            v-on:keypress="is_number($event)"
                            v-on:change="calculateTotal(index, 'line-item')"
                            :disabled="searching"
                            flat
                            type="number"
                            color="cyan"
                            v-model="line_item.rate"
                          ></v-text-field> -->
                          <v-text-field
                            label="Rate"
                            dense
                            filled
                            solo
                            v-on:change="calculateTotal(index, 'line-item')"
                            :disabled="searching"
                            flat
                            type="number"
                            color="cyan"
                            v-model="line_item.rate"
                            v-model.trim.number.lazy="line_item.rate"
                            v-on:keypress="limitDecimal($event, line_item.rate)"
                          ></v-text-field>
                          <template>
                            <WarrantyManage
                              v-if="
                                $hasWarranty && line_item.has_warranty && false
                              "
                              :warranty="line_item.warranty"
                              v-model="line_item.warranty"
                            ></WarrantyManage>
                          </template>
                        </td>
                        <td valign="top" class="text-left" width="15%">
                          <!-- <v-text-field
                            label="Project Pricing"
                            dense
                            filled
                            solo
                            v-on:change="calculateTotal(index, 'line-item')"
                            :disabled="searching"
                            flat
                            type="number"
                            color="cyan"
                            v-model="line_item.project_price"
                            v-model.trim.number.lazy="line_item.project_price"
                            v-on:keypress="
                              limitDecimal($event, line_item.project_price)
                            "
                          ></v-text-field> -->
                          <template>
                            <v-checkbox
                              color="cyan"
                              v-model="line_item.is_optional"
                              class="mt-0 mb-2"
                              v-on:change="
                                calculateTotal(index, 'selected-line-item')
                              "
                            >
                              <!-- <template v-slot:label>
                                <span class="color-custom-blue font-weight-700"
                                  >Is Optional ?</span
                                >
                              </template> -->
                            </v-checkbox>
                          </template>
                        </td>
                        <td valign="top" class="text-left" width="15%">
                          <v-text-field
                            label="Quantity"
                            dense
                            filled
                            solo
                            flat
                            type="number"
                            v-on:keyup="calculateTotal(index, 'line-item')"
                            v-on:change="calculateTotal(index, 'line-item')"
                            :disabled="searching"
                            color="cyan"
                            :suffix="line_item.uom"
                            v-model="line_item.quantity"
                            v-mask="'####'"
                          ></v-text-field>
                        </td>
                        <td valign="top" class="text-left" width="15%">
                          <v-text-field
                            label="Total"
                            dense
                            filled
                            :disabled="searching"
                            solo
                            color="cyan"
                            flat
                            type="number"
                            readonly
                            v-model="line_item.total"
                          ></v-text-field>
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
                <tfoot v-else>
                  <tr>
                    <td colspan="5">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no {{ product_type_text }} at the
                        moment.
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="
            product_type = null;
            product_type_text = null;
            line_items = [];
            categories = [];
            dialog = false;
          "
          :disabled="searching"
          class="custom-grey-border custom-bold-button"
          >Cancel</v-btn
        >
        <v-btn
          color="cyan white--text"
          class="custom-bold-button"
          v-on:click="saveLineItem()"
          :disabled="searching"
          >Save</v-btn
        >
      </template>
    </Dialog>

    <DeleteDialog :delete-dialog="delete_line_item_dialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ delete_line_item.product }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ delete_line_item.product }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="delete_line_item_dialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          color="red lighten-1 white--text"
          v-on:click="deleteLineItemConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
  </div>
</template>
<script>
import { QUERY, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import WarrantyManage from "@/view/pages/partials/Warranty-Manage";
import ObjectPath from "object-path";
import Draggable from "vuedraggable";
import { JobEventBus } from "@/core/lib/job/job.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  cloneDeep,
  toNumber,
  sum,
  round,
  filter,
  concat,
  uniqBy,
  find,
  map,
  toSafeInteger,
} from "lodash";
import AppConfiguration from "@/core/config/app.config.js";

export default {
  mixins: [CommonFunctionMixin, ValidationMixin],
  data() {
    return {
      search: null,
      searching: false,
      selectedItems: [],
      allcheckbox: false,
      save_enabled: false,
      product_type: null,
      product_type_text: null,
      dialog: false,
      categories: [],
      alphabet: null,
      category: null,
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      timeout: null,
      timeout_limit: 500,
      line_items: [],
      selected_line_items: [],
      equipments: [],
      selected_equipments: [],
      show_selected: false,
      apply_tax: true,
      sub_total: 0,
      discount: 0,
      total_tax: 0,
      adjustment: 0,
      grand_total: 0,
      discount_value: 0,
      discount_value_type: 1,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      delete_loading: false,
      delete_line_item_dialog: false,
      delete_line_item: {},
      delete_line_index: -1,
      rules: {
        loanThrasholdPrice(value, min) {
          return value >= min;
          //return (value || "") < min || `Rate Should be greater than Thrashold Price ${min} `;
        },
      },
    };
  },

  props: {
    isCreditNote: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    isInvoice: {
      type: Boolean,
      default: false,
    },
    isJob: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dbLineItems: {
      type: Array,
      default() {
        return [];
      },
    },
    dbEquipments: {
      type: Array,
      default() {
        return [];
      },
    },
    entityId: {
      type: Number,
      default: 0,
    },
    propertyId: {
      type: Number,
      default: 0,
    },
    discountValue: {
      type: Number,
      default: 0,
    },
    discountValueType: {
      type: Number,
      default: 0,
    },
    applyTax: {
      type: Number,
      default: 0,
    },
    adjustmentValue: {
      type: Number,
      default: 0,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: "SGD",
    },
    exchangeRate: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    dbLineItems: {
      deep: true,
      immediate: true,
      handler() {
        this.initUpdate();
      },
    },
    dbEquipments: {
      deep: true,
      immediate: true,
      handler() {
        this.initUpdate();
      },
    },
    currency: {
      deep: true,
      immediate: true,
      handler(param) {
        this.convertPrice(param);
      },
    },
  },
  methods: {
    emitData() {
      let selectedData = map(this.selected_line_items, (row, index) => {
        row.order = index + 1;
        return row;
      });
      this.selected_line_items = selectedData;
      this.$emit("update:line-item", this.selected_line_items);
    },
    convertPrice(param) {
      let exchangeRate = this.exchangeRate;
      if (param == "SGD") {
        exchangeRate = 1 / this.exchangeRate;
      }
      let newLineItemData = map(this.selected_line_items, (row) => {
        let newRate = exchangeRate * row.rate;
        let thrasholdRate = exchangeRate * row.threshold_price;
        const total = newRate * row.quantity;
        row.threshold_price = round(toNumber(thrasholdRate), 2);
        row.rate = round(toNumber(newRate), 2);
        row.total = round(toNumber(total), 2);
        row.project_price = round(
          toNumber(row.project_price * exchangeRate),
          2
        );
        return row;
      });
      this.selected_line_items = newLineItemData;
      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },

    isGSTAllowed() {
      const app_config = AppConfiguration.get();
      const { entity } = app_config;
      const currentEntity = find(entity, { id: this.entityId });
      this.gstValue = currentEntity.gstvalue;
      if (currentEntity) {
        if (currentEntity.gst) {
          /* this.apply_tax = true; */
          return true;
        }
      }
    },
    validateDiscount() {
      const sub_total = toNumber(this.sub_total);
      const discount_value = toNumber(this.discount_value);
      const discount_value_type = toNumber(this.discount_value_type);
      if (discount_value_type == 2) {
        if (discount_value >= 100) {
          this.discount_value = 0;
        }
      }
      if (discount_value_type == 1) {
        if (discount_value >= sub_total) {
          this.discount_value = 0;
        }
      }

      if (this.discount_value < 0) {
        this.discount_value = 0;
      }

      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },
    getProductTypeColor(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "cyan darken-1 white--text";
          break;
        case "service":
          result = "orange darken-1 white--text";
          break;
        case "equipment":
          result = "green darken-1 white--text";
          break;
      }
      return result;
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Service";
          break;
        case "equipment":
          result = "Equipment";
          break;
      }
      return result;
    },
    initUpdate() {
      this.selected_line_items = [];
      this.selected_equipments = [];
      if (this.dbLineItems.length) {
        for (let i = 0; i < this.dbLineItems.length; i++) {
          this.selected_line_items.push({
            status: true,
            id: this.dbLineItems[i].id,
            visit_barcode: this.dbLineItems[i].visit_barcode,
            group: this.dbLineItems[i].group,
            group_primary: this.dbLineItems[i].group_primary,
            product: this.dbLineItems[i].product,
            product_id: this.dbLineItems[i].product_id,
            serial_no: this.dbLineItems[i].serial_no,
            eq_model: this.dbLineItems[i].eq_model,
            location: this.dbLineItems[i].location,
            product_type: this.dbLineItems[i].product_type,
            has_warranty: this.dbLineItems[i].has_warranty,
            to_equipment: this.dbLineItems[i].to_equipment,
            warranty: this.dbLineItems[i].warranty,
            description: this.dbLineItems[i].description,
            rate: this.dbLineItems[i].rate,
            threshold_price: this.dbLineItems[i].threshold_price,
            project_price: this.dbLineItems[i].project_price,
            quantity: this.dbLineItems[i].quantity,
            uom: this.dbLineItems[i].uom,
            total: this.dbLineItems[i].total,
            order: this.dbLineItems[i].order,
            is_optional: this.dbLineItems[i].is_optional,
          });
          this.$nextTick(() => {
            this.calculateTotal(i, "selected-line-item");
          });
        }
      }

      if (this.dbEquipments.length) {
        for (let z = 0; z < this.dbEquipments.length; z++) {
          this.selected_equipments.push({
            status: true,
            id: this.dbEquipments[z].id,
            visit_barcode: this.dbEquipments[z].visit_barcode,
            group: this.dbEquipments[z].group,
            group_primary: this.dbEquipments[z].group_primary,
            product: this.dbEquipments[z].product,
            product_id: this.dbEquipments[z].product_id,
            serial_no: this.dbEquipments[z].serial_no,
            eq_model: this.dbEquipments[z].eq_model,
            location: this.dbEquipments[z].location,
            product_type: this.dbEquipments[z].product_type,
            has_warranty: this.dbEquipments[z].has_warranty,
            to_equipment: this.dbEquipments[z].to_equipment,
            warranty: this.dbEquipments[z].warranty,
            description: this.dbEquipments[z].description,
            rate: this.dbEquipments[z].rate,
            threshold_price: this.dbEquipments[z].threshold_price,

            project_price: this.dbLineItems[z]
              ? this.dbLineItems[z].project_price
              : 0,
            quantity: this.dbEquipments[z].quantity,
            uom: this.dbEquipments[z].uom,
            total: this.dbEquipments[z].total,
            order: this.dbEquipments[z].order,
            is_optional: this.dbLineItems[z].is_optional,
          });
        }
      }

      this.apply_tax = this.applyTax;
      this.adjustment = this.adjustmentValue;
      this.discount_value = this.discountValue;
      this.discount_value_type = this.discountValueType;

      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },
    deleteLineItem(row, index) {
      if (row.id) {
        this.delete_line_index = index;
        this.delete_line_item = row;
        this.delete_line_item_dialog = true;
      } else {
        if (row.product_type == "equipment") {
          this.selected_equipments.splice(index, 1);
        } else {
          this.selected_line_items.splice(index, 1);
        }
      }
      this.emitData();
      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },
    deleteLineItemConfirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, { url: `line-item/${this.delete_line_item.id}` })
        .then(() => {
          this.$emit("load:content", true);
          this.selected_equipments.splice(this.delete_line_index, 1);
          this.selected_line_items.splice(this.delete_line_index, 1);
          this.delete_line_item = {};
          this.delete_line_item_dialog = false;
          this.calculateGrandTotal();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },

    calculateGrandTotal() {
      const item_total = [];

      for (let i = 0; i < this.selected_line_items.length; i++) {
        if (this.selected_line_items[i].is_optional) {
          continue;
        }
        const { rate, quantity } = this.selected_line_items[i];
        const total = round(toNumber(rate), 2) * round(toNumber(quantity), 2);
        item_total.push(total);
      }

      let sub_total = sum(item_total);

      this.sub_total = round(toNumber(sub_total), 2);

      if (this.discount_value_type == 1) {
        this.discount = round(toNumber(this.discount_value), 2);
      }

      if (this.discount_value_type == 2) {
        this.discount =
          (round(toNumber(sub_total), 2) *
            round(toNumber(this.discount_value), 2)) /
          100;
      }

      sub_total = sub_total - round(toNumber(this.discount), 2);
      sub_total = sub_total - round(toNumber(this.adjustment), 2);

      if (this.apply_tax) {
        //this.total_tax = (round(toNumber(sub_total), 2) * 7) / 100;
        this.total_tax = (round(toNumber(sub_total), 2) * this.gstValue) / 100;
      } else {
        this.total_tax = 0;
      }

      sub_total = sub_total + round(toNumber(this.total_tax), 2);

      this.grand_total = round(toNumber(sub_total), 2);

      this.$nextTick(() => {
        const calculations = {
          apply_tax: toSafeInteger(this.apply_tax),
          adjustment: round(toNumber(this.adjustment), 2),
          discount_value: round(toNumber(this.discount_value), 2),
          discount_value_type: toSafeInteger(this.discount_value_type),
        };

        if (!this.showDetail) {
          this.$emit("update:line-item", this.selected_line_items);
          this.$emit("update:equipment", this.selected_equipments);
          this.$emit("update:line-item-calculation", calculations);
        }
      });
    },
    updateLineItem() {
      const selected_line_items = filter(this.line_items, {
        status: true,
      });

      this.selected_line_items = concat(
        selected_line_items,
        this.selected_line_items
      );

      this.selected_line_items = uniqBy(this.selected_line_items, "product_id");

      this.calculateGrandTotal();

      this.$nextTick(() => {
        const calculations = {
          apply_tax: toSafeInteger(this.apply_tax),
          adjustment: round(toNumber(this.adjustment), 2),
          discount_value: round(toNumber(this.discount_value), 2),
          discount_value_type: toSafeInteger(this.discount_value_type),
        };

        this.$emit("update:line-item", this.selected_line_items);
        this.$emit("update:equipment", this.selected_equipments);
        this.$emit("update:line-item-calculation", calculations);
        this.save_enabled = false;
      });
    },

    calculateTotal(index, type) {
      let item = this.selected_line_items[index];
      if (type == "line-item") {
        item = this.line_items[index];
      }

      if (item) {
        let rate = item.rate;
        /* if (rate < 0) {
          rate = 0;
        } */

        let quantity = toNumber(item.quantity);
        if (quantity <= 0) {
          //quantity = 1;
        }

        const total = rate * quantity;

        if (type == "line-item") {
          this.line_items[index].quantity = quantity;
          this.line_items[index].rate = rate;
          this.line_items[index].total = total;
        } else {
          this.selected_line_items[index].quantity = quantity;
          this.selected_line_items[index].rate = rate;
          this.selected_line_items[index].total = total;
        }
      }

      if (type == "selected-line-item") {
        this.$nextTick(() => {
          this.calculateGrandTotal();
        });
      }
    },
    openDialog(type, type_text) {
      this.selectedItems = [];
      this.product_type_text = type_text;
      this.product_type = type;
      this.allcheckbox = false;
      this.$nextTick(() => {
        this.findLineItem(true);
      });
    },
    showAll() {
      this.show_selected = false;
    },
    selectedCount() {
      return filter(this.line_items, { status: true }).length;
    },
    totalCount() {
      return this.line_items.length;
    },
    checkSelected(line_item) {
      if (this.show_selected) {
        return line_item.status;
      }
      return true;
    },
    validateCheckbox(status, product_id) {
      const index = this.lodash.findIndex(this.selectedItems, {
        product_id,
      });
      if (status && index < 0) {
        let row = this.lodash.find(this.line_items, { product_id });
        row.order =
          this.selected_line_items.length + this.selectedItems.length + 1;
        this.selectedItems.push(row);
      } else {
        ObjectPath.del(this.selectedItems, index);
      }
      this.$nextTick(() => {
        this.allcheckbox = this.lodash.every(this.line_items, ["status", true]);
      });
    },
    init_selection() {
      if (this.selectedItems.length > 0) {
        for (let i = 0; i < this.selectedItems.length; i++) {
          const index = this.lodash.findIndex(this.line_items, {
            product_id: this.selectedItems[i].product_id,
          });
          if (index >= 0) {
            const selected = this.lodash.cloneDeep(this.selectedItems[i]);
            ObjectPath.set(this.line_items, index, selected);
          }
        }
      }
    },

    checkAll(param) {
      if (this.line_items.length > 0) {
        for (let i = 0; i < this.line_items.length; i++) {
          this.line_items[i].status = param;
          this.validateCheckbox(param, this.line_items[i].product_id);
        }
      }
    },

    getLineItem(value, type) {
      const _this = this;
      if (type == "category") {
        _this.category = _this.category != value ? value : null;
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "alphabet") {
        _this.alphabet = _this.alphabet != value ? value : null;
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "search") {
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "keyup") {
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(() => {
          _this.findLineItem(false);
        }, _this.timeout_limit);
      }
    },
    findLineItem(dialog) {
      const _this = this;
      if (!_this.product_type) {
        return false;
      }
      if (_this.searching) {
        return false;
      }
      _this.searching = true;
      _this.line_items = [];
      _this.$store
        .dispatch(QUERY, {
          url: "product/find-line-items",
          data: {
            type: _this.product_type,
            property: _this.propertyId,
            search: _this.search,
            alphabet: _this.alphabet,
            category: _this.category,
          },
        })
        .then(({ data }) => {
          _this.categories = data.categories;
          let selectedItems = this.selectedItems;
          let lineItemData = map(data.line_items, function (row) {
            row.status =
              selectedItems.length > 0 && selectedItems.includes(row.product_id)
                ? true
                : false;
            let exchangeRate = 1;
            if (_this.currency == "USD") {
              exchangeRate = _this.exchangeRate;
            }

            row.rate = parseFloat(row.rate * exchangeRate).toFixed(2);
            row.project_price = parseFloat(
              row.project_price * exchangeRate
            ).toFixed(2);
            row.total = parseFloat(row.rate * row.quantity).toFixed(2);

            row.threshold_price = parseFloat(
              row.threshold_price * exchangeRate
            ).toFixed(2);

            /*      row.rate = row.rate * exchangeRate;
            row.project_price = row.project_price * exchangeRate;
            row.total = row.rate * row.quantity; */
            return row;
          });
          _this.line_items = lineItemData;

          this.$nextTick(() => {
            this.init_selection();
            if (this.line_items.length > 0) {
              this.allcheckbox = this.lodash.every(this.line_items, [
                "status",
                true,
              ]);
            }
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searching = false;
          _this.updateChecked(dialog);
        });
    },
    saveLineItem() {
      const default_line_items = cloneDeep(this.line_items);

      const selected_line_items = filter(default_line_items, {
        status: true,
      });

      if (!selected_line_items.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            `Select atleast one ${this.product_type_text.toLowerCase()} then try again.`
          )
        );
        return false;
      }

      let thrasholdLimitError = [];
      for (let i = 0; i < selected_line_items.length; i++) {
        if (selected_line_items[i].is_optional) {
          continue;
        }

        const { rate, threshold_price } = selected_line_items[i];
        if (rate < threshold_price) {
          thrasholdLimitError.push(true);
        }
      }

      if (thrasholdLimitError.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            `Item Rate can not below thrashold price. Please check and correct pricing of each item. `
          )
        );
        return false;
      }

      if (this.product_type == "equipment") {
        const selected_equipments = filter(default_line_items, {
          status: true,
          product_type: "equipment",
        });

        this.selected_equipments = concat(
          selected_equipments,
          this.selected_equipments
        );

        /*  this.selected_equipments = uniqBy(
          this.selected_equipments,
          "product_id"
        ); */
      } else {
        const selected_products = filter(default_line_items, {
          status: true,
          product_type: "goods",
        });

        const selected_services = filter(default_line_items, {
          status: true,
          product_type: "service",
        });

        this.selected_line_items = concat(
          concat(selected_products, selected_services),
          this.selected_line_items
        );

        /*  this.selected_line_items = uniqBy(
          this.selected_line_items,
          "product_id"
        ); */
      }

      this.selected_line_items.sort((p1, p2) =>
        p1.order > p2.order ? 1 : p1.order < p2.order ? -1 : 0
      );

      //this.selected_line_items.sort((r1, r2) => (r1.roll > r2.roll) ? 1 : (r1.roll < r2.roll) ? -1 : 0);

      this.product_type = null;
      this.product_type_text = null;
      this.dialog = false;

      this.save_enabled = true;

      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },

    updateChecked(dialog) {
      /*   const line_items =
        this.product_type == "equipment"
          ? this.selected_equipments
          : this.selected_line_items; */

      /* const filtered = filter(line_items, {
        product_type: this.product_type,
      });
 */
      /*   for (let i = 0; i < this.line_items.length; i++) {
        const exist = find(filtered, {
          product_id: this.line_items[i].product_id,
        });

        if (exist) {
          this.line_items[i] = exist;
        }
      } */

      if (dialog) {
        this.$nextTick(() => {
          this.dialog = true;
          /*   if (filtered.length && filtered.length == this.line_items.length) {
            this.allcheckbox = true;
          } */
        });
      }
    },
  },

  components: {
    Dialog,
    Draggable,
    WarrantyManage,
    DeleteDialog,
  },
  mounted() {
    let _this = this;
    JobEventBus.$on("update-value:line-item-products", (values) => {
      _this.selected_line_items = [];
      if (values.length) {
        for (let i = 0; i < values.length; i++) {
          _this.selected_line_items.push({
            status: true,
            id: values[i].id,
            visit_barcode: values[i].visit_barcode,
            group: values[i].group,
            group_primary: values[i].group_primary,
            product: values[i].product,
            product_id: values[i].product_id,
            serial_no: values[i].serial_no,
            eq_model: values[i].eq_model,
            location: values[i].location,
            product_type: values[i].product_type,
            has_warranty: values[i].has_warranty,
            to_equipment: values[i].to_equipment,
            warranty: values[i].warranty,
            description: values[i].description,
            rate: values[i].rate,
            threshold_price: values[i].threshold_price,
            project_price: values[i].project_price,
            quantity: values[i].quantity,
            uom: values[i].uom,
            total: values[i].total,
            order: values[i].order,
            is_optional: values[i].is_optional,
          });
          _this.$nextTick(() => {
            _this.calculateTotal(i, "selected-line-item");
          });
        }
      }
      JobEventBus.$off("update-value:line-item-products");
    });

    JobEventBus.$on("update-value:line-item-calculation", (value) => {
      _this.apply_tax = value.tax_value > 0;
      _this.adjustment = value.adjustment;
      _this.discount_value = value.discount_value;
      _this.discount_value_type = value.discount_value_type;
      JobEventBus.$off("update-value:line-item-calculation");
    });
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
